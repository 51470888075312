import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useSelector, useDispatch } from 'react-redux';

import { fetchOrganizationUsers, selectedPortfolioOrganizationUsers } from '../reducers/users';
import { RootState } from '../reducers';

import UserTable from '../components/view/UsersTable';
import {
  portfolioByIdSelector,
  selectedPortfolioOrganizationSelector,
} from '../reducers/portfolios';
import { getToken } from '../reducers/user';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function ManageUsers() {
  const classes = useStyles();
  const token = useSelector(getToken);
  const dispatch = useDispatch();

  const { id: organizationId } = useSelector((state: RootState) =>
    selectedPortfolioOrganizationSelector(state),
  );
  const isLoadingUsers = useSelector((state: RootState) => state.users?.isLoadingData);
  const users = useSelector((state: RootState) => selectedPortfolioOrganizationUsers(state));
  const portfolioById = useSelector((state: RootState) => portfolioByIdSelector(state));

  useEffect(() => {
    async function fetchUsers(organizationId: number) {
      dispatch(fetchOrganizationUsers(organizationId, token));
    }

    if (users === undefined) {
      fetchUsers(organizationId);
    }
  }, [dispatch, organizationId, users]);

  return (
    <div className={classes.root}>
      {isLoadingUsers ? (
        <div>Loading...</div>
      ) : (
        <UserTable title="Users" users={users} portfolioMap={portfolioById!} />
      )}
    </div>
  );
}
