import { createSelector } from 'reselect';

import { PolicyTrigger, Trigger, PolicyTriggerStatus } from '../../models';
import { RootState, FluxStandardAction } from '.';
import {
  getStartDate,
  getGerminationEndDate,
  getFloweringDate,
  getMaturationDate,
  getEndDate,
  getFloweringEndDate,
  getAlmondStartDate,
  getAlmondTriggerDate,
  getAlmondEndDate,
} from './timeUtils';
import moment from 'moment';

export const TRIGGERS_SUCCESS = '@@policies/TRIGGERS_SUCCESS';

export type PolicyTriggersState = {
  isLoadingData: boolean;
  error: boolean;
  byId: {
    [triggerId: number]: PolicyTrigger;
  };
  byPolicy: {
    [policyId: number]: number[];
  };
};

const initialState: PolicyTriggersState = {
  isLoadingData: false,
  error: false,
  byId: {},
  byPolicy: {},
};

export default function reducer(
  state = initialState,
  action: FluxStandardAction,
): PolicyTriggersState {
  switch (action.type) {
    case TRIGGERS_SUCCESS: {
      const { locale, policyId, coverage_limit, payout, currentDate } = action.meta;
      let newTriggers;
      if (locale === 'en-US') {
        newTriggers = createAlmondTriggers(policyId, coverage_limit, payout, currentDate);
      } else {
        newTriggers = createDroughtTriggers(policyId, coverage_limit, payout, currentDate);
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          ...newTriggers.byId,
        },
        byPolicy: {
          ...state.byPolicy,
          ...newTriggers.byPolicy,
        },
      };
    }
    default:
      return state;
  }
}

export const triggerErrorSelector = (state: RootState) => state.triggers.error;

const triggerIdsByPolicySelector = (state: RootState, props: any) =>
  state.triggers.byPolicy[props.policy.id];
const triggersByIdSelector = (state: RootState) => state.triggers.byId;
export const policyTriggersSelector = createSelector(
  triggerIdsByPolicySelector,
  triggersByIdSelector,
  (triggerIds, byId) =>
    triggerIds !== undefined ? triggerIds.map(triggerId => byId[triggerId]) : undefined,
);

export const fetchPolicyTriggers = (policyId: number, policies: any[], currentDate: string) => {
  const locale = window.sessionStorage.getItem('country');
  const policy = policies?.filter(pol => pol.id === policyId);
  const coverage_limit = policy?.[0].coverage_limit ?? 0;
  const payout = policy?.[0].payout ?? 0;
  return {
    type: TRIGGERS_SUCCESS,
    meta: {
      policyId,
      coverage_limit,
      payout,
      locale,
      currentDate,
    },
  };
};

const createDroughtTriggers = (
  policyId: number,
  coverage_limit: number,
  payout: number,
  currentDate: string,
): any => {
  const getStatus = (
    startDate: string,
    endDate: string,
    isTriggered = false,
  ): PolicyTriggerStatus => {
    const current = moment(currentDate);
    if (current.isBefore(moment(startDate))) {
      return 'priced';
    } else if (current.isBefore(moment(endDate))) {
      return isTriggered ? 'triggered' : 'active';
    } else {
      return isTriggered ? 'paid out' : 'expired';
    }
  };

  const isTriggered = payout > 0;
  let cddValue = 0;
  if (moment(currentDate).isAfter(moment(getFloweringDate()))) {
    cddValue = isTriggered ? 14 : 5;
  }

  let cddTrigger: any = {
    id: 8602198,
    trigger_type: 'cdd',
    current_value: cddValue,
    start_date: getFloweringDate(),
    end_date: getEndDate(),
    period: 2,
    status: getStatus(getFloweringDate(), getEndDate(), isTriggered),
    triggers: [
      {
        payout: Math.floor(coverage_limit * 0.6),
        factor: 0.6,
        threshold: 13,
        status: getStatus(getFloweringDate(), getEndDate(), isTriggered),
      },
      {
        payout: Math.floor(coverage_limit * 0.75),
        factor: 0.75,
        threshold: 15,
        status: getStatus(getFloweringDate(), getEndDate()),
      },
      {
        payout: Math.floor(coverage_limit * 0.8),
        factor: 0.8,
        threshold: 19,
        status: getStatus(getFloweringDate(), getEndDate()),
      },
    ],
  };

  return {
    byId: {
      [`${policyId}195`]: {
        id: 8602196,
        trigger_type: 'grm',
        start_date: getStartDate(),
        end_date: getGerminationEndDate(),
        current_value: moment(currentDate).isBefore(moment(getGerminationEndDate())) ? 0 : 135,
        period: 1,
        status: getStatus(getStartDate(), getGerminationEndDate()),
        triggers: [
          {
            payout: Math.floor(coverage_limit * 0.3),
            factor: 0.3,
            threshold: 120,
            status: getStatus(getStartDate(), getGerminationEndDate()),
          },
        ],
      },
      [`${policyId}196`]: {
        id: 8602196,
        trigger_type: 'crf',
        start_date: getFloweringDate(),
        end_date: getFloweringEndDate(),
        current_value: moment(currentDate).isAfter(moment(getFloweringDate())) ? 55 : 0,
        period: 1,
        status: getStatus(getFloweringDate(), getFloweringEndDate()),
        triggers: [
          {
            payout: Math.floor(coverage_limit * 0.5),
            factor: 0.5,
            threshold: 45,
            status: getStatus(getFloweringDate(), getFloweringEndDate()),
          },
          {
            payout: Math.floor(coverage_limit),
            factor: 1,
            threshold: 22,
            status: getStatus(getFloweringDate(), getFloweringEndDate()),
          },
        ],
      },
      [`${policyId}198`]: cddTrigger,
      [`${policyId}209`]: {
        id: 8602209,
        trigger_type: 'crf',
        start_date: getMaturationDate(),
        end_date: getEndDate(),
        current_value: moment(currentDate).isBefore(moment(getEndDate())) ? 0 : 55,
        period: 2,
        status: getStatus(getMaturationDate(), getEndDate()),
        triggers: [
          {
            payout: Math.floor(coverage_limit * 0.5),
            factor: 0.5,
            threshold: 34,
            status: getStatus(getMaturationDate(), getEndDate()),
          },
          {
            payout: Math.floor(coverage_limit),
            factor: 1,
            threshold: 18,
            status: getStatus(getMaturationDate(), getEndDate()),
          },
        ],
      },
    },
    byPolicy: {
      [policyId]: [`${policyId}195`, `${policyId}196`, `${policyId}198`, `${policyId}209`],
    },
  };
};

const createAlmondTriggers = (
  policyId: number,
  coverage_limit: number,
  payout: number,
  currentDate: string,
): any => {
  console.log('almond trigger cov limit', coverage_limit);
  const getStatus = (
    startDate: string,
    endDate: string,
    isTriggered = false,
  ): PolicyTriggerStatus => {
    const current = moment(currentDate);
    if (current.isBefore(moment(startDate))) {
      return 'priced';
    } else if (current.isBefore(moment(endDate))) {
      return isTriggered ? 'triggered' : 'active';
    } else {
      return isTriggered ? 'paid out' : 'expired';
    }
  };

  const isTriggered = payout > 0;
  let fddValue = 0;
  if (moment(currentDate).isAfter(moment(getAlmondStartDate()))) {
    fddValue = isTriggered ? 5 : 2;
  }

  let crwValue = 0;
  if (moment(currentDate).isAfter(moment(getAlmondTriggerDate()))) {
    crwValue = 37;
  } else if (moment(currentDate).isAfter(moment(getAlmondStartDate()))) {
    crwValue = 16;
  }

  let fddTrigger: any = {
    id: 8602198,
    trigger_type: 'fdd',
    current_value: fddValue,
    start_date: getAlmondStartDate(),
    end_date: getAlmondEndDate(),
    period: 2,
    status: getStatus(getAlmondStartDate(), getAlmondEndDate(), isTriggered),
    triggers: [
      {
        payout: Math.floor(coverage_limit * 0.056),
        threshold: 4,
        factor: 0.056,
        status: getStatus(getAlmondStartDate(), getAlmondEndDate(), isTriggered),
      },
      {
        payout: Math.floor(coverage_limit * 0.064),
        threshold: 8,
        factor: 0.064,
        status: getStatus(getAlmondStartDate(), getAlmondEndDate()),
      },
      {
        payout: Math.floor(coverage_limit * 0.09),
        threshold: 12,
        factor: 0.09,
        status: getStatus(getAlmondStartDate(), getAlmondEndDate()),
      },
      {
        payout: Math.floor(coverage_limit * 0.14),
        threshold: 16,
        factor: 0.14,
        status: getStatus(getAlmondStartDate(), getAlmondEndDate()),
      },
      {
        payout: Math.floor(coverage_limit * 0.214),
        threshold: 20,
        factor: 0.214,
        status: getStatus(getAlmondStartDate(), getAlmondEndDate()),
      },
      {
        payout: Math.floor(coverage_limit * 0.303),
        threshold: 24,
        factor: 0.303,
        status: getStatus(getAlmondStartDate(), getAlmondEndDate()),
      },
    ],
  };

  return {
    byId: {
      [`${policyId}198`]: fddTrigger,
      [`${policyId}209`]: {
        id: 8602209,
        trigger_type: 'crw',
        start_date: getAlmondStartDate(),
        end_date: getAlmondEndDate(),
        current_value: crwValue,
        period: 4,
        status: getStatus(getAlmondStartDate(), getAlmondEndDate()),
        triggers: [
          {
            payout: Math.floor(coverage_limit * 0.05),
            factor: 0.05,
            threshold: 60,
            status: getStatus(getAlmondStartDate(), getAlmondEndDate()),
          },
          {
            payout: Math.floor(coverage_limit * 0.0833),
            factor: 0.0833,
            threshold: 70,
            status: getStatus(getAlmondStartDate(), getAlmondEndDate()),
          },
          {
            payout: Math.floor(coverage_limit * 0.1167),
            factor: 0.1167,
            threshold: 80,
            status: getStatus(getAlmondStartDate(), getAlmondEndDate()),
          },

          {
            payout: Math.floor(coverage_limit * 0.15),
            factor: 0.15,
            threshold: 90,
            status: getStatus(getAlmondStartDate(), getAlmondEndDate()),
          },
        ],
      },
    },
    byPolicy: {
      [policyId]: [`${policyId}198`, `${policyId}209`],
    },
  };
};
