import { RSAA } from 'redux-api-middleware'; // RSAA = '@@redux-api-middleware/RSAA'
import keyBy from 'lodash/keyBy';

import { createSelector } from 'reselect';

import { API_V2_ROOT } from '../env';
import { PolicyTrigger, PolicyTriggerSummary, Trigger } from '../models';
import { RootState, FluxStandardAction } from '.';
import { transformTriggers } from './transformations';
import { LOGOUT } from './user';

export const TRIGGERS_REQUEST = '@@policies/TRIGGERS_REQUEST';
export const TRIGGERS_SUCCESS = '@@policies/TRIGGERS_SUCCESS';
export const TRIGGERS_FAILURE = '@@policies/TRIGGERS_FAILURE';

export const fetchPolicyTriggers: any = (policyId: number, token: string) => ({
  [RSAA]: {
    endpoint: `${API_V2_ROOT}/policies/${policyId}/triggers`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
    types: [
      TRIGGERS_REQUEST,
      {
        type: TRIGGERS_SUCCESS,
        meta: { policyId },
      },
      TRIGGERS_FAILURE,
    ],
  },
});

export type PolicyTriggersState = {
  isLoadingData: boolean;
  error: boolean;
  byId: {
    [triggerId: number]: PolicyTrigger;
  };
  byPolicy: {
    [policyId: number]: number[];
  };
};

const initialState: PolicyTriggersState = {
  isLoadingData: false,
  error: false,
  byId: {},
  byPolicy: {},
};

export default function reducer(
  state = initialState,
  action: FluxStandardAction,
): PolicyTriggersState {
  switch (action.type) {
    case TRIGGERS_REQUEST:
      return {
        ...state,
        isLoadingData: true,
        error: false,
      };
    case TRIGGERS_SUCCESS:
      const { policyId } = action.meta;
      const rawPayload: Trigger[] = action.payload;
      const payload = transformTriggers(rawPayload);
      const triggersById = keyBy(payload, 'id');
      const triggerIds = payload.map(trig => trig.id);
      return {
        ...state,
        isLoadingData: false,
        byId: {
          ...state.byId,
          ...triggersById,
        },
        byPolicy: {
          ...state.byPolicy,
          [policyId]: triggerIds,
        },
      };
    case TRIGGERS_FAILURE:
      // TODO implement
      return {
        ...state,
        error: true,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export const triggerErrorSelector = (state: RootState) => state.triggers.error;

const triggerIdsByPolicySelector = (state: RootState, props: any) =>
  state.triggers.byPolicy[props.policy.id];
const triggersByIdSelector = (state: RootState) => state.triggers.byId;
export const policyTriggersSelector = createSelector(
  triggerIdsByPolicySelector,
  triggersByIdSelector,
  (triggerIds, byId) =>
    triggerIds !== undefined ? triggerIds.map(triggerId => byId[triggerId]) : undefined,
);
