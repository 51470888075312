export const clusterLayer: any = {
  id: 'clusters',
  type: 'circle',
  source: 'trigger_locations',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': ['step', ['get', 'point_count'], 'honeydew', 100, '#adc24f', 750, '#f28cb1'],
    'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
  },
};

export const heatmapLayer: any = {
  id: 'heatmap',
  type: 'heatmap',
  source: 'trigger_locations',
  maxzoom: 15,
  paint: {
    // increase weight as diameter breast height increases
    // 'heatmap-weight': {
    //   property: 'payout',
    //   type: 'exponential',
    //   stops: [
    //     [1, 0],
    //     [300, 1],
    //   ],
    // },
    'heatmap-weight': ['interpolate', ['linear'], ['get', 'payout'], 0, 0, 1000, 1],
    // increase intensity as zoom level increases
    // 'heatmap-intensity': {
    //   stops: [
    //     [11, 2],
    //     [15, 2],
    //   ],
    // },
    // assign color values be applied to points depending on their density
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(33,102,172,0)',
      0.3,
      '#feb24c',
      0.5,
      '#fd8d3c',
      0.7,
      '#fc4e2a',
      1,
      '#e31a1c',
    ],
    // increase radius as zoom increases
    'heatmap-radius': {
      stops: [
        [4, 100],
        [11, 100],
        [15, 100],
      ],
    },
    // decrease opacity to transition into the circle layer
    'heatmap-opacity': {
      default: 1,
      stops: [
        [2, 1],
        [15, 1],
      ],
    },
  },
};

export const clusterCountLayer: any = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'trigger_locations',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
  },
};

export const payoutAmountLayer: any = {
  id: 'payout-sum',
  type: 'symbol',
  source: 'trigger_locations',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '${payoutSum}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
  },
};

export const unclusteredPointLayer: any = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'trigger_locations',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-radius': 8,
    // 'circle-opacity': 0,
    'circle-color': [
      'match',
      ['get', 'status'],
      'All Pending',
      '#757780',
      'All Expired',
      '#0C090D',
      'Some In Progress',
      '#3BAE65',
      'Some Policies Triggered',
      '#DCC48E',
      'Some Payouts Eligible',
      '#F15946',
      /* other */ '#fafafa',
    ],
    // 'circle-stroke-width': 5,
  },
};
