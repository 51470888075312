import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { useSelector, useDispatch } from 'react-redux';

import { getSelectedPortfolioPolicies } from '../reducers/policies';
import { getSelectedPortfolio, selectedPortfolioIdSelector } from '../reducers/portfolios';
import { getPortfolioSummaryById } from '../reducers/portfolio-summaries';
import { RootState } from '../reducers';

import PortfolioDashboard from '../../components/view/PortfolioSummaryDashboard';
import TimeShift from './TimeShift';
import { fetchPolicyTriggers } from '../reducers/triggers';
import { getPlantingDate } from '../reducers/timeUtils';
import { Policy } from '../../models';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  time: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function MonitorPortfolio() {
  const classes = useStyles();
  let history = useHistory();

  const locale = window.sessionStorage.getItem('country') ?? 'en-CA';
  const dispatch = useDispatch();
  const currentDate = useSelector((state: RootState) => state.time?.currentTime);
  const organizationId = useSelector((state: RootState) => state.user?.metadata?.organizationId);
  const selectedPortfolioId = useSelector((state: RootState) => selectedPortfolioIdSelector(state));
  const portfolioPolicies = useSelector((state: RootState) => getSelectedPortfolioPolicies(state));
  const portfolio = useSelector((state: RootState) => getSelectedPortfolio(state));
  const selectedPortfolioSummary = useSelector((state: RootState) =>
    getPortfolioSummaryById(state, selectedPortfolioId),
  );

  useEffect(() => {
    portfolioPolicies!.forEach(pol => {
      dispatch(fetchPolicyTriggers(pol.id, portfolioPolicies!, currentDate));
    });
  }, [currentDate]);

  const timeShift = (
    <Grid item xs={12}>
      <div className={classes.time}>
        <TimeShift
          productType={locale === 'en-US' ? 'almond' : 'maize'}
          policies={portfolioPolicies}
        />
      </div>
    </Grid>
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {portfolioPolicies && portfolioPolicies.length ? timeShift : null}
        {organizationId !== undefined ? (
          <PortfolioDashboard
            onTableRowClick={(event: any, rowData?: Policy) => {
              history.push(`/policies/${rowData!.id}`);
            }}
            portfolioInfo={portfolio}
            portfolioPolicies={portfolioPolicies}
            portfolioSummary={selectedPortfolioSummary}
            filterComponent={() => <span />}
            currentDate={currentDate}
          />
        ) : null}
      </Grid>
    </div>
  );
}

export default MonitorPortfolio;
