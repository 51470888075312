import React, { useEffect, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import axios from 'axios';

import UploadPanel from '../components/create/UploadPanel';
import BulkPolicyJobsList from '../components/create/BulkPolicyJobsTable';

import { useSelector } from 'react-redux';
import { selectedPortfolioIdSelector } from '../reducers/portfolios';
import { RootState } from '../reducers';
import { BulkUploadJob } from '../models';
import { API_V2_ROOT } from '../env';
import { getToken } from '../reducers/user';

const BULK_IMPORT_ENDPOINT = `${API_V2_ROOT}/jobs/bulk-import`;

const BuildPortfolio = () => {
  const token = useSelector(getToken);
  const [jobsList, setJobsList] = useState<BulkUploadJob[]>([]);
  const [shouldRefreshTable, setShouldRefreshTable] = useState<number>(0);

  const selectedPortfolioId = useSelector((state: RootState) => selectedPortfolioIdSelector(state));

  async function downloadFile(url: string) {
    const res = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob',
    });
    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'file.csv'); //any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  useEffect(() => {
    async function fetchJobs() {
      const result = await axios.get(BULK_IMPORT_ENDPOINT, {
        headers: { Authorization: `Bearer ${token}` },
        params: { portfolio_id: selectedPortfolioId },
      });
      setJobsList(result.data);
    }

    fetchJobs();
  }, [shouldRefreshTable]);

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Build Portfolio
      </Typography>
      <UploadPanel
        token={token}
        portfolioId={selectedPortfolioId}
        onUploadSuccess={() => setShouldRefreshTable(shouldRefreshTable + 1)}
      />
      <div style={{ marginTop: 16 }}>
        <BulkPolicyJobsList jobsList={jobsList} downloadFile={downloadFile} />
      </div>
    </>
  );
};

export default BuildPortfolio;
