import groupBy from 'lodash/groupBy';

import { PolicyTrigger, Trigger } from '../models';

export function transformTriggers(data: Trigger[]): PolicyTrigger[] {
  // group by type, start date, end date
  const grouped = groupBy(
    data,
    ({ trigger_type, date_start, date_end }) => `${trigger_type}-${date_start}-${date_end}`,
  );
  const mapped: PolicyTrigger[] = Object.values(grouped).map(trigs => {
    const {
      id,
      trigger_type,
      date_start: start_date,
      date_end: end_date,
      current_value,
      period,
      status,
    } = trigs[0];
    const triggers = trigs.map(trig => ({
      // no longer receiving payout amounts from the API - calculate using UI
      payout: 0,
      factor: trig.payout_factor,
      threshold: trig.trigger_threshold,
      status: trig.status,
    }));
    return {
      id,
      trigger_type,
      start_date,
      end_date,
      current_value,
      period,
      status,
      triggers,
    };
  });
  return mapped;
}
