import { RSAA } from 'redux-api-middleware'; // RSAA = '@@redux-api-middleware/RSAA'
import keyBy from 'lodash/keyBy';

import { createSelector } from 'reselect';

import { API_V2_ROOT } from '../env';
import { PortfolioBalance, Portfolio, EnterpriseOrganization, ObjectLiteral } from '../models';
import { RootState } from '.';
import { LOGOUT } from './user';

export const PORTFOLIOS_REQUEST = '@@portfolio/PORTFOLIOS_REQUEST';
export const PORTFOLIOS_SUCCESS = '@@portfolio/PORTFOLIOS_SUCCESS';
export const PORTFOLIOS_FAILURE = '@@portfolio/PORTFOLIOS_FAILURE';

export const SET_SELECTED_PORTFOLIO = '@@portfolio/SET_SELECTED_PORTFOLIO';

export const fetchPortfolios: any = (token: string) => ({
  [RSAA]: {
    endpoint: `${API_V2_ROOT}/portfolios`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
    types: [PORTFOLIOS_REQUEST, PORTFOLIOS_SUCCESS, PORTFOLIOS_FAILURE],
  },
});

export type PortfolioState = {
  isLoadingData: boolean;
  lastUpdate?: string;
  selectedId?: number;
  byId?: {
    [id: number]: Portfolio;
  };
  allIds?: number[];
};

export const portfolioInitialState: PortfolioState = {
  isLoadingData: false,
  selectedId:
    window.localStorage.getItem('selectedPortfolioId') !== null
      ? parseInt(window.localStorage.getItem('selectedPortfolioId')!, 10)
      : undefined,
  byId: undefined,
  allIds: undefined,
};

type PortfolioAction = {
  type: string;
  payload: any;
};

export default function reducer(
  state = portfolioInitialState,
  action: PortfolioAction,
): PortfolioState {
  switch (action.type) {
    case PORTFOLIOS_REQUEST:
      return state;
    case PORTFOLIOS_SUCCESS:
      const portfoliosById = keyBy(action.payload, 'id');
      const allIds = Object.keys(portfoliosById).map(key => parseInt(key, 10));
      let selectedId = state.selectedId;
      if (!selectedId || !allIds.includes(selectedId)) {
        // Set first portfolio as selected by default
        selectedId = action.payload[0].id;
        window.localStorage.setItem('selectedPortfolioId', String(selectedId));
      }
      return {
        ...state,
        isLoadingData: false,
        selectedId,
        byId: portfoliosById,
        allIds,
        lastUpdate: new Date().toJSON(),
      };
    case PORTFOLIOS_FAILURE:
      // TODO implement
      return state;
    case SET_SELECTED_PORTFOLIO:
      window.localStorage.setItem('selectedPortfolioId', action.payload);
      return {
        ...state,
        selectedId: action.payload,
      };
    case LOGOUT:
      return {
        ...portfolioInitialState,
      };
    default:
      return state;
  }
}

export const setPortfolioId = (portfolioId: string) => ({
  type: SET_SELECTED_PORTFOLIO,
  payload: parseInt(portfolioId, 10),
});

export const selectedPortfolioIdSelector = (state: RootState) => state.portfolios.selectedId;
export const portfolioByIdSelector = (state: RootState) => state.portfolios.byId;

export const getSelectedPortfolio = createSelector(
  selectedPortfolioIdSelector,
  portfolioByIdSelector,
  (selectedId, byId) =>
    selectedId === undefined || byId === undefined ? undefined : byId[selectedId],
);

const allIdsSelector = (state: RootState) => state.portfolios.allIds;
export const getPortfoliosList = createSelector(
  allIdsSelector,
  portfolioByIdSelector,
  (allIds, portfolioById) =>
    allIds === undefined || portfolioById === undefined
      ? undefined
      : allIds.map(id => portfolioById[id]),
);

export const selectedPortfolioTypeSelector = createSelector(getSelectedPortfolio, selected =>
  selected !== undefined ? selected.portfolio_type : '',
);

export const selectedPortfolioCurrencySelector = createSelector(getSelectedPortfolio, selected =>
  selected !== undefined ? selected.currency.iso_code : '',
);

export const selectedPortfolioBalanceSelector = createSelector(
  getSelectedPortfolio,
  selected => selected?.balance || ({} as PortfolioBalance),
);

export const selectedPortfolioTotalBalanceSelector = createSelector(
  selectedPortfolioBalanceSelector,
  selectedPortfolioBalances => selectedPortfolioBalances?.total || 0,
);

export const selectedPortfolioCountrySelector = createSelector(
  getSelectedPortfolio,
  selected => selected?.country.id,
);

export const selectedPortfolioOrganizationSelector = createSelector(
  getSelectedPortfolio,
  selected => selected?.organization || ({} as EnterpriseOrganization),
);
