import React from 'react';

import { CircularProgressbar } from 'react-circular-progressbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    maxWidth: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export type CircularSummaryProps = {
  /** Currency Value of the KPI */
  main: number;
  /** Total Possible Value of the KPI */
  total: number;
  /** Label for KPI */
  label: string;
  /** Color of Summary */
  color?: string;
};

/**
 * Circular Summary. Displays a percentage filled with a label.
 */
const CircularSummary = ({ main, total, label, color = '#3BAE65' }: CircularSummaryProps) => {
  const classes = useStyles();

  const percentage = (main / total) * 100;
  const summaryText = isNaN(percentage) ? 'N/A' : `${Math.floor(percentage)}%`;
  return (
    <div className={classes.root}>
      <Typography gutterBottom variant="button" component="h2">
        {label}
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" gutterBottom component="p">
        {!isNaN(percentage) ? `${main}/${total}` : '0/0'}
      </Typography>
      <CircularProgressbar
        value={isNaN(percentage) ? 0 : percentage}
        text={summaryText}
        strokeWidth={15}
        styles={{
          path: { stroke: color },
          text: { fill: color },
        }}
      />
    </div>
  );
};

export default CircularSummary;
