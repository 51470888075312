import { createSelector } from 'reselect';

import { localeMap, Locale } from '../env';
import { PortfolioBalance, CurrencyCode, Portfolio } from '../../models';
import { RootState } from '.';
import { LOGIN } from './user';

export const ADD_TOP_UP = '@@PORTFOLIO/ADD_TOP_UP';
export const DEDUCT_PREMIUM = '@@PORTFOLIO/DEDUCT_PREMIUM';
export const ADD_PAYOUT = '@@PORTFOLIO/ADD_PAYOUT';
export const DEDUCT_PAYOUT = '@@PORTFOLIO/DEDUCT_PAYOUT';

export type PortfolioState = {
  locale: string;
  isLoadingData: boolean;
  selectedId: number;
  byId: {
    [id: number]: Portfolio;
  };
  allIds: number[];
};

const PORTFOLIO_ID = 1;

export function getLocale(loginLocale?: string): Locale {
  let country = loginLocale;
  if (country == null) {
    country = window.sessionStorage.getItem('country') || 'en-US';
  }
  return localeMap[country];
}

function getInitialState(loginLocale?: string): PortfolioState {
  const locale = getLocale(loginLocale);

  const initialState: PortfolioState = {
    locale: locale.locale,
    isLoadingData: false,
    selectedId: PORTFOLIO_ID,
    byId: {
      [PORTFOLIO_ID]: {
        id: PORTFOLIO_ID,
        organization: {
          id: 1,
          name: 'ACME Agro Inc',
        },
        country: {
          id: 1,
          iso_code: 'GH',
        },
        portfolio_code: 'ACME-20',
        portfolio_type: 'PI',
        currency: {
          id: 1,
          iso_code: locale.currency as any,
        },
        balance: {
          currency_id: 1,
          payments: 0,
          allocations: 0,
          payouts_due: 0,
          funds_withdrawn: 0,
          total: 0,
        },
      },
    },
    allIds: [PORTFOLIO_ID],
  };
  return initialState;
}
type PortfolioAction = {
  type: string;
  payload: any;
};

export default function reducer(
  state = getInitialState(),
  action: PortfolioAction,
): PortfolioState {
  switch (action.type) {
    case ADD_TOP_UP: {
      const { balance } = state.byId[1];
      const payment: number = action.payload.data;
      const newBalance = {
        ...balance,
        payments: (balance!.payments || 0) + payment,
        total: (balance!.total || 0) + payment,
      };
      const byId: any = state.byId;
      return {
        ...state,
        byId: {
          [PORTFOLIO_ID]: {
            ...byId[PORTFOLIO_ID],
            balance: newBalance,
          },
        },
      };
    }
    case DEDUCT_PREMIUM: {
      const { balance } = state.byId[1];
      const allocation: number = action.payload.data;
      const newBalance = {
        ...balance,
        allocations: (balance!.allocations || 0) + allocation,
        total: (balance!.total || 0) - allocation,
      };
      const byId: any = state.byId;
      return {
        ...state,
        byId: {
          [PORTFOLIO_ID]: {
            ...byId[PORTFOLIO_ID],
            balance: newBalance,
          },
        },
      };
    }
    case DEDUCT_PAYOUT: {
      const { balance } = state.byId[1];
      const payout: number = action.payload.data;
      const newBalance = {
        ...balance,
        funds_withdrawn: (balance!.funds_withdrawn || 0) + payout,
        total: (balance!.total || 0) - payout,
      };
      const byId: any = state.byId;
      return {
        ...state,
        byId: {
          [PORTFOLIO_ID]: {
            ...byId[PORTFOLIO_ID],
            balance: newBalance,
          },
        },
      };
    }
    case ADD_PAYOUT: {
      const { balance } = state.byId[1];
      const payment: number = action.payload.data;
      const newBalance = {
        ...balance,
        payouts_due: (balance!.payouts_due || 0) + payment,
        total: (balance!.total || 0) + payment,
      };
      const byId: any = state.byId;
      return {
        ...state,
        byId: {
          [PORTFOLIO_ID]: {
            ...byId[PORTFOLIO_ID],
            balance: newBalance,
          },
        },
      };
    }
    case LOGIN:
      const { locale } = action.payload;
      return getInitialState(locale);
    default:
      return state;
  }
}

export const addBalance = (amount: number) => ({
  type: ADD_TOP_UP,
  payload: {
    data: amount,
  },
});

export const deductBalance = (amount: number) => ({
  type: DEDUCT_PREMIUM,
  payload: {
    data: amount,
  },
});

export const deductPayout = (amount: number) => ({
  type: DEDUCT_PAYOUT,
  payload: {
    data: amount,
  },
});

export const addPayout = (amount: number) => ({
  type: ADD_PAYOUT,
  payload: {
    data: amount,
  },
});

export const selectedPortfolioIdSelector = (state: RootState) => state.portfolios.selectedId;
const byIdSelector = (state: RootState) => state.portfolios.byId;

export const getSelectedPortfolio = createSelector(
  selectedPortfolioIdSelector,
  byIdSelector,
  (selectedId, byId) =>
    selectedId === undefined || byId === undefined ? undefined : byId[selectedId],
);

const allIdsSelector = (state: RootState) => state.portfolios.allIds;
export const getPortfoliosList = createSelector(
  allIdsSelector,
  byIdSelector,
  (allIds, portfolioById) =>
    allIds === undefined || portfolioById === undefined
      ? undefined
      : allIds.map(id => portfolioById[id]),
);

export const selectedPortfolioCurrencySelector = createSelector(getSelectedPortfolio, selected =>
  selected !== undefined ? selected.currency.iso_code : '',
);

export const selectedPortfolioBalanceSelector = createSelector(
  getSelectedPortfolio,
  selected => selected?.balance || ({} as PortfolioBalance),
);

export const selectedPortfolioTotalBalanceSelector = createSelector(
  selectedPortfolioBalanceSelector,
  selectedPortfolioBalances => selectedPortfolioBalances?.total || 0,
);
