import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import payoutsImg from './payouts.png';

const useStyles = makeStyles({
  emptyPortfolioImg: {
    maxWidth: 265,
    borderRadius: '50%',
    opacity: '70%',
  },
});

const UserNotFound = () => {
  const classes = useStyles();
  const content = (
    <React.Fragment>
      <Typography component="h1" variant="h4">
        Sorry! We can't find a user account associated with this email.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Please try a different email or reach out to a{' '}
        <a href="mailto:info@worldcovr.com">Customer Success Representative</a> if you have any
        questions.
      </Typography>
    </React.Fragment>
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <img className={classes.emptyPortfolioImg} src={payoutsImg} alt="Payouts Charts" />
      </Grid>
      <Grid item xs={12}>
        {content}
      </Grid>
    </Grid>
  );
};

export default UserNotFound;
