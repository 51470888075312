import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { Omit } from '@material-ui/types';

import PolicyStatus from '../PolicyStatus';
import { Policy } from '../../models';

interface PolicyItemLinkProps {
  label: string;
  policy: Policy;
}

function PolicyItemLink(props: PolicyItemLinkProps) {
  const { label, policy: pol } = props;
  const to = `/policies/${pol.id}`;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink} divider>
        <ListItemText
          primary={<Typography variant="body1">{label}</Typography>}
          secondary={`ID ${pol.id}`}
        />
        <ListItemSecondaryAction>
          <PolicyStatus policy={pol} />
        </ListItemSecondaryAction>
      </ListItem>
    </li>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  listRoot: {
    width: '100%',
  },
  title: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

export type PolicyListProps = {
  /** Array of policy summary data for the portfolio */
  policies: Policy[];
  /** Denote if the portfolio is Portfolio Insurance or Member Enrollment */
  portfolioType: 'PI' | 'ME';
  /** Title for the list */
  title?: string;
};

/**
 * A list of policies in the customer's portfolio used in mobile view.
 */
const PolicyList = (props: PolicyListProps) => {
  const { policies, portfolioType, title = 'Portfolio Policies' } = props;
  const classes = useStyles();
  const locationKey = portfolioType === 'PI' ? 'trigger_location' : 'customer';

  return (
    <React.Fragment>
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>

      <Paper className={classes.paper}>
        <List dense className={classes.listRoot}>
          {policies.map(pol => {
            let label = '';
            if (pol !== null && pol[locationKey] !== null) {
              // @ts-ignore: Object is possibly 'null'.
              label = pol[locationKey].name;
            }
            return <PolicyItemLink key={pol.id} label={label} policy={pol} />;
          })}
        </List>
      </Paper>
    </React.Fragment>
  );
};

export default PolicyList;
