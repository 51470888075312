import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import mapValues from 'lodash/mapValues';

import {
  Policy,
  CustomerSummary,
  TriggerLocation,
  Crop,
  ProductSummary,
  PolicyStatus,
  CurrencyCode,
  ApiErrorMessage,
} from '../../models';
import { RootState, FluxStandardAction } from '.';
import { selectedPortfolioIdSelector } from './portfolios';
import {
  getStartDate,
  getPlantingDate,
  getEndDate,
  getAlmondUploadDate,
  getAlmondStartDate,
  getAlmondEndDate,
} from './timeUtils';
import { localeMap } from '../env';

export const LOAD_POLICIES = '@@POLICIES/LOAD_POLICIES';
export const HYDRATE_STATE = '@@POLICIES/HYDRATE';

export const PLANT_POLICIES = '@@POLICIES/PLANT_POLICIES_STEP';
export const ACTIVATE_POLICIES = '@@POLICIES/ACTIVATE_POLICIES_STEP';
export const TRIGGER_POLICIES = '@@POLICIES/TRIGGER_POLICIES_STEP';
export const COMPLETE_POLICIES = '@@POLICIES/COMPLETE_POLICIES_STEP';

export interface PolicyData {
  id: number;
  customer: CustomerSummary | null;
  trigger_location: TriggerLocation;
  crop: Crop;
  product: ProductSummary;
  date_issued: string;
  date_planted?: string;
  date_start?: string;
  date_end?: string;
  date_last_payment: string;
  premium: number;
  status: PolicyStatus;
  policy_doc_pdf?: string;
  payout?: number;
  portfolio_id?: number;
  product_id?: number;
  coverage_limit?: number;
  metadata?: string;
  is_activated: boolean;
  tag_ids: number[];
}

export type PortfolioPoliciesState = {
  isLoadingData: boolean;
  error?: ApiErrorMessage;
  byId?: {
    [policyId: number]: Policy;
  };
  allIds?: number[];
  byPortfolio: {
    [portfolioId: number]: number[];
  };
};

type PolicyLocations = {
  [key: string]: TriggerLocation[];
};
const policyLocations: PolicyLocations = {
  'en-US': [
    {
      id: 750,
      name: 'Dixon',
      district_id: 20,
      latitude: 38.404684,
      longitude: -121.849194,
    },
    {
      id: 751,
      name: 'Butte',
      district_id: 20,
      latitude: 39.532131,
      longitude: -121.791868,
    },
    {
      id: 752,
      name: 'Modesto',
      district_id: 20,
      latitude: 37.722509,
      longitude: -121.05437,
    },
    {
      id: 753,
      name: 'Fresno',
      district_id: 20,
      latitude: 36.663005,
      longitude: -119.941297,
    },
  ],
  'pt-BR': [
    {
      id: 750,
      name: 'Brasília',
      district_id: 20,
      latitude: -15.793889,
      longitude: -47.882778,
    },
    {
      id: 751,
      name: 'Curitiba',
      district_id: 20,
      latitude: -25.416667,
      longitude: -49.25,
    },
    {
      id: 752,
      name: 'Teresina',
      district_id: 20,
      latitude: -5.0949,
      longitude: -42.8042,
    },
    {
      id: 753,
      name: 'Vilhena',
      district_id: 20,
      latitude: -12.740556,
      longitude: -60.145833,
    },
  ],
  'en-KE': [
    {
      id: 750,
      name: 'Nairobi',
      longitude: 36.817222,
      latitude: -1.286389,
      district_id: 20,
    },
    {
      id: 751,
      name: 'Mombasa',
      longitude: 39.666667,
      latitude: -4.05,
      district_id: 20,
    },
    {
      id: 752,
      name: 'Kisumu',
      longitude: 34.75,
      latitude: -0.1,
      district_id: 20,
    },
    {
      id: 753,
      name: 'Nakuru',
      longitude: 36.066667,
      latitude: -0.3,
      district_id: 20,
    },
  ],
};

const emptyInitialState: PortfolioPoliciesState = {
  isLoadingData: false,
  error: undefined,
  byId: {},
  allIds: [],
  byPortfolio: { '1': [] },
};

function getHydratedState(locale: string = 'en-GH'): PortfolioPoliciesState {
  const { currency, premium: baseAmount, productId } = localeMap[locale];

  const countryLocations = policyLocations[locale] ?? [];
  console.log('country locations', locale, countryLocations);

  let productData: any;
  if (productId === 2) {
    productData = {
      date_planted: getAlmondUploadDate(),
      date_issued: getAlmondUploadDate(),
      date_start: getAlmondStartDate(),
      date_end: getAlmondEndDate(),
      product_id: 2,
      sum_insured: baseAmount * 20,
    };
  } else {
    productData = {
      date_planted: getPlantingDate(),
      date_issued: getPlantingDate(),
      date_start: getStartDate(),
      date_end: getEndDate(),
      product_id: 1,
      sum_insured: baseAmount * 5,
    };
  }
  const byIdPolicies: Policy[] = countryLocations.map((loc, idx) => ({
    id: idx + 250,
    ...productData,
    trigger_location: loc,
    total_premium: {
      amount: baseAmount,
      currency: currency as CurrencyCode,
    },
    status: 'pending' as PolicyStatus,
    activation_status: 'pending_activation',
    created_at: '2020-01-01',
    is_eligible: false,
    payout: 0,
    is_activated: false,
    tag_ids: [],
  }));

  const hydratedInitialState: PortfolioPoliciesState = {
    isLoadingData: false,
    byId: keyBy(byIdPolicies, 'id'),
    allIds: [250, 251, 252, 253],
    byPortfolio: {
      '1': [250, 251, 252, 253],
    },
  };

  return hydratedInitialState;
}

const getInitialState = () => {
  const locale = window.sessionStorage.getItem('country');
  if (locale == null) {
    return emptyInitialState;
  } else {
    return getHydratedState(locale);
  }
};

export default function reducer(
  state = getInitialState(),
  action: FluxStandardAction,
): PortfolioPoliciesState {
  switch (action.type) {
    case HYDRATE_STATE:
      const { data: country } = action.payload;
      return getHydratedState(country);
    case LOAD_POLICIES:
      const policiesById = keyBy(action.payload!.data, 'id');
      const policyIds = Object.keys(policiesById).map(key => parseInt(key, 10));
      const { portfolioId } = action.meta;
      const currentPortfolioIds = state.byPortfolio[portfolioId] || [];
      return {
        ...state,
        isLoadingData: false,
        byId: {
          ...state.byId,
          ...policiesById,
        },
        allIds: state.allIds ? uniq([...state.allIds, ...policyIds]) : undefined,
        byPortfolio: {
          ...state.byPortfolio,
          [portfolioId]: uniq([...currentPortfolioIds, ...policyIds]),
        },
      };
    case PLANT_POLICIES: {
      const byId = state.byId;
      const newById = mapValues(byId, (pol: any) => ({
        ...pol,
        date_planted: getPlantingDate(),
        activation_status: 'pending_activation',
        is_eligible: false,
        payout: 0,
        triggerMetadata: undefined,
      }));
      return {
        ...state,
        byId: newById,
      };
    }
    case ACTIVATE_POLICIES: {
      const byId = state.byId;
      const newById = mapValues(byId, (pol: any) => ({
        ...pol,
        activation_status: 'active',
        is_eligible: false,
        payout: 0,
        triggerMetadata: undefined,
      }));
      return {
        ...state,
        byId: newById,
      };
    }
    case TRIGGER_POLICIES: {
      const byId = state.byId;
      const newById = mapValues(byId, (pol: any) => {
        const payoutFactor = pol.product_id === 2 ? 0.056 : 0.6;
        if (pol.id % 2 === 0) {
          console.log('sum insured', pol.sum_insured);
          return {
            ...pol,
            activation_status: 'active',
            is_eligible: true,
            payout: pol.sum_insured * payoutFactor,
            triggerMetadata: undefined,
          };
        } else {
          return {
            ...pol,
            activation_status: 'active',
            is_eligible: false,
            triggerMetadata: undefined,
          };
        }
      });
      return {
        ...state,
        byId: newById,
      };
    }
    case COMPLETE_POLICIES: {
      const byId = state.byId;
      const newById = mapValues(byId, (pol: any) => {
        if (pol.status === 'triggered') {
          return {
            ...pol,
            activation_status: 'expired',
            triggerMetadata: undefined,
          };
        } else {
          return {
            ...pol,
            activation_status: 'expired',
            triggerMetadata: undefined,
          };
        }
      });
      return {
        ...state,
        byId: newById,
      };
    }
    default:
      return state;
  }
}

export const loadMockPolicies = (policies: Policy[]) => ({
  type: LOAD_POLICIES,
  payload: {
    data: policies,
  },
  meta: { portfolioId: 1 },
});

export const policiesByPortfolioSelector = (state: RootState) => state.policies.byPortfolio;
export const policiesByIdSelector = (state: RootState) => state.policies?.byId || {};
export const policiesErrorSelector = (state: RootState) => state.policies.error;

export const getSelectedPortfolioPolicies = createSelector(
  selectedPortfolioIdSelector,
  policiesByPortfolioSelector,
  policiesByIdSelector,
  (portfolioId, byPortfolio, byId) => {
    if (!portfolioId || !byPortfolio) {
      return undefined;
    }
    const policyIds = byPortfolio[portfolioId];
    return policyIds === undefined ? undefined : policyIds.map(id => byId[id]);
  },
);

const policyIdSelector = (state: RootState, props: any) => props?.policy?.id;
export const policySelector = createSelector(
  policiesByIdSelector,
  policyIdSelector,
  (byId, policyId) => byId[policyId],
);
