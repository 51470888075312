import React from 'react';
import * as Sentry from '@sentry/browser';

type ErrorInfo = {
  componentStack: string;
};

type ErrorBoundaryState = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  eventId: string | null;
};

class ErrorBoundary extends React.Component {
  state: ErrorBoundaryState = { error: null, errorInfo: null, eventId: null };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    if (process.env['NODE_ENV'] === 'production') {
      // In prod also log error messages to an error reporting service
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      const errorString = this.state.error != null ? this.state.error.toString() : '';
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {errorString}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
          {process.env['NODE_ENV'] === 'production' ? (
            <button onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId! })}>
              Report feedback
            </button>
          ) : null}
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
