import { connect } from 'react-redux';

import { RootState } from '../reducers';
import { setPortfolioId, getPortfoliosList } from '../reducers/portfolios';
import PortfolioSelector from '../components/PortfolioSelector';

const mapState = (state: RootState) => ({
  selectedPortfolioId: state.portfolios.selectedId,
  portfolios: getPortfoliosList(state) || [],
});

const mapDispatch = {
  setPortfolioId,
};

const connector = connect(mapState, mapDispatch);

export default connector(PortfolioSelector);
