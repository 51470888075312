import React, { useEffect } from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';

import { Link, useLocation } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloudIcon from '@material-ui/icons/FilterDrama';
import SunIcon from '@material-ui/icons/Brightness5';

import { RootState } from '../reducers';
import { policyTriggersSelector, fetchPolicyTriggers } from '../reducers/triggers';
import { policySelector, fetchPolicy, policiesErrorSelector } from '../reducers/policies';
import { districtByIdSelector, fetchDistrict } from '../reducers/districts';

import PolicyDetailsSummaryCard from '../components/view/PolicyDetailsSummaryCard';
import Loader from '../components/Loader';
import { cropByProductIdSelector, productByIdSelector } from '../reducers/products';
import { portfolioByIdSelector } from '../reducers/portfolios';
import { District, Portfolio, Product } from '../models';
import PolicyTriggerCard from '../components/view/PolicyTriggerCard';
import ErrorPage from '../components/view/ErrorPage';
import { getToken } from '../reducers/user';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(3),
  },
  flex: {
    display: 'flex',
    flexFlow: 'column',
    padding: 0,
  },
  iconItem: {
    display: 'flex',
  },
  iconItemRow: {
    display: 'flex',
    flexGrow: 2,
    justifyContent: 'space-around',
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 35,
    margin: '5px 10px',
  },
  alignLeft: {
    textAlign: 'left',
  },
  payment: {
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  siteMap: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  triggerLabelRoot: {
    display: 'flex',
    textAlign: 'left',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  triggerLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
  triggerIcon: {
    fontSize: 55,
    marginRight: theme.spacing(2),
  },
}));

const mapState = (state: RootState, props: any) => {
  const { policyId } = props.match.params;
  const selectorProps = {
    policy: { id: policyId },
  };
  const products = productByIdSelector(state) || ({} as Product[]);
  const policy = policySelector(state, selectorProps);
  const policyError = policiesErrorSelector(state);
  const districts = districtByIdSelector(state) || ({} as District[]);
  const portfolios = portfolioByIdSelector(state) || ({} as Portfolio[]);
  const crops = cropByProductIdSelector(state);
  const districtId = policy?.trigger_location?.district_id;
  return {
    policy,
    policyError,
    policyTriggers: policyTriggersSelector(state, selectorProps),
    crop: crops[policy?.product_id!],
    productCode: products[policy?.product_id!].code,
    district: districtId ? districts[districtId] : ({} as District),
    portfolio: portfolios[policy?.portfolio_id!],
  };
};

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
interface PolicyDetailsProps extends PropsFromRedux {
  match: {
    params: {
      policyId: number;
    };
  };
}

function PolicyDetails(props: PolicyDetailsProps) {
  const { policyId } = props.match.params;
  const { policy, policyError, policyTriggers, district, crop, productCode } = props;
  const classes = useStyles();

  const token = useSelector(getToken);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getPolicy() {
      dispatch(fetchPolicy(policyId, token));
    }

    async function getTriggers() {
      dispatch(fetchPolicyTriggers(policyId, token));
    }

    if (!token) {
      return;
    }
    getPolicy();
    getTriggers();
  }, [token]);

  useEffect(() => {
    async function getDistrict() {
      dispatch(fetchDistrict(policy?.trigger_location!.district_id, token));
    }

    if (!token) {
      return;
    }
    if (policy?.trigger_location?.district_id && !district) {
      getDistrict();
    }
  }, [token, policy?.trigger_location]);

  type TriggerLabelProps = { label: string; icon: any };
  function TriggerLabel({ label, icon: TriggerIcon }: TriggerLabelProps) {
    return (
      <Grid item xs={12}>
        <div className={classes.triggerLabelRoot}>
          <TriggerIcon className={classes.triggerIcon} />
          <div className={classes.triggerLabel}>
            <Typography variant="body2" component="p" color="textSecondary">
              Triggers
            </Typography>
            <Typography variant="h5" component="p">
              {label}
            </Typography>
          </div>
        </div>
      </Grid>
    );
  }

  const triggersByType = groupBy(policyTriggers, 'trigger_type');
  console.log('triggers by type', triggersByType);
  if (policyError != null) {
    return <ErrorPage />;
  } else if (policy && !isEmpty(policy.trigger_location) && policyTriggers && district) {
    const currencyCode = policy.total_premium.currency;
    return (
      <div>
        <Typography className={classes.siteMap} variant="body2" color="textSecondary" component="p">
          <Link to={`/portfolios/${policy.portfolio_id}`}>Portfolio</Link> {'>'} Policy ID{' '}
          {policy.id}
        </Typography>
        <PolicyDetailsSummaryCard productCode={productCode} policy={policy} crop={crop} />
        <Typography className={classes.alignLeft} variant="caption" display="block" gutterBottom>
          Note: The total payout is determined by the maximum payout amount that was triggered by
          the policy.
        </Typography>
        {'fdd' in triggersByType ? (
          <>
            <Grid container spacing={2}>
              <TriggerLabel label="Frost Degree Day" icon={CloudIcon} />
              {triggersByType.fdd.map((trig, idx, arr) => (
                <Grid key={`trg-${idx}`} item xs={12} lg={(12 / arr.length) as any}>
                  <PolicyTriggerCard
                    trigger={trig}
                    sumInsured={policy.sum_insured!}
                    currencyCode={currencyCode}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        ) : null}
        {'crd' in triggersByType ? (
          <>
            <Grid container spacing={2}>
              <TriggerLabel label="Consecutive Rainy Day" icon={CloudIcon} />
              {triggersByType.crd.map((trig, idx, arr) => (
                <Grid key={`trg-${idx}`} item xs={12} lg={(12 / arr.length) as any}>
                  <PolicyTriggerCard
                    trigger={trig}
                    sumInsured={policy.sum_insured!}
                    currencyCode={currencyCode}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        ) : null}
        {'crw' in triggersByType ? (
          <>
            <Grid container spacing={2}>
              <TriggerLabel label="5-Day Moving Window Excess Rain" icon={CloudIcon} />
              {triggersByType.crw.map((trig, idx, arr) => (
                <Grid key={`trg-${idx}`} item xs={12} lg={(12 / arr.length) as any}>
                  <PolicyTriggerCard
                    trigger={trig}
                    sumInsured={policy.sum_insured!}
                    currencyCode={currencyCode}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        ) : null}
        {'crf' in triggersByType ? (
          <>
            <Grid container spacing={2}>
              <TriggerLabel label="Cumulative Rainfall (drought)" icon={SunIcon} />
              {[...(triggersByType.grm || []), ...triggersByType.crf].map((trig, idx, arr) => (
                <Grid key={`trg-${idx}`} item xs={12} lg={(12 / arr.length) as any}>
                  <PolicyTriggerCard
                    trigger={trig}
                    sumInsured={policy.sum_insured!}
                    currencyCode={currencyCode}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        ) : null}
        {'cdd' in triggersByType ? (
          <>
            <Grid container spacing={2}>
              <TriggerLabel label="Consecutive Dry Day" icon={SunIcon} />
              {triggersByType.cdd.map((trig, idx, arr) => (
                <Grid key={`trg-${idx}`} item xs={12} lg={(12 / arr.length) as any}>
                  <PolicyTriggerCard
                    trigger={trig}
                    sumInsured={policy.sum_insured!}
                    currencyCode={currencyCode}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        ) : null}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              component={Link}
              target="_blank"
              to={`/policies/${policy.id}/terms`}
              color="secondary"
              variant="contained"
              size="small"
            >
              Policy Terms Sheet
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return <Loader />;
  }
}

export default connector(PolicyDetails);
