import Mixpanel, { Dict } from 'mixpanel-browser';
import { MIXPANEL_TOKEN } from '../env';

Mixpanel.init(MIXPANEL_TOKEN);

const isProduction = process.env.NODE_ENV === 'production';

export const identify = (id: string) => {
  if (isProduction) Mixpanel.identify(id);
};

export const track = (name: string, props?: Dict) => {
  if (isProduction) Mixpanel.track(name, props);
};

export const setUser = (email: string, name: string, organizationId: number) => {
  if (isProduction) {
    const [firstName, ...lastName] = name.split(' ');
    Mixpanel.people.set(email, {
      $first_name: firstName,
      $last_name: lastName.join(' '),
      organization_id: organizationId,
    });
  }
};

export default {
  identify,
  track,
  setUser,
};
