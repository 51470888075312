import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACTIVATE_POLICIES,
  PLANT_POLICIES,
  TRIGGER_POLICIES,
  COMPLETE_POLICIES,
} from '../reducers/policies';
import { addPayout } from '../reducers/portfolios';
import {
  START_DAYS,
  getStartDate,
  getEndDate,
  getPlantingDate,
  getFloweringDate,
  getAlmondUploadDate,
  getAlmondTriggerDate,
  getAlmondEndDate,
  getAlmondStartDate,
} from '../reducers/timeUtils';
import moment from 'moment';
import { SET_DATE, INCREASE_STEP, DECREASE_STEP } from '../reducers/time';
import { RootState } from '../reducers';
import { Policy } from '../../models';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    maxWidth: 400,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
}));

const getPortfolioSteps = (productType: any) => [
  {
    label: 'Price Portfolio',
    date: productType === 'almond' ? getAlmondUploadDate() : getPlantingDate(),
    days: `Shortly After Providing Portfolio Data`,
    event: PLANT_POLICIES,
  },
  {
    label: 'Monitor Weather',
    date: productType === 'almond' ? getAlmondStartDate() : getStartDate(),
    days: `Portfolio Activation`,
    event: ACTIVATE_POLICIES,
  },
  {
    label: 'Trigger Payouts',
    date:
      productType === 'almond'
        ? getAlmondTriggerDate()
        : moment(getFloweringDate())
            .add(10, 'days')
            .format('YYYY-MM-DD'),
    days: `Partway through the Monitoring Period`,
    event: TRIGGER_POLICIES,
  },
  {
    label: 'Claim Payouts',
    date: productType === 'almond' ? getAlmondEndDate() : getEndDate(),
    days: `Portfolio Expiration`,
    event: COMPLETE_POLICIES,
  },
];

type TimeShiftProps = {
  policies?: Policy[];
  productType: 'almond' | 'maize';
};
export default function TimeShift(props: TimeShiftProps) {
  const { policies, productType } = props;
  const portfolioSteps = getPortfolioSteps(productType);
  const dispatch = useDispatch();
  const activeStep = useSelector((state: RootState) => state.time?.step);
  const [paidOut, setPaidOut] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const maxSteps = portfolioSteps.length;

  const dispatchShift = (newStep: number) => {
    const step = portfolioSteps[newStep];
    dispatch({ type: step.event });
    dispatch({ type: SET_DATE, payload: step.date });
  };

  const handleNext = () => {
    const newStep = activeStep + 1;
    dispatch({ type: INCREASE_STEP });
    dispatchShift(newStep);
    if (newStep === 3) {
      const payout = policies!.reduce((total: number, pol: any) => total + (pol.payout || 0), 0);
      if (!paidOut) {
        dispatch(addPayout(payout));
        setPaidOut(true);
      }
    }
  };

  const handleBack = () => {
    const newStep = activeStep - 1;
    dispatch({ type: DECREASE_STEP });
    dispatchShift(newStep);
  };

  return (
    <Paper className={classes.root}>
      <Typography component="h2" variant="body1">
        <strong>Time Shifter</strong>
      </Typography>
      <Typography component="h2" variant="h5">
        {portfolioSteps[activeStep].label}
      </Typography>
      <Typography component="p" variant="button">
        {portfolioSteps[activeStep].date}
      </Typography>
      <Typography component="p" variant="subtitle2">
        {portfolioSteps[activeStep].days}
      </Typography>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="progress"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
    </Paper>
  );
}
