import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';

import { DRAWER_WIDTH, toCurrency } from '../utils';

import logo from './worldcover_logo_v2.svg';

const useStyles = makeStyles(theme => ({
  unauthBar: {
    backgroundColor: '#121427',
  },
  appBar: {
    backgroundColor: 'white',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  flex: {
    display: 'flex',
  },
  icon: {
    paddingTop: theme.spacing(1),
  },
  avatar: {
    width: '35px',
  },
  balanceButton: {
    fontWeight: 600,
    borderWidth: 'medium',
    marginRight: theme.spacing(1),
  },
  loginButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    justifyContent: 'space-between',
  },
}));

type HeaderBarProps = {
  handleDrawerToggle: () => void;
  currency: string;
  balance: number;
  isAuthenticated: boolean;
  loginWithRedirect: Function;
  logout: Function;
  selectorComponent: JSX.Element | any;
  user: any;
};

export function HeaderBar(props: HeaderBarProps) {
  const {
    handleDrawerToggle,
    currency = 'USD',
    balance,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    selectorComponent: PortfolioSelectorComponent,
  } = props;
  const classes = useStyles({});

  return (
    <AppBar position="fixed" className={isAuthenticated ? classes.appBar : classes.unauthBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Hidden smDown>
          {!isAuthenticated && (
            <div className={classes.icon}>
              <img width={DRAWER_WIDTH} src={logo} alt="WorldCover" />
            </div>
          )}
          {isAuthenticated && <PortfolioSelectorComponent variant="light" />}
        </Hidden>

        {isAuthenticated ? (
          <span className={classes.flex}>
            <Hidden smDown>
              <Button className={classes.balanceButton} variant="outlined" color="primary">
                Balance:{' '}
                {balance != null && currency != null && currency !== ''
                  ? toCurrency(balance, currency)
                  : 'N/A'}
              </Button>
            </Hidden>
            {user.picture && (
              <img className={classes.avatar} src={user.picture} alt="User Avatar" />
            )}
            <Button className={classes.loginButton} onClick={() => logout()} color="inherit">
              Log Out
            </Button>
          </span>
        ) : (
          <span>
            <Button
              className={classes.loginButton}
              onClick={() => loginWithRedirect({})}
              color="secondary"
            >
              Log In
            </Button>
          </span>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default HeaderBar;
