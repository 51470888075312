import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Magic } from 'magic-sdk';

import { RootState } from '../reducers';
import {
  selectedPortfolioCurrencySelector,
  selectedPortfolioTotalBalanceSelector,
  selectedPortfolioOrganizationSelector,
} from '../reducers/portfolios';
import HeaderBar from '../components/HeaderBar';
import PortfolioSelector from './PortfolioSelectorConnected';
import { isAuthenticated, logout } from '../reducers/user';
import { MAGIC_API_KEY } from '../env';
import { Dispatch } from 'redux';

const magic = new Magic(MAGIC_API_KEY);

const mapState = (state: RootState) => ({
  organization: selectedPortfolioOrganizationSelector(state),
  currency: selectedPortfolioCurrencySelector(state),
  balance: selectedPortfolioTotalBalanceSelector(state),
  isAuth: isAuthenticated(state),
});

const mapDispatch = (dispatch: Dispatch) => ({
  logout: () => dispatch(logout()),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
interface HeaderBarAuthProps extends PropsFromRedux {
  handleDrawerToggle: () => void;
}

function HeaderBarAuth(props: HeaderBarAuthProps) {
  const { isAuth, logout, ...rest } = props;
  const user = {};

  const wrappedLogout = () => {
    // TODO fix logout
    logout();
    magic.user.logout();
    window.localStorage.clear();
    window.sessionStorage.clear();
  };

  return (
    <HeaderBar
      isAuthenticated={isAuth}
      loginWithRedirect={/*loginWithRedirect*/ () => {}}
      selectorComponent={PortfolioSelector}
      logout={wrappedLogout}
      user={user}
      {...rest}
    />
  );
}

export default connector(HeaderBarAuth);
