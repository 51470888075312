import React, { useState, useEffect, useRef } from 'react';
import useComponentSize from '@rehooks/component-size';

import { Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';

import CircularSummaries from './CircularSummaries';
import PortfolioMap from './PortfolioMap';
import PolicySummaryTable from './PolicySummaryTable';
import Loader from '../Loader';
import PolicyList from './PolicyList';
import {
  Policy,
  PolicyLocation,
  PolicyStatus,
  DistrictMap,
  Portfolio,
  PortfolioSummary,
} from '../../models';

import { calculateStatus } from '../../utils';
import PortfolioOverviewCard from './PortfolioOverviewCard';

const useStyles = makeStyles((theme: Theme) => ({
  circularSummaryContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  loader: {
    minHeight: '100vh',
    overflow: 'auto',
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  card: {
    textAlign: 'center',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullSize: {
    height: '100%',
  },
  paper: {
    padding: theme.spacing(2),
  },
  emptyPortfolioImg: {
    maxWidth: 265,
    borderRadius: '50%',
    opacity: '70%',
  },
}));

export type PortfolioSummaryAppProps = {
  filterComponent?: any;
  onTableRowClick: (event: any, rowData?: Policy) => void;
  portfolioPolicies?: Policy[];
  portfolioDistricts?: DistrictMap;
  portfolioInfo?: Portfolio;
  portfolioSummary?: PortfolioSummary;
  currentDate?: string;
};

const PortfolioSummaryApp = (props: PortfolioSummaryAppProps) => {
  const {
    filterComponent,
    onTableRowClick,
    portfolioPolicies,
    portfolioInfo,
    portfolioSummary,
    currentDate,
  } = props;
  const portfolioId: number | undefined = portfolioInfo?.id;
  const classes = useStyles();

  const [locations, setLocations] = useState<PolicyLocation[]>([]);

  const currencyCode =
    portfolioInfo && portfolioInfo.currency ? portfolioInfo.currency.iso_code : '';

  // Parsing Policy Data
  useEffect(() => {
    function parseLocationData(policies: Policy[]) {
      type PolicySummaryStatus =
        | 'All Expired'
        | 'All Pending'
        | 'Some In Progress'
        | 'Some Payouts Eligible'
        | 'Some Policies Triggered';

      function getSummaryStatus(
        currentStatus: PolicySummaryStatus | '',
        incomingPolicyStatus: PolicyStatus,
      ): PolicySummaryStatus {
        // All Expired, All Pending, Some In Progress, someTriggered
        if (currentStatus === '') {
          switch (incomingPolicyStatus) {
            case 'expired':
              return 'All Expired';
            case 'priced':
            case 'pending':
              return 'All Pending';
            case 'active':
              return 'Some In Progress';
            case 'triggered':
              return 'Some Policies Triggered';
            default:
              return 'Some Payouts Eligible';
          }
        } else if (currentStatus === 'Some Policies Triggered') {
          switch (incomingPolicyStatus) {
            case 'priced':
            case 'pending':
            case 'expired':
            case 'active':
            case 'triggered':
              return 'Some Policies Triggered';
            default:
              return 'Some Payouts Eligible';
          }
        } else if (currentStatus === 'All Pending') {
          switch (incomingPolicyStatus) {
            case 'priced':
            case 'pending':
              return 'All Pending';
            case 'expired':
            case 'active':
              return 'Some In Progress';
            case 'triggered':
              return 'Some Policies Triggered';
            default:
              return 'Some Payouts Eligible';
          }
        } else if (currentStatus === 'All Expired') {
          switch (incomingPolicyStatus) {
            case 'expired':
              return 'All Expired';
            case 'priced':
            case 'pending':
            case 'active':
              return 'Some In Progress';
            case 'triggered':
              return 'Some Policies Triggered';
            default:
              return 'Some Payouts Eligible';
          }
        } else if (currentStatus === 'Some In Progress') {
          switch (incomingPolicyStatus) {
            case 'expired':
            case 'priced':
            case 'pending':
            case 'active':
              return 'Some In Progress';
            case 'triggered':
              return 'Some Policies Triggered';
            default:
              return 'Some Payouts Eligible';
          }
        } else {
          // if (currentStatus === "Some Payouts Triggered") {
          return 'Some Payouts Eligible';
        }
      }

      const rawLocationData: PolicyLocation[] = portfolioPolicies!
        .filter(pol => pol.trigger_location != null)
        .map(pol => ({
          id: pol.trigger_location!.id,
          label: pol.trigger_location!.name,
          latitude: pol.trigger_location!.latitude,
          longitude: pol.trigger_location!.longitude,
          payout: pol.payout || 0,
          premium: pol.total_premium.amount,
          status: calculateStatus(pol),
        }));
      type LocationData = { [id: number]: PolicyLocation };
      let locationData: LocationData = {};
      // TODO come up with better way to do this w/ libraries
      rawLocationData.forEach(loc => {
        if (loc.id in locationData) {
          // Aggregate payout & premium
          locationData[loc.id].premium += loc.premium;
          locationData[loc.id].payout += loc.payout;
          const summaryStatus = (locationData[loc.id].status as PolicySummaryStatus) || '';
          locationData[loc.id].status = getSummaryStatus(summaryStatus, loc.status as PolicyStatus);
        } else {
          // Create object
          locationData[loc.id] = {
            ...loc,
            status: getSummaryStatus('', loc.status as PolicyStatus),
          };
        }
      });
      return Object.values(locationData);
    }

    if (portfolioPolicies !== undefined) {
      setLocations(parseLocationData(portfolioPolicies));
    }
  }, [portfolioId, portfolioPolicies]);

  // Adjust Width
  const targetRef = useRef(null);
  let { width } = useComponentSize(targetRef);
  // Type Handling for Grid w/ Ref
  const RefGrid = (props: any) => <Grid {...props} />;

  if (
    portfolioPolicies === undefined ||
    portfolioInfo === undefined ||
    portfolioSummary === undefined
  ) {
    return (
      <div className={classes.loader}>
        <Loader />;
      </div>
    );
  } else {
    return (
      <div className={classes.root} ref={targetRef}>
        <Typography component="h1" variant="h4" gutterBottom>
          Portfolio Overview
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <PortfolioOverviewCard
              sumInsured={portfolioSummary.total_sum_insured}
              triggeredPayout={portfolioSummary.total_payout}
              startDate={portfolioSummary.start_date}
              endDate={portfolioSummary.end_date}
              currentDate={currentDate}
              currencyCode={currencyCode}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <CircularSummaries summary={portfolioSummary!} />
          </Grid>

          <Hidden smDown>
            <RefGrid item xs={12}>
              <PortfolioMap
                parentWidth={width - 80}
                locations={locations}
                currency={currencyCode}
              />
            </RefGrid>
          </Hidden>

          <Hidden smDown>
            <Grid item xs={12}>
              {!!portfolioInfo && portfolioPolicies !== undefined && (
                <PolicySummaryTable
                  filterComponent={filterComponent}
                  portfolioType={portfolioInfo.portfolio_type}
                  onRowClick={onTableRowClick}
                  policies={portfolioPolicies}
                  currency={currencyCode}
                  title="Portfolio Policies"
                />
              )}
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item xs={12}>
              {!!portfolioInfo && portfolioPolicies !== undefined && (
                <PolicyList
                  policies={portfolioPolicies}
                  portfolioType={portfolioInfo.portfolio_type}
                />
              )}
            </Grid>
          </Hidden>
        </Grid>
      </div>
    );
  }
};

export default PortfolioSummaryApp;
