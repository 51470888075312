import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import AddLocationIcon from '@material-ui/icons/AddLocation';
import PeopleIcon from '@material-ui/icons/People';
import SettingsSystemDaydreamIcon from '@material-ui/icons/SettingsSystemDaydream';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: 'flex',
  },
  content: {
    width: '100%',
    minHeight: 160,
  },
}));

export default function LoginBenefits() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid item xs={12} sm={6}>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <div>
              <AddLocationIcon />
            </div>
            <div>
              <Typography variant="h6" gutterBottom>
                Build your Portfolio
              </Typography>
              <Typography variant="body2" gutterBottom>
                Top up your account, set locations, and we'll price your policy.
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <div>
              <SettingsSystemDaydreamIcon />
            </div>
            <div>
              <Typography variant="h6" gutterBottom>
                Real-time Monitoring
              </Typography>
              <Typography variant="body2" gutterBottom>
                Track your policy along the growing season and receive alerts when droughts occur.
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <div>
              <MonetizationOnIcon />
            </div>
            <div>
              <Typography variant="h6" gutterBottom>
                Quick and Easy Payouts
              </Typography>
              <Typography variant="body2" gutterBottom>
                Collect your triggered payout online - no claim required!
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <div>
              <PeopleIcon />
            </div>
            <div>
              <Typography variant="h6" gutterBottom>
                Account Management
              </Typography>
              <Typography variant="body2" gutterBottom>
                Manage and view all policies, premium and triggered payouts across your organization
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </React.Fragment>
  );
}
