import React from 'react';
import clsx from 'clsx';

import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';

import PrintIcon from '@material-ui/icons/Print';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import logo from './new_logo.png';
import {
  ProductSummary,
  Policy,
  PolicyTrigger,
  ObjectLiteral,
  Portfolio,
  District,
} from '../../models';
import { toCurrency } from '../../utils';

const useStyles = makeStyles(theme => ({
  '@media print': {
    noPrint: {
      display: 'none !important',
    },
    termSheetBody: {
      backgroundColor: 'white !important',
    },
    locationSection: {
      backgroundColor: 'white !important',
    },
    paymentSection: {
      background: 'white !important',
    },
  },
  noPrint: {
    backgroundColor: '#121427',
    textAlign: 'left',
    '& button': {
      color: '#fafafa',
    },
  },
  termSheetBody: {
    textAlign: 'left',
    width: '100%',
    minWidth: '1024px',
    backgroundColor: '#fafafa',
    color: '#121427',
    flexDirection: 'column',
    '& td': {
      padding: 5,
    },
  },
  logo: {
    '& img': {
      maxWidth: 250,
    },
  },
  header: {
    display: 'flex',
    backgroundColor: '#121427',
    color: '#fafafa',
    padding: '50px 50px 0 50px',
    flexDirection: 'column',
  },
  headerBorder: {
    borderTop: '1px solid #4a4a4a',
  },
  headerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
  },
  headerSideBorder: {
    borderLeft: '1px solid #4a4a4a',
    borderRight: '1px solid #4a4a4a',
    padding: '0 50px',
  },
  subtitle: {
    fontFamily: 'Raleway, "Roboto Condensed", Arial, Helvetica, sans-serif',
    fontWeight: 400,
  },
  subtitleBox: {
    maxWidth: 250,
    '> p': {
      color: 'silver',
      fontFamily: 'Raleway, "Roboto Condensed", Arial, Helvetica, sans-serif',
      fontWeight: 400,
    },
  },
  contractInfo: {
    padding: '20px 50px',
    '& table': {
      width: '100%',
    },
    '& td:first-child': {
      width: 350,
    },
    '& td:nth-child(2)': {
      fontWeight: 700,
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#efeeee',
    },
  },
  paymentSection: {
    display: 'flex',
    alignItems: 'center',
    background: `linear-gradient(
      0deg,
      rgba(239, 238, 238, 1) 0%,
      rgba(239, 238, 238, 1) 50%,
      rgba(250, 250, 250, 1) 100%
    )`,
    paddingBottom: 60,
  },
  borderLeft: {
    flexGrow: 3,
  },
  borderRight: {
    flexGrow: 1,
  },
  payBox: {
    display: 'flex',
  },
  kpiBox: {
    padding: '20px 50px',
    color: '#fafafa',
    width: 200,
    '& >p, & >h1': {
      margin: 0,
    },
  },
  premiumBox: {
    backgroundColor: '#3bae65',
  },
  siBox: {
    backgroundColor: '#121427',
    '& >h1': {
      color: '#fcbf09',
    },
  },
  locationSection: {
    padding: '20px 50px',
    backgroundColor: '#efeeee',
    paddingBottom: 75,
    '& >p': {
      fontWeight: 700,
    },
  },
  mapImg: {
    border: '3px solid #121427',
  },
  payoutScheduleSection: {
    padding: '20px 50px',
  },
  payoutSchedule: {
    marginBottom: 50,
    '& >h2': {
      marginBottom: 0,
    },
  },
  triggerDescription: {
    marginTop: 5,
  },
  scheduleTable: {
    borderCollapse: 'collapse',
    width: '100%',
    '& thead tr:first-child': {
      backgroundColor: '#121427',
      color: '#fafafa',
    },
    '& th': {
      padding: 5,
      minWidth: 150,
    },
    '& tbody tr:nth-child(even)': {
      backgroundColor: '#efeeee',
    },
  },
}));

type TermSheetProps = {
  policy: Policy;
  triggers: PolicyTrigger[];
  portfolio?: Portfolio;
  product?: ProductSummary;
  crop: string;
  district: District;
};

function PayoutSchedule(props: any) {
  const { title, description, unit, triggers, currency } = props;
  const classes = useStyles();

  const periodMap: ObjectLiteral = {
    1: 'Germination',
    2: 'Flowering',
    3: 'Maturation',
    4: 'Late Maturation',
    5: 'Late Maturation',
  };

  return (
    <div className={classes.payoutSchedule}>
      <h2 className={classes.subtitle}>{title}</h2>
      <p className={clsx(classes.subtitle, classes.triggerDescription)}>{description}</p>
      <table className={classes.scheduleTable}>
        <thead>
          <tr>
            <th>Period</th>
            <th>Start</th>
            <th>End</th>
            <th>Theshold ({unit})</th>
            <th>Payout Factor</th>
            <th>Payout ({currency})</th>
          </tr>
        </thead>
        <tbody>
          {triggers.map((trig: any) => (
            <tr key={trig.id}>
              <td>{periodMap[trig.period]}</td>
              <td>{trig.start_date}</td>
              <td>{trig.end_date}</td>
              <td>{trig.threshold}</td>
              <td>{trig.factor * 100}%</td>
              <td>{trig.payout}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default function TermSheet(props: TermSheetProps) {
  const { policy, triggers, portfolio, crop, district } = props;
  const loc = policy.trigger_location!;
  const currency = policy.total_premium.currency;
  const classes = useStyles();

  function flattenTriggers(trig: PolicyTrigger) {
    const { id, period, start_date, end_date, trigger_type } = trig;
    const flatTriggers = trig.triggers.map(trigThres => ({
      id: `${id}-${trigThres.threshold}`,
      trigger_type,
      period,
      start_date,
      end_date,
      ...trigThres,
    }));
    return flatTriggers;
  }
  const allTriggers = groupBy(flatten(triggers.map(flattenTriggers)), 'trigger_type');

  const insurerMapByCountry: ObjectLiteral = {
    KE: 'Geminia Insurance',
    GH: 'Quality Insurance Company',
    UG: 'NIC General Insurance Company Ltd',
  };

  const insurerMapByCurrency: ObjectLiteral = {
    KES: 'Geminia Insurance',
    GHS: 'Quality Insurance Company',
    UGX: 'NIC General Insurance Company Ltd',
  };
  const insurer =
    (portfolio
      ? insurerMapByCountry[portfolio.country.iso_code]
      : insurerMapByCurrency[policy.total_premium.currency]) ?? 'N/A';

  const policyHolder = policy.customer ? policy.customer.name : portfolio?.organization?.name;
  const triggerLocation =
    `${loc.name}, ${district.name}` + (portfolio ? `, ${portfolio?.country.iso_code}` : '');

  return (
    <React.Fragment>
      <div className={classes.noPrint}>
        <IconButton aria-label="print" onClick={() => window.print()}>
          <PrintIcon fontSize="small" />
        </IconButton>
      </div>
      <div className={classes.termSheetBody}>
        <div className={classes.header}>
          <div className={classes.headerTitle}>
            <div className={classes.logo}>
              <img alt="worldcover logo" src={logo} />
            </div>
            <div>
              <h1>Weather Index Insurance</h1>
              <h2 className={classes.subtitle}>Terms Sheet - Policy No. {policy.id}</h2>
            </div>
          </div>
          <div className={clsx(classes.headerTitle, classes.headerBorder)}>
            <div className={classes.subtitleBox}>
              <p>Policy Holder</p>
              <h2>{policyHolder}</h2>
            </div>
            <div className={clsx(classes.subtitleBox, classes.headerSideBorder)}>
              <p>Insurance Product</p>
              <h2>CropAssure {crop} Cover</h2>
            </div>
            <div className={classes.subtitleBox}>
              <p>Insurer</p>
              <h2>{insurer}</h2>
            </div>
          </div>
        </div>
        <div className={classes.contractInfo}>
          <table>
            <tbody>
              <tr>
                <td>Crop Covered</td>
                <td>{crop}</td>
              </tr>
              <tr>
                <td>Trigger Location</td>
                <td>{loc!.name}</td>
              </tr>
              <tr>
                <td>Longitude</td>
                <td>{loc!.longitude}</td>
              </tr>
              <tr>
                <td>Latitude</td>
                <td>{loc!.latitude}</td>
              </tr>
              <tr>
                <td>Planting Date</td>
                <td>{policy.date_planted}</td>
              </tr>
              <tr>
                <td>Inception date</td>
                <td>{policy.date_start}</td>
              </tr>
              <tr>
                <td>Expiry date</td>
                <td>{policy.date_end}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={classes.paymentSection}>
          <div className={clsx(classes.headerBorder, classes.borderLeft)}></div>
          <div className={classes.payBox}>
            <div className={clsx(classes.kpiBox, classes.premiumBox)}>
              <p className={classes.subtitle}>Premium</p>
              <h1>{toCurrency(policy.total_premium.amount, currency)}</h1>
            </div>
            <div className={clsx(classes.kpiBox, classes.siBox)}>
              <p className={classes.subtitle}>Sum Insured</p>
              <h1>{toCurrency(policy.sum_insured!, currency)}</h1>
            </div>
          </div>
          <div className={clsx(classes.headerBorder, classes.borderRight)}></div>
        </div>
        <div className={classes.locationSection}>
          <h2>Trigger Location</h2>
          <h3 className={classes.subtitle}>{triggerLocation}</h3>
          <h4 className={classes.subtitle}>{`(${loc!.latitude}, ${loc!.longitude})`}</h4>

          <img
            alt="trigger location map"
            className={classes.mapImg}
            src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+9ed4bd(${
              loc!.longitude
            },${loc!.latitude}/${loc!.longitude},${
              loc!.latitude
            },9,0/500x350?access_token=pk.eyJ1IjoiamFzb25zY2hhcGlybyIsImEiOiJjazNlcnJpbXUwMWRzM2VtbDhpcTdxemN3In0.PVLZkH91kDLHYqq2g45Y5g`}
          />
        </div>
        <div className={classes.headerBorder}></div>
        <div className={classes.payoutScheduleSection}>
          <h2>Payout Schedule</h2>
          {'crf' in allTriggers ? (
            <PayoutSchedule
              title="Cumulative Rainfall"
              description="Triggers when rainfall is less than the threshold over the full monitoring period."
              unit="mm"
              triggers={allTriggers.crf}
              currency={currency}
            />
          ) : null}

          {'cdd' in allTriggers ? (
            <PayoutSchedule
              title="Consecutive Dry Days"
              description="Triggers when rainfall is 2.5mm or less every day for the threshold number of days in a row during the monitoring period."
              unit="days"
              triggers={allTriggers.cdd}
              currency={currency}
            />
          ) : null}

          {'crd' in allTriggers ? (
            <PayoutSchedule
              title="Consecutive Rainy Days"
              description="Triggers when rainfall is 7.5mm or more every day for the threshold number of days in a row during the monitoring period."
              unit="days"
              triggers={allTriggers.crd}
              currency={currency}
            />
          ) : null}

          {'crw' in allTriggers ? (
            <PayoutSchedule
              title="Moving Window Cumulative Rainfall"
              description="Triggers when rainfall exceeds than the threshold over a five-day moving monitoring window."
              unit="mm"
              triggers={allTriggers.crw}
              currency={currency}
            />
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
}
