import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { Magic } from 'magic-sdk';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import { MAGIC_API_KEY } from '../env';
import { isAuthenticated, login, TokenData } from '../reducers/user';
import swapMagicToken from '../utils/login';
import Loader from '../components/Loader';

const magic = new Magic(MAGIC_API_KEY);

export default function FilterPolicyTable(props: any) {
  // Assume user has logged in
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [errorMessage, setError] = useState<string>();
  const isAuth = useSelector(isAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    async function checkAuthAndRedirect() {
      if (await magic.user.isLoggedIn()) {
        const storageToken = localStorage.getItem('apitoken');
        console.log('has storage token?', storageToken);
        if (storageToken != null) {
          // Use existing login token
          const { token }: TokenData = JSON.parse(storageToken);
          dispatch(login(token));
        } else {
          // Generate new login token
          const magicToken = await magic.user.getIdToken();
          swapMagicToken(magicToken, dispatch, setError);
        }
      } else {
        // User came to page without logging in via magic link
        console.log('Not logged in! Redirect to /login');
        setIsLoggedIn(false);
      }
    }
    checkAuthAndRedirect();
  });

  if (isAuth === true) {
    return <Redirect to="/monitor" />;
  } else if (isLoggedIn === false) {
    return <Redirect to="/login" />;
  } else if (errorMessage != null) {
    console.log('ERROR!', errorMessage);
    return (
      <div>
        <h1>Sorry! Something went wrong during the login process.</h1>
        <Button onClick={() => setIsLoggedIn(false)}>Back</Button>
      </div>
    );
  } else {
    return <Loader />;
  }
}
