import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import intersection from 'lodash/intersection';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { useSelector, useDispatch } from 'react-redux';

import PortfolioDashboard from '../components/view/PortfolioSummaryDashboard';
import FilterPolicyTable from './FilterPolicyTable';
import EmptyPortfolio from '../components/view/EmptyPortfolio';
import ErrorPage from '../components/view/ErrorPage';
import Loader from '../components/Loader';

import { fetchPortfolioPolicies, getSelectedPortfolioPolicies } from '../reducers/policies';
import {
  getSelectedPortfolio,
  selectedPortfolioIdSelector,
  setPortfolioId,
} from '../reducers/portfolios';
import { RootState } from '../reducers';
import { fetchPolicyTriggers } from '../reducers/triggers';
import {
  filteredDistrictsSelector,
  filteredTagsSelector,
  filteredEligibleSelector,
  filteredActivationStatusSelector,
} from '../reducers/filters';
import {
  fetchPortfolioSummary,
  getPortfolioSummaryById,
  isLoadingPortfolioSummary,
  portfolioSummaryErrorSelector,
} from '../reducers/portfolio-summaries';
import { Policy } from '../models';
import { getToken } from '../reducers/user';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

function MonitorPortfolio(props: any) {
  const { portfolioId } = props.match.params;
  const classes = useStyles();
  const dispatch = useDispatch();

  let history = useHistory();

  const token = useSelector(getToken);
  const portfolioPolicies = useSelector((state: RootState) => getSelectedPortfolioPolicies(state));
  const portfolio = useSelector((state: RootState) => getSelectedPortfolio(state));
  const selectedPortfolioId = useSelector((state: RootState) => selectedPortfolioIdSelector(state));
  const alreadyLoadingPortfolioSummary = useSelector((state: RootState) =>
    isLoadingPortfolioSummary(state),
  );
  const selectedPortfolioSummary = useSelector((state: RootState) =>
    getPortfolioSummaryById(state, selectedPortfolioId),
  );
  const portfolioSummaryError = useSelector((state: RootState) =>
    portfolioSummaryErrorSelector(state),
  );
  const filteredDistricts = useSelector((state: RootState) => filteredDistrictsSelector(state));
  const filteredTagIds = useSelector((state: RootState) => filteredTagsSelector(state));
  const filteredEligbleOnly = useSelector((state: RootState) => filteredEligibleSelector(state));
  const filteredActivationStatues = useSelector((state: RootState) =>
    filteredActivationStatusSelector(state),
  );

  useEffect(() => {
    if (token == null) {
      return;
    }
    if (portfolioId != null) {
      dispatch(setPortfolioId(portfolioId));
    }
    if (!selectedPortfolioId) {
      return;
    }

    async function fetchPolicies(selectedPortfolioId: number) {
      dispatch(fetchPortfolioPolicies(selectedPortfolioId, token));
    }

    async function fetchSummary(selectedPortfolioId: number) {
      dispatch(fetchPortfolioSummary(token, selectedPortfolioId));
    }

    if (portfolioPolicies === undefined) {
      fetchPolicies(selectedPortfolioId);
    }
    if (!alreadyLoadingPortfolioSummary && selectedPortfolioSummary === undefined) {
      fetchSummary(selectedPortfolioId);
    }
  }, [dispatch, token, portfolioId, selectedPortfolioId, portfolioPolicies]);

  async function getTriggers(policyId: number) {
    dispatch(fetchPolicyTriggers(policyId, token));
  }

  let filteredPolicies = portfolioPolicies;
  if (portfolioPolicies !== undefined && filteredDistricts.length > 0) {
    filteredPolicies = filteredPolicies!.filter(pol => {
      const districtId = pol.trigger_location?.district_id;
      return districtId && filteredDistricts.includes(districtId);
    });
  }
  if (portfolioPolicies !== undefined && filteredTagIds.length > 0) {
    filteredPolicies = filteredPolicies!.filter(pol => {
      const matchingTags = intersection(pol.tag_ids, filteredTagIds);
      return matchingTags.length > 0;
    });
  }
  if (portfolioPolicies !== undefined && filteredEligbleOnly === true) {
    filteredPolicies = filteredPolicies!.filter(pol => pol.is_eligible);
  }
  if (portfolioPolicies !== undefined && filteredActivationStatues.length !== 0) {
    filteredPolicies = filteredPolicies!.filter(pol =>
      filteredActivationStatues.includes(pol.activation_status),
    );
  }

  let content = null;
  if (portfolioSummaryError != null) {
    content = <ErrorPage />;
  } else if (portfolioPolicies === undefined || selectedPortfolioSummary === undefined) {
    content = <Loader />;
  } else if (portfolioPolicies.length === 0) {
    content = <EmptyPortfolio />;
  } else {
    content = (
      <PortfolioDashboard
        portfolioInfo={portfolio}
        portfolioPolicies={filteredPolicies}
        portfolioSummary={selectedPortfolioSummary}
        onTableRowClick={(event: any, rowData?: Policy) => {
          history.push(`/policies/${rowData!.id}`);
        }}
        filterComponent={FilterPolicyTable}
      />
    );
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {content}
      </Grid>
    </div>
  );
}

export default MonitorPortfolio;
