// Handles high-level app routing
import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import Header from './components/Header';
import HeaderDrawer from './components/HeaderDrawer';
import PortfolioSelector from './containers/PortfolioSelectorConnected';
import HeaderBar from './containers/HeaderBarConnected';

import './App.css';
import LoginPage from './containers/LoginPage';
import PrivateRoute from './appHelperComponents/PrivateRoute';
import MonitorPortfolio from './containers/MonitorPortfolio';
import Analyze from './containers/Analyze';

import CrispApp from './appHelperComponents/CrispApp';
import ManagePayouts from './containers/ManagePayouts';
import BuildPortfolio from './containers/BuildPortfolio';
import ManageUsers from './containers/ManageUsers';
import TermSheetConnected from './containers/TermSheetConnected';
import ApiKey from './containers/ManageApiKey';
import PolicyDetails from './containers/PolicyDetails';
import ScrollToTop from './components/view/ScrollToTop';
import OrgManagement from './containers/OrgManagement';
import PortfolioManagement from './containers/PortfolioManagement';
import { RootState } from './reducers';
import { fetchUser, getToken, isAuthenticated, getAuthRedirect } from './reducers/user';
import UserNotFound from './containers/UserNotFound';
import AuthRedirect from './containers/AuthRedirect';

const NotFound = () => <h1>404 - Page Not Found</h1>;

const App = () => {
  const dispatch = useDispatch();
  const role = useSelector((state: RootState) => state.user.role);
  const isLoadingUser = useSelector((state: RootState) => state.user.isLoadingData);
  const isAuth = useSelector(isAuthenticated);
  const token = useSelector(getToken);
  const authRedirect = useSelector(getAuthRedirect);

  useEffect(() => {
    async function loadUser() {
      dispatch(fetchUser(token));
    }

    if (isAuth === true && role === undefined && isLoadingUser === false) {
      loadUser();
    }
  }, [isAuth, role, isLoadingUser]);
  return (
    <div className="App">
      {process.env.NODE_ENV === 'production' ? (
        <React.Fragment>
          <CrispApp />
        </React.Fragment>
      ) : null}
      <Header
        isAuthenticated={isAuth}
        role={role}
        selectorComponent={PortfolioSelector}
        headerDrawerComponent={HeaderDrawer}
        headerBarComponent={HeaderBar}
        onlyRenderChilden={window.location.pathname.endsWith('/terms')}
      >
        <ScrollToTop />
        <Switch>
          <PrivateRoute path="/monitor/analyze" component={Analyze} />
          <PrivateRoute path="/monitor" component={MonitorPortfolio} />
          <PrivateRoute path="/portfolios/:portfolioId" component={MonitorPortfolio} />
          <PrivateRoute path="/policies/:policyId/terms" component={TermSheetConnected} />
          <PrivateRoute path="/policies/:policyId" component={PolicyDetails} />
          <PrivateRoute path="/build" component={BuildPortfolio} />
          <PrivateRoute path="/payouts" component={ManagePayouts} />
          <PrivateRoute path="/settings/users" component={ManageUsers} />
          <PrivateRoute path="/settings/keys" component={ApiKey} />
          <Redirect from="/settings" to="/settings/users" />
          <PrivateRoute path="/admin/organizations" component={OrgManagement} />
          <PrivateRoute path="/admin/portfolios" component={PortfolioManagement} />
          <Route path="/auth-redirect" component={AuthRedirect} />
          <Route path="/user-not-found" component={UserNotFound} />
          <Redirect from="/admin" to="/admin/organizations" />
          {isAuth ? <Redirect from="/" to={authRedirect ?? '/monitor'} /> : null}
          {isAuth ? <Redirect from="/login" to={authRedirect ?? '/monitor'} /> : null}
          <Route path="/login" component={LoginPage} />
          {isAuth === false ? <Redirect to="/login" /> : null}
          <Route component={NotFound} />
        </Switch>
      </Header>
    </div>
  );
};

export default App;
