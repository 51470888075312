import React from 'react';

import MaterialTable from 'material-table';
import PolicyStatus from '../PolicyStatus';
import { MTableToolbar } from 'material-table';

import grey from '@material-ui/core/colors/grey';

import { Policy } from '../../models';
import { cropByProductIdSelector } from '../../reducers/products';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { toCurrency } from '../../utils';

const NA = () => <span style={{ color: grey[500] }}>N/A</span>;

type PolicySummaryTableProps = {
  portfolioType: string;
  filterComponent?: any;
  onRowClick: (event: any, rowData?: Policy) => void;
  policies: Policy[];
  currency: string;
  title: string;
};

function PolicySummaryTable(props: PolicySummaryTableProps) {
  const {
    portfolioType,
    filterComponent: FilterComponent = null,
    policies,
    currency: currencyCode,
    onRowClick,
    title,
  } = props;

  const locationLabel = portfolioType === 'PI' ? 'Location' : 'Customer';
  const locationKey = portfolioType === 'PI' ? 'trigger_location' : 'customer';

  // Used for Filtering Dropdown
  const policyStatusesMap = {
    pending: 'Pending',
    planted: 'Planted',
    active: 'Active',
    triggered: 'Triggered',
    'payout due': 'Payout Due',
    'payout requested': 'Payout Requested',
    'paid out': 'Paid Out',
    dispute: 'Dispute',
    refunded: 'Refunded',
  };

  const MIN_PAGE_SIZE = 10;
  const pageSize = policies.length > MIN_PAGE_SIZE ? MIN_PAGE_SIZE : policies.length;
  const crops = useSelector((state: RootState) => cropByProductIdSelector(state));

  return (
    <MaterialTable
      columns={[
        { title: 'Policy ID', field: 'id', filtering: false },
        { title: locationLabel, field: `${locationKey}.name`, filtering: false },
        {
          title: 'Crop',
          field: 'product_id',
          lookup: crops,
        },
        {
          title: 'Status',
          field: 'status',
          lookup: policyStatusesMap,
          render: rowData => <PolicyStatus policy={rowData} />,
        },
        {
          title: 'Activation Date',
          field: 'date_start',
          render: rowData => rowData.date_start ?? <NA />,
        },
        {
          title: 'Expiration Date',
          field: 'date_end',
          render: rowData => (!!rowData.date_end ? rowData.date_end : <NA />),
        },
        {
          title: 'Premium',
          field: 'total_premium.amount',
          render: rowData =>
            rowData.total_premium?.amount ? (
              toCurrency(rowData.total_premium.amount, currencyCode)
            ) : (
              <NA />
            ),
        },
        {
          title: 'Payout',
          field: 'payout',
          render: rowData => (rowData.payout ? toCurrency(rowData.payout, currencyCode) : <NA />),
        },
      ]}
      data={policies}
      title={title}
      options={{
        pageSizeOptions: pageSize < MIN_PAGE_SIZE ? [pageSize] : [MIN_PAGE_SIZE, 25, 50, 100, 500],
        pageSize,
        sorting: false,
        search: false,
      }}
      onRowClick={onRowClick}
      components={{
        Toolbar: props => (
          <div>
            <MTableToolbar {...props} />
            <FilterComponent />
          </div>
        ),
      }}
    />
  );
}

export default PolicySummaryTable;
