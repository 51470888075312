import React from 'react';
import clsx from 'clsx';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import StepIcon from '@material-ui/core/StepIcon';
import Typography from '@material-ui/core/Typography';

import FilledStepperIcon from '@material-ui/icons/RadioButtonChecked';
import UnfilledStepperIcon from '@material-ui/icons/RadioButtonUnchecked';

import { toCurrency } from '../../utils';
import { PolicyTrigger as PolicyTriggerModel, CurrencyCode, TriggerType } from '../../models';
import SmallDateRange from './SmallDateRange';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  card: {
    overflow: 'visible',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    margin: 0,
    marginTop: `-${theme.spacing(4)}px`,
    marginBottom: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  statusChip: {
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    padding: `0 ${theme.spacing(3)}px`,
  },
  inactiveLabel: {
    color: `${theme.palette.grey[400]} !important`,
  },
  expiredIcon: {
    color: theme.palette.grey[400],
  },
  pricedIcon: {
    color: theme.palette.secondary.main,
  },
  activeIcon: {
    color: theme.palette.primary.light,
  },
  triggeredIcon: {
    color: theme.palette.primary.main,
  },
  paidoutIcon: {
    color: theme.palette.primary.dark,
  },
  pricedChip: {
    backgroundColor: theme.palette.secondary.main,
  },
  activeChip: {
    backgroundColor: theme.palette.primary.light,
  },
  expiredChip: {},
  triggeredChip: {
    backgroundColor: theme.palette.primary.main,
  },
  paidoutChip: {
    backgroundColor: theme.palette.primary.dark,
  },
}));

/*
active: '#7db447
triggered: '#4c8e06',
payout: '#254b00',
*/

type PolicyTriggerCardProps = {
  trigger: PolicyTriggerModel;
  currencyCode: CurrencyCode;
  sumInsured: number;
  currentDate?: string;
};

type TriggerParamMap = {
  [triggerType in TriggerType]: {
    name: (period: number) => string;
    unit: ' Days' | 'mm' | ' FDD';
    isAscending: boolean;
  };
};
const triggerParameters: TriggerParamMap = {
  cdd: {
    name: period => 'Consecutive Drought Days',
    unit: ' Days',
    isAscending: true,
  },
  crd: {
    name: period => 'Consecutive Rain Days',
    unit: ' Days',
    isAscending: true,
  },
  crf: {
    name: period => `Cumulative Rainfall (Period ${period})`,
    unit: 'mm',
    isAscending: false,
  },
  crw: {
    name: period => '5-Day Cumulative Rainfall',
    unit: 'mm',
    isAscending: true,
  },
  fdd: {
    name: period => 'Frost Degree Days',
    unit: ' FDD',
    isAscending: true,
  },
  grm: {
    name: period => 'Germination Rainfall',
    unit: 'mm',
    isAscending: false,
  },
};

type PeriodMap = { [id: number]: string };
const periodMap: PeriodMap = {
  1: 'Germination',
  2: 'Flowering',
  3: 'Maturation',
  4: 'Blooming',
};

export default function PolicyTriggerCard(props: PolicyTriggerCardProps) {
  const { trigger, sumInsured, currencyCode, currentDate } = props;
  const { trigger_type, triggers, current_value: currentVal, status } = trigger;
  const classes = useStyles();
  const colorClass: any = classes;

  // Find highest index where status == triggered or paid out
  const activeStep = triggers.reduce((currMax, trig, idx) => {
    // We prefix the "start step" so need to add by one
    const stepIdx = idx + 1;
    if (['triggered', 'paid out'].includes(trig.status) && stepIdx > currMax) {
      return stepIdx;
    } else {
      return currMax;
    }
  }, 0);

  const { unit, name: getTitle } = triggerParameters[trigger_type];
  const steps = triggers.map(trig => ({
    label: `${trig.threshold}${unit}`,
    payout: sumInsured * trig.factor,
  }));
  //title = `Cumulative Rainfall (${periodMap[period]})`;

  // Start Step stuff - if I put as separate component it isn't loaded properly

  // Handling for Pending, Actived, Expired
  let startLabel = 'Activated';
  let startIconComponent = (iconProps: any) => (
    <StepIcon
      {...iconProps}
      icon={<FilledStepperIcon className={colorClass[`${status.replace(' ', '')}Icon`]} />}
      completed={true}
    />
  );
  if (status === 'priced') {
    startLabel = 'Not Activated';
    //startIconComponent = (iconProps: any) => <StepIcon {...iconProps} />;
  } else if (status === 'expired') {
    //startLabel = 'Expired';
    //startIconComponent = (iconProps: any) => <StepIcon {...iconProps} error={true} />;
  }

  function CurrentValueConnector(props: any) {
    const valueStyle: any = {
      top: -85,
      left: 'calc(-50% + 20px)',
      right: 'calc(50% + 20px)',
      position: 'absolute',
      textAlign: 'center',
    };
    const lineStyle: any = {
      padding: 8,
      height: 20,
      backgroundImage: 'linear-gradient(#000, #000)',
      backgroundSize: '2px 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    };
    const currentValue = (
      <div style={valueStyle}>
        <Typography variant="body2" component="p" color="textSecondary">
          {['expired', 'paid out'].includes(status) ? 'Final' : 'Current'}
        </Typography>
        <Typography variant="h6" component="p">
          {currentVal} {unit}
        </Typography>
        <div style={lineStyle} />
      </div>
    );
    return (
      <div style={{ minWidth: props.active ? 100 : undefined }}>
        {status !== 'priced' && props.active ? currentValue : null}
        <StepConnector {...props} />
      </div>
    );
  }

  function getPeriod(trigger: PolicyTriggerModel) {
    if (trigger.trigger_type === 'grm') {
      return 'Germination';
    } else if (trigger.trigger_type === 'crf') {
      if (trigger.period === 1) {
        return 'Flowering';
      } else if (trigger.period === 2) {
        return 'Maturation';
      }
    } else if (trigger.trigger_type === 'crw') {
      if (trigger.period === 4) {
        return 'Blooming';
      } else {
        return 'Flowering - Maturation';
      }
    } else if (trigger.trigger_type === 'fdd') {
      return 'Blooming';
    } else {
      return 'Flowering - Maturation';
    }
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Paper className={classes.header}>
          <Typography
            variant="h5"
            component="p"
            color={status === 'expired' ? 'textSecondary' : undefined}
          >
            {getPeriod(trigger)}
          </Typography>
          <Chip
            className={clsx(colorClass[`${status.replace(' ', '')}Chip`], classes.statusChip)}
            label={trigger.status}
          />
        </Paper>
        <SmallDateRange
          currentDate={currentDate}
          startDate={trigger.start_date}
          endDate={trigger.end_date}
          status={status}
        />
        <Stepper
          alternativeLabel
          connector={<CurrentValueConnector />}
          activeStep={activeStep + 1}
          style={{ maxWidth: '100%', padding: 0, paddingTop: 100, overflow: 'hidden' }}
        >
          <Step>
            <StepLabel
              classes={{
                completed: ['priced', 'expired', 'paid out'].includes(status)
                  ? classes.inactiveLabel
                  : undefined,
                active: ['priced', 'expired', 'paid out'].includes(status)
                  ? classes.inactiveLabel
                  : undefined,
              }}
              StepIconComponent={startIconComponent}
            >
              {startLabel}
            </StepLabel>
          </Step>
          {steps.map((step, idx) => (
            <Step key={step.label}>
              <StepLabel
                classes={{
                  label:
                    ['priced', 'expired', 'paid out'].includes(status) || idx > activeStep
                      ? classes.inactiveLabel
                      : undefined,
                  completed: ['priced', 'expired', 'paid out'].includes(status)
                    ? classes.inactiveLabel
                    : undefined,
                  active: classes.inactiveLabel,
                }}
                StepIconComponent={iconProps => (
                  <StepIcon
                    {...iconProps}
                    completed={iconProps.completed}
                    icon={
                      iconProps.completed ? (
                        <FilledStepperIcon
                          className={colorClass[`${status.replace(' ', '')}Icon`]}
                        />
                      ) : (
                        <UnfilledStepperIcon className={classes.expiredIcon} />
                      )
                    }
                  />
                )}
              >
                <Typography variant="body2">{step.label}</Typography>
                <Typography
                  color={idx !== 0 && idx === activeStep - 1 ? 'textPrimary' : undefined}
                  variant={idx !== 0 && idx === activeStep - 1 ? 'h5' : 'body1'}
                >
                  {toCurrency(step.payout, currencyCode)}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </CardContent>
    </Card>
  );
}
