import React from 'react';

import { useSelector } from 'react-redux';

import { Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import payoutsImg from './payouts.png';
import { RootState } from '../reducers';
import {
  selectedPortfolioCurrencySelector,
  selectedPortfolioTotalBalanceSelector,
  selectedPortfolioBalanceSelector,
} from '../reducers/portfolios';

const useStyles = makeStyles((theme: Theme) => ({
  emptyPortfolioImg: {
    maxWidth: 265,
    borderRadius: '50%',
    opacity: '70%',
  },
}));

export const ManagePayouts = () => {
  const { currency, balance, allBalances } = useSelector((state: RootState) => ({
    currency: selectedPortfolioCurrencySelector(state),
    balance: selectedPortfolioTotalBalanceSelector(state),
    allBalances: selectedPortfolioBalanceSelector(state),
  }));
  const { payouts_due: payoutsDue } = allBalances;
  const classes = useStyles();

  const content =
    payoutsDue > 0 && balance > 0 ? (
      <React.Fragment>
        <Typography component="h1" variant="h4">
          You have a payout due.
        </Typography>
        <Typography variant="h6" gutterBottom>
          You have an account balance of: {currency} {balance}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Contact your{' '}
          <a href="mailto:partnersuccess@worldcovr.com">Partner Success Representative</a> to
          transfer this to your bank account, or use it <br /> to renew for next year's portfolio at
          a discount.
        </Typography>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Typography component="h1" variant="h4">
          You have no payout due at this time.
        </Typography>
        <Typography variant="h6" gutterBottom>
          We will notify you when any payout is triggered on your portfolio.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Check back later or contact your{' '}
          <a href="mailto:partnersuccess@worldcovr.com">Partner Success Representative</a> if you
          have any questions.
        </Typography>
      </React.Fragment>
    );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <img className={classes.emptyPortfolioImg} src={payoutsImg} alt="Payouts Charts" />
      </Grid>
      <Grid item xs={12}>
        {content}
      </Grid>
    </Grid>
  );
};

export default ManagePayouts;
