import { PortfolioSummary, SimpleEntity, TagMap, ApiErrorMessage } from '../../models';
import { RootState } from '.';
import { selectedPortfolioIdSelector } from './portfolios';
import { createSelector } from 'reselect';
import { FluxStandardAction } from '../../reducers';
import { getStartDate, getEndDate, getAlmondEndDate, getAlmondStartDate } from './timeUtils';
import { localeMap } from '../env';

import {
  HYDRATE_STATE,
  PLANT_POLICIES,
  ACTIVATE_POLICIES,
  TRIGGER_POLICIES,
  COMPLETE_POLICIES,
} from './policies';

export type PortfolioSummaryState = {
  isLoadingData: boolean;
  error?: ApiErrorMessage;
  byId: {
    [id: number]: PortfolioSummary;
  };
};

const initialCounts = {
  id: 1,
  total_payout: 0,
  policies_planted: 0,
  policies_priced: 4,
  policies_activated: 0,
  policies_triggered: 0,
  policies_completed: 0,
  policies_total: 4,
  tags: [],
};

function getHydratedState(locale: string = 'en-GH'): PortfolioSummaryState {
  const { premium: baseAmount, productId } = localeMap[locale];

  const initialSummary: PortfolioSummary = {
    ...initialCounts,
    total_sum_insured: baseAmount * 5 * 4,
    start_date: productId === 2 ? getAlmondStartDate() : getStartDate(),
    end_date: productId === 2 ? getAlmondEndDate() : getEndDate(),
  };

  return {
    isLoadingData: false,
    error: undefined,
    byId: {
      '1': initialSummary,
    },
  };
}

export const emptyInitialState: PortfolioSummaryState = {
  isLoadingData: false,
  error: undefined,
  byId: {},
};

const getInitialState = () => {
  const locale = window.sessionStorage.getItem('country');
  if (locale == null) {
    return emptyInitialState;
  } else {
    return getHydratedState(locale);
  }
};

export default function reducer(
  state = getInitialState(),
  action: FluxStandardAction,
): PortfolioSummaryState {
  const locale = window.sessionStorage.getItem('country');
  const payoutFactor = locale === 'en-US' ? 0.112 : 0.3;
  const { 1: currentSummary } = state.byId;
  switch (action.type) {
    case HYDRATE_STATE:
      const { data: country } = action.payload;
      return getHydratedState(country);
    case PLANT_POLICIES: {
      return {
        ...state,
        byId: {
          '1': {
            ...currentSummary,
            ...initialCounts,
            policies_planted: 4,
            policies_activated: 4,
            total_payout: 0,
          },
        },
      };
    }
    case ACTIVATE_POLICIES: {
      return {
        ...state,
        byId: {
          '1': {
            ...currentSummary,
            ...initialCounts,
            policies_planted: 4,
            policies_activated: 4,
            total_payout: 0,
          },
        },
      };
    }
    case TRIGGER_POLICIES: {
      return {
        ...state,
        byId: {
          '1': {
            ...currentSummary,
            ...initialCounts,
            policies_planted: 4,
            policies_activated: 4,
            policies_triggered: 2,
            total_payout: currentSummary.total_sum_insured * payoutFactor,
          },
        },
      };
    }
    case COMPLETE_POLICIES: {
      return {
        ...state,
        byId: {
          '1': {
            ...currentSummary,
            ...initialCounts,
            policies_planted: 4,
            policies_activated: 4,
            policies_triggered: 2,
            policies_completed: 4,
            total_payout: currentSummary.total_sum_insured * payoutFactor,
          },
        },
      };
    }
    default:
      return state;
  }
}

export const portfolioSummaryByIdSelector = (state: RootState) => state.portfolioSummaries.byId;
export const getPortfolioSummaryById = (state: RootState, portfolioId?: number) =>
  portfolioId == null ? undefined : state.portfolioSummaries.byId[portfolioId];

export const isLoadingPortfolioSummary = (state: RootState) =>
  state.portfolioSummaries.isLoadingData;

export const selectedPortfolioSummarySelector = createSelector(
  portfolioSummaryByIdSelector,
  selectedPortfolioIdSelector,
  (byId, selectedId) => byId[selectedId!],
);

export const portfolioSummaryErrorSelector = (state: RootState) => state.portfolioSummaries.error;

export const selectedPortfolioTagsSelector = createSelector(
  selectedPortfolioSummarySelector,
  selected => {
    let filteredTagMap = {} as TagMap;
    const tagsArr = selected?.tags || ([] as SimpleEntity[]);
    tagsArr.forEach(tagObj => {
      filteredTagMap[tagObj.id] = tagObj.name;
    });
    return filteredTagMap;
  },
);
