import React from 'react';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import 'react-circular-progressbar/dist/styles.css';

import CircularSummary from './CircularSummary';
import { PortfolioSummary } from '../../models';

const useStyles = makeStyles((theme: any) => ({
  circularSummaryContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: theme.spacing(2),
    height: '100%',
  },
}));

type CircularSummariesProps = {
  summary: PortfolioSummary;
};

/**
 * Displays top-level portfolio KPIs using the summary counts of
 * all policies in the portfolio.
 */
const CircularSummaries = ({ summary }: CircularSummariesProps) => {
  const classes = useStyles();

  return (
    <Paper className={classes.circularSummaryContainer}>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={12} md={3}>
          <CircularSummary
            label="Priced"
            main={summary.policies_priced}
            total={summary.policies_total}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CircularSummary
            label="Activated"
            main={summary.policies_activated}
            total={summary.policies_total}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CircularSummary
            label="Triggered"
            main={summary.policies_triggered}
            total={summary.policies_activated}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CircularSummary
            label="Completed"
            main={summary.policies_completed}
            total={summary.policies_activated}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CircularSummaries;
