import { API_V2_ROOT } from './env';
import renderApp from './index.app';
import renderDemo from './index.demo';

const isDemo = process.env['REACT_APP_IS_DEMO'];
const rootUrl = API_V2_ROOT;

if (isDemo === 'true') {
  renderDemo();
} else {
  renderApp();
}
