import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { useSelector, useDispatch } from 'react-redux';

import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';

import { defaultStatusSummary } from '../env';
import { Policy, StatusSummary, ObjectLiteral } from '../models';
import { RootState } from '../reducers';
import { fetchPortfolioPolicies, getSelectedPortfolioPolicies } from '../reducers/policies';
import {
  selectedPortfolioIdSelector,
  selectedPortfolioCountrySelector,
  selectedPortfolioCurrencySelector,
} from '../reducers/portfolios';
import { selectedPortfolioTagsSelector } from '../reducers/portfolio-summaries';
import { fetchDistricts, selectedPortfolioDistricts } from '../reducers/districts';

import Loader from '../components/Loader';
import BreakdownTable from '../components/view/BreakdownTable';
import { cropByProductIdSelector } from '../reducers/products';
import { getToken } from '../reducers/user';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  loader: {
    minHeight: '100vh',
    overflow: 'auto',
  },
});

function AnalyzePortfolio() {
  const token = useSelector(getToken);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [statusSummary, setStatusSummary] = useState(defaultStatusSummary);

  const portfolioPolicies = useSelector((state: RootState) => getSelectedPortfolioPolicies(state));
  const selectedPortfolioId = useSelector((state: RootState) => selectedPortfolioIdSelector(state));
  const selectedPortfolioCountryId = useSelector((state: RootState) =>
    selectedPortfolioCountrySelector(state),
  );
  const currency = useSelector((state: RootState) => selectedPortfolioCurrencySelector(state));
  const districts = useSelector((state: RootState) => selectedPortfolioDistricts(state));
  const tags = useSelector((state: RootState) => selectedPortfolioTagsSelector(state));
  const cropsMap = useSelector((state: RootState) => cropByProductIdSelector(state));

  useEffect(() => {
    function parseMetricSummaries(policies: Policy[]): StatusSummary {
      const summarizePolicyStatuses = (policies: Policy[]) => ({
        ...mapValues(groupBy(policies, 'status'), 'length'),
        total: policies.length,
      });

      return {
        ...defaultStatusSummary,
        ...summarizePolicyStatuses(policies),
      };
    }

    if (!selectedPortfolioId) {
      return;
    }

    async function fetchPolicies(selectedPortfolioId: number) {
      dispatch(fetchPortfolioPolicies(selectedPortfolioId, token));
    }

    if (!token) {
      return;
    } else if (portfolioPolicies === undefined) {
      fetchPolicies(selectedPortfolioId);
    } else {
      setStatusSummary(parseMetricSummaries(portfolioPolicies));
    }
  }, [dispatch, token, selectedPortfolioId, portfolioPolicies]);

  useEffect(() => {
    if (!selectedPortfolioCountryId) {
      return;
    }

    async function fetchPortfolioDistricts(selectedPortfolioCountryId: number) {
      dispatch(fetchDistricts(selectedPortfolioCountryId, token));
    }

    if (!token) {
      return;
    } else if (districts === undefined) {
      fetchPortfolioDistricts(selectedPortfolioCountryId);
    }
  }, [dispatch, token, selectedPortfolioCountryId, districts]);

  if (portfolioPolicies === undefined || districts === undefined) {
    return (
      <div className={classes.loader}>
        <Loader />;
      </div>
    );
  } else if (portfolioPolicies !== undefined && portfolioPolicies.length > 0) {
    const districtGrouping = {
      groupedPolicies: groupBy(portfolioPolicies, 'trigger_location.district_id'),
      lookup: mapValues(districts!, dist => dist.name),
      title: 'By District',
      columnName: 'District',
    };

    const groupedTagPortfolios = {} as ObjectLiteral;
    Object.keys(tags).forEach(tagId => (groupedTagPortfolios[tagId] = []));
    portfolioPolicies.forEach(pol => {
      const tags = pol.tag_ids;
      if (tags.length > 0) {
        tags.forEach(tagId => {
          groupedTagPortfolios[tagId].push(pol);
        });
      }
    });

    const tagGrouping = {
      title: 'By Tag',
      columnName: 'Tag',
      lookup: tags,
      groupedPolicies: groupedTagPortfolios,
    };
    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h1" variant="h3">
              Analyze Portfolio (Beta)
            </Typography>
            <Typography component="h2" variant="subtitle1">
              More Features Coming Soon!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <BreakdownTable {...districtGrouping} cropsMap={cropsMap} currencyCode={currency} />
            {/* <StatusPieChart statusSummary={statusSummary} /> */}
          </Grid>
          <Grid item xs={12}>
            <BreakdownTable {...tagGrouping} cropsMap={cropsMap} currencyCode={currency} />
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h4">
            Start Building Your Portfolio
          </Typography>
          <Typography variant="h6" gutterBottom>
            Before we can create any policies, we need to get some data in here!
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Head to <Link to="/build">Build Portfolio</Link> to start loading in your portfolio
            data.
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default AnalyzePortfolio;
