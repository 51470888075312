import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import isEmpty from 'lodash/isEmpty';

import { RootState } from '../reducers';
import { policyTriggersSelector, fetchPolicyTriggers } from '../reducers/triggers';
import { policySelector, fetchPolicy } from '../reducers/policies';
import { districtByIdSelector, fetchDistrict } from '../reducers/districts';

import TermSheet from '../components/view/TermSheet';
import Loader from '../components/Loader';
import { cropByProductIdSelector } from '../reducers/products';
import { portfolioByIdSelector } from '../reducers/portfolios';
import { District, Portfolio } from '../models';
import { getToken } from '../reducers/user';

function TermSheetConnected(props: any) {
  const { policyId } = props.match.params;
  const { policy, policyTriggers, district, crop, portfolio } = props;

  const token = useSelector(getToken);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getPolicy() {
      dispatch(fetchPolicy(policyId, token));
    }

    async function getTriggers() {
      dispatch(fetchPolicyTriggers(policyId, token));
    }

    getPolicy();
    getTriggers();
  }, []);

  useEffect(() => {
    async function getDistrict() {
      dispatch(fetchDistrict(policy?.trigger_location!.district_id, token));
    }

    if (policy?.trigger_location?.district_id && !district) {
      getDistrict();
    }
  }, [policy?.trigger_location]);

  if (policy && !isEmpty(policy.trigger_location) && policyTriggers && district) {
    return (
      <TermSheet
        policy={policy}
        triggers={policyTriggers}
        portfolio={portfolio}
        district={district}
        crop={crop}
      />
    );
  } else {
    return <Loader />;
  }
}

const mapState = (state: RootState, props: any) => {
  const { policyId } = props.match.params;
  const selectorProps = {
    policy: { id: policyId },
  };
  const policy = policySelector(state, selectorProps);
  const districts = districtByIdSelector(state) || ({} as District[]);
  const portfolios = portfolioByIdSelector(state) || ({} as Portfolio[]);
  const crops = cropByProductIdSelector(state);
  const districtId = policy?.trigger_location?.district_id;
  return {
    policy,
    policyTriggers: policyTriggersSelector(state, selectorProps),
    crop: crops[policy?.product_id!],
    district: districtId ? districts[districtId] : ({} as District),
    portfolio: portfolios[policy?.portfolio_id!],
  };
};

const connector = connect(mapState);

export default connector(TermSheetConnected);
