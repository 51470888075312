import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

import classnames from 'classnames';
import sortBy from 'lodash/sortBy';

import { Theme, InputBaseComponentProps } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Omit } from '@material-ui/types';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ListSubheader from '@material-ui/core/ListSubheader';
import grey from '@material-ui/core/colors/grey';
import { Portfolio } from '../models';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControlDark: {
    '& > *': {
      color: grey[50],
    },
  },
  iconDark: {
    fill: grey[50],
  },
  selectedDarkVariant: {
    color: grey[50],
  },
  title: {
    display: 'inline-flex',
  },
}));

type PortfolioSelectorProps = {
  variant?: 'light' | 'dark';
  selectedPortfolioId?: number;
  setPortfolioId: Function;
  portfolios: Portfolio[];
};

function PortfolioLink(props: any) {
  const { portfolio: port } = props;
  const to = `/portfolios/${port.id}`;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to],
  );

  return (
    <MenuItem value={port.id} component={renderLink as any}>
      {port.portfolio_code}
    </MenuItem>
  );
}

function createPortfolioList(portfolios: Portfolio[]) {
  let currentOrg: string;
  const portfoliosList = portfolios.map(port => {
    if (port.organization.name !== currentOrg) {
      currentOrg = port.organization.name;
      return [<ListSubheader>{currentOrg}</ListSubheader>, <PortfolioLink portfolio={port} />];
    } else {
      return <PortfolioLink key={port.id} portfolio={port} />;
    }
  });
  return portfoliosList;
}

export function PortfolioSelector(props: PortfolioSelectorProps) {
  const { setPortfolioId, selectedPortfolioId, portfolios, variant = 'light' } = props;
  const classes = useStyles();

  if (Object.keys(portfolios).length == 0) {
    return null;
  } else if (Object.keys(portfolios).length < 2) {
    const organizationName = portfolios[0].organization.name;
    return (
      <Typography variant="h6" noWrap className={classes.title}>
        {organizationName}
      </Typography>
    );
  } else {
    let inputProps: InputBaseComponentProps | undefined;
    let formClassName = classes.formControl;
    if (variant === 'dark') {
      inputProps = {
        classes: {
          icon: classes.iconDark,
        },
      };
      formClassName = classnames(classes.formControl, classes.formControlDark);
    }
    const sortedPortfolios = sortBy(
      Object.values(portfolios),
      port => `${port.organization.name} - ${port.portfolio_code}`,
    );

    return (
      <FormControl className={formClassName}>
        <Select
          labelId="portfolio-select-label"
          id="portfolio-select"
          disableUnderline
          value={selectedPortfolioId}
          inputProps={inputProps}
          renderValue={(selectedListId: any) => {
            const selectedPortfolio = sortedPortfolios.filter(port => port.id === selectedListId);
            const textClass = variant === 'dark' ? classes.selectedDarkVariant : undefined;
            return selectedPortfolio.length === 1 ? (
              <div className={textClass}>
                {selectedPortfolio[0].organization.name} - {selectedPortfolio[0].portfolio_code}
              </div>
            ) : (
              <div className={textClass}>Select Portfolio</div>
            );
          }}
        >
          {createPortfolioList(sortedPortfolios)}
        </Select>
      </FormControl>
    );
  }
}

export default PortfolioSelector;
