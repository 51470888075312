import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers';

export default function configureStore(initialState?: any) {
  const middlewares: any[] = [];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  // Set Redux Enhancers
  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(rootReducer, initialState, composedEnhancers);

  return store;
}
