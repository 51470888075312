import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MaterialTable from 'material-table';

import { API_V2_ROOT } from '../env';
import { useSelector } from 'react-redux';
import { getToken } from '../reducers/user';

const ORGANIZATIONS_ENDPOINT = `${API_V2_ROOT}/organizations`;
const USERS_ENDPOINT = `${API_V2_ROOT}/users`;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },
  formRoot: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

type FormData = {
  orgName?: string;
  adminEmail?: string;
  adminName?: string;
};

export default function OrganizationApp() {
  const classes = useStyles();
  const token = useSelector(getToken);
  const [openAlert, setOpenAlert] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [formValues, setFormValues] = useState({} as FormData);
  const [shouldRefreshTable, setShouldRefreshTable] = useState<number>(0);

  async function createNewOrg() {
    const headers = { Authorization: `Bearer ${token}` };
    // Create Org
    const orgPayload = {
      name: formValues.orgName,
    };
    const orgResult = await axios.post(ORGANIZATIONS_ENDPOINT, orgPayload, { headers });
    console.log('ORG RESULT!', orgResult);
    // Create User
    const userPayload = {
      role: 'org_admin',
      name: formValues.adminName,
      email: formValues.adminEmail,
      organization_id: orgResult.data.id,
    };
    console.log(userPayload);
    const userResult = await axios.post(USERS_ENDPOINT, userPayload, { headers });
    console.log('result', orgPayload, orgResult, userPayload, userResult);
    setOpenAlert(true);
    setShouldRefreshTable(ref => ref + 1);
  }

  const handleChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues(form => ({
      ...form,
      [field]: event.target.value,
    }));
  };

  useEffect(() => {
    async function fetchOrgs() {
      setOrgs([]);

      const result = await axios.get(ORGANIZATIONS_ENDPOINT, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOrgs(result.data);
    }

    fetchOrgs();
  }, [shouldRefreshTable]);

  return (
    <div>
      <Paper className={classes.paper}>
        <Typography variant="h5" gutterBottom>
          New Organization
        </Typography>
        <form className={classes.formRoot} noValidate autoComplete="off">
          <TextField
            label="Org Name"
            onChange={handleChange('orgName')}
            value={formValues.orgName || ''}
          />
          <TextField
            type="email"
            label="Admin User's Email"
            onChange={handleChange('adminEmail')}
            value={formValues.adminEmail || ''}
          />
          <TextField
            label="Admin User's Name"
            onChange={handleChange('adminName')}
            value={formValues.adminName || ''}
          />
          <Button
            disabled={!formValues.adminName || !formValues.orgName || !formValues.adminEmail}
            variant="contained"
            color="secondary"
            onClick={createNewOrg}
          >
            Create
          </Button>
        </form>
      </Paper>
      {orgs.length ? (
        <MaterialTable
          columns={[
            { title: 'ID', field: 'id', editable: 'never' },
            { title: 'Organization', field: 'name' },
            { title: 'Active', field: 'is_active', type: 'boolean', editable: 'onUpdate' },
            {
              title: 'Created At',
              field: 'created_at',
              type: 'date',
              editable: 'never',
            },
          ]}
          data={orgs}
          title="Enterprise Organizations"
          options={{
            filtering: false,
            pageSizeOptions: [10, 25, 50, 100, 500],
            pageSize: orgs.length,
          }}
        />
      ) : null}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openAlert}
        autoHideDuration={12000}
        onClose={() => setOpenAlert(false)}
        message="Organization Successfully Created"
      />
    </div>
  );
}
