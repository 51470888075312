import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from './muiTheme';

import { Provider as ReduxProvider } from 'react-redux';

import './index.css';

import App from './demo/DemoApp';
import { unregister as disableServiceWorker } from './serviceWorker.js';
import configureStore from './demo/configureStore';

export default function renderDemo() {
  const store = configureStore();

  ReactDOM.render(
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ReduxProvider store={store}>
          <App />
        </ReduxProvider>
      </ThemeProvider>
    </BrowserRouter>,
    document.getElementById('root'),
  );

  // Turn off service worker for now
  disableServiceWorker();
}
