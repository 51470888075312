import { combineReducers } from 'redux';

import user from './user';
import organization from './organization';
import portfolios from './portfolios';
import policies from './policies';
import products from './products';
import portfolioSummaries from './portfolio-summaries';
import triggers from './triggers';
import time from './time';

const rootReducer = combineReducers({
  organization,
  products,
  portfolios,
  portfolioSummaries,
  policies,
  triggers,
  time,
  user,
});

export type FluxStandardAction = {
  type: string;
  payload?: any;
  error?: boolean;
  meta?: any;
};

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
