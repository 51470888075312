import React from 'react';
import moment from 'moment';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  progressBar: {
    height: 10,
    borderRadius: 10,
  },
  dateDescription: {
    minWidth: 75,
    textAlign: 'center',
  },
  calIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 35,
  },
  monitoringProgress: {
    width: '100%',
    padding: `0 ${theme.spacing(2)}px`,
  },
  bar: {
    backgroundColor: theme.palette.grey[400],
  },
  expiredBackBar: {
    backgroundColor: theme.palette.grey[200],
    transform: 'rotate(180deg)',
  },
  expiredBar: {
    backgroundColor: theme.palette.primary.light,
  },
  pricedBar: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 20,
  },
  activeBar: {
    backgroundColor: theme.palette.primary.light,
  },
  triggeredBar: {
    backgroundColor: theme.palette.primary.main,
  },
  paidoutBar: {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export type SmallDateRangeProps = {
  startDate: string;
  endDate: string;
  currentDate?: string;
  status?: string;
};

const SmallDateRange = (props: SmallDateRangeProps) => {
  const { status, startDate, endDate, currentDate = moment().format('YYYY-MM-DD') } = props;
  const classes = useStyles();
  const colorClass: any = classes;

  function calculateDateRange(start?: string, end?: string, current?: string) {
    const startDate = moment(start, 'YYYY-MM-DD');
    const endDate = moment(end, 'YYYY-MM-DD');
    const currentDate = moment(current, 'YYYY-MM-DD');
    if (currentDate.isBefore(startDate)) {
      return 0;
    } else if (currentDate.isAfter(endDate)) {
      return 100;
    } else {
      const totalRange = endDate.diff(startDate, 'days');
      const currentRange = currentDate.diff(startDate, 'days');
      return Math.floor((currentRange / totalRange) * 100);
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.dateDescription}>
        <Typography variant="body1" component="p" color="textSecondary">
          {moment(startDate, 'YYYY-MM-DD').format('MMM Do')}
        </Typography>
      </div>
      <div className={classes.monitoringProgress}>
        <LinearProgress
          classes={{
            colorPrimary: status === 'expired' ? classes.expiredBackBar : classes.bar,
            bar: status ? colorClass[`${status.replace(' ', '')}Bar`] : undefined,
          }}
          className={classes.progressBar}
          variant="determinate"
          value={
            ['priced', 'expired'].includes(status!)
              ? 2
              : calculateDateRange(startDate, endDate, currentDate)
          }
        />
      </div>
      <div className={classes.dateDescription}>
        <Typography variant="body1" component="p" color="textSecondary">
          {moment(endDate, 'YYYY-MM-DD').format('MMM Do')}
        </Typography>
      </div>
    </div>
  );
};

export default SmallDateRange;
