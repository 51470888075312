import { RSAA } from 'redux-api-middleware'; // RSAA = '@@redux-api-middleware/RSAA'

import { API_V2_ROOT } from '../env';
import { PortfolioSummary, SimpleEntity, TagMap, ApiErrorMessage } from '../models';
import { RootState } from '.';
import { selectedPortfolioIdSelector } from './portfolios';
import { createSelector } from 'reselect';
import { LOGOUT } from './user';

export const PORTFOLIO_SUMMARY_REQUEST = '@@portfolio/PORTFOLIO_SUMMARY_REQUEST';
export const PORTFOLIO_SUMMARY_SUCCESS = '@@portfolio/PORTFOLIO_SUMMARY_SUCCESS';
export const PORTFOLIO_SUMMARY_FAILURE = '@@portfolio/PORTFOLIO_SUMMARY_FAILURE';

export const fetchPortfolioSummary: any = (token: string, portfolioId: number) => ({
  [RSAA]: {
    endpoint: `${API_V2_ROOT}/portfolios/${portfolioId}/summary`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
    types: [PORTFOLIO_SUMMARY_REQUEST, PORTFOLIO_SUMMARY_SUCCESS, PORTFOLIO_SUMMARY_FAILURE],
  },
});

export type PortfolioSummaryState = {
  isLoadingData: boolean;
  error?: ApiErrorMessage;
  byId: {
    [id: number]: PortfolioSummary;
  };
};

export const portfolioInitialState: PortfolioSummaryState = {
  isLoadingData: false,
  error: undefined,
  byId: {},
};

type PortfolioAction = {
  type: string;
  payload: any;
};

export default function reducer(
  state = portfolioInitialState,
  action: PortfolioAction,
): PortfolioSummaryState {
  switch (action.type) {
    case PORTFOLIO_SUMMARY_REQUEST:
      return {
        ...state,
        error: undefined,
        isLoadingData: true,
      };
    case PORTFOLIO_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoadingData: false,
        byId: { ...state.byId, [action.payload.id]: action.payload },
      };
    case PORTFOLIO_SUMMARY_FAILURE:
      return {
        ...state,
        error: action.payload?.response as ApiErrorMessage,
        isLoadingData: false,
      };
    case LOGOUT:
      return {
        ...portfolioInitialState,
      };
    default:
      return state;
  }
}

export const portfolioSummaryByIdSelector = (state: RootState) => state.portfolioSummaries.byId;
export const getPortfolioSummaryById = (state: RootState, portfolioId?: number) =>
  portfolioId == null ? undefined : state.portfolioSummaries.byId[portfolioId];

export const isLoadingPortfolioSummary = (state: RootState) =>
  state.portfolioSummaries.isLoadingData;

export const selectedPortfolioSummarySelector = createSelector(
  portfolioSummaryByIdSelector,
  selectedPortfolioIdSelector,
  (byId, selectedId) => byId[selectedId!],
);

export const portfolioSummaryErrorSelector = (state: RootState) => state.portfolioSummaries.error;

export const selectedPortfolioTagsSelector = createSelector(
  selectedPortfolioSummarySelector,
  selected => {
    let filteredTagMap = {} as TagMap;
    const tagsArr = selected?.tags || ([] as SimpleEntity[]);
    tagsArr.forEach(tagObj => {
      filteredTagMap[tagObj.id] = tagObj.name;
    });
    return filteredTagMap;
  },
);
