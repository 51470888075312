import React from 'react';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

import BulkPolicyFileUpload from './BulkPolicyFileUpload';
import { RawPortfolioData } from '../../models';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: `${theme.spacing(2)} 0`,
  },
  uploadPanel: {
    margin: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
}));

type UploadPanelProps = {
  onUpload?: (data: RawPortfolioData[]) => any;
  portfolioId?: number;
  token?: string;
  defaultSheetUrl?: string;
  defaultSheetTab?: string;
  onUploadSuccess?: () => void;
};

const UploadPanel = (props: UploadPanelProps) => {
  const { token, portfolioId, onUploadSuccess } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <div className={classes.uploadPanel}>
        <div>
          <Typography variant="h6">Upload Bulk Policies File</Typography>
          <BulkPolicyFileUpload
            portfolioId={portfolioId}
            token={token}
            onUploadSuccess={onUploadSuccess}
          />
        </div>
      </div>
    </Paper>
  );
};

export default UploadPanel;
