import { RSAA } from 'redux-api-middleware'; // RSAA = '@@redux-api-middleware/RSAA'

import { API_V2_ROOT } from '../env';
import { createSelector } from 'reselect';
import { RootState, FluxStandardAction } from '../reducers';
import { User } from '../models';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import { selectedPortfolioOrganizationSelector } from './portfolios';
import { LOGOUT } from './user';

export const LOAD_USERS_REQUEST = '@@users/LOAD_USERS_REQUEST';
export const LOAD_USERS_SUCCESS = '@@users/LOAD_USERS_SUCCESS';
export const LOAD_USERS_FAILURE = '@@users/LOAD_USERS_FAILURE';

export const fetchOrganizationUsers: any = (orgId: number, token: string) => ({
  [RSAA]: {
    endpoint: `${API_V2_ROOT}/users?organization_id=${orgId}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
    types: [
      LOAD_USERS_REQUEST,
      {
        type: LOAD_USERS_SUCCESS,
        meta: { organizationId: orgId },
      },
      LOAD_USERS_FAILURE,
    ],
  },
});

export type UsersState = {
  isLoadingData: boolean;
  byId?: {
    [userId: number]: User;
  };
  allIds?: number[];
  byOrganization?: {
    [organizationId: number]: number[];
  };
};

const initialState: UsersState = {
  isLoadingData: false,
  byId: undefined,
  allIds: undefined,
};

export default function reducer(
  state: UsersState = initialState,
  action: FluxStandardAction,
): UsersState {
  switch (action.type) {
    case LOAD_USERS_REQUEST:
      return {
        ...state,
        isLoadingData: true,
      };
    case LOAD_USERS_SUCCESS:
      const usersById = keyBy(action.payload, 'id');
      const userIds = Object.keys(usersById).map(key => parseInt(key, 10));
      const { organizationId } = action.meta;
      return {
        ...state,
        isLoadingData: false,
        byId: {
          ...state.byId,
          ...usersById,
        },
        byOrganization: {
          ...state.byOrganization,
          [organizationId]: [...userIds],
        },
        allIds: state.allIds ? uniq([...state.allIds, ...userIds]) : [...userIds],
      };
    case LOAD_USERS_FAILURE:
      // TODO implement
      return state;
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export const userByIdSelector = (state: RootState) => state.users?.byId || {};
export const allIdsSelector = (state: RootState) => state.users?.allIds;
export const userByOrganizationSelector = (state: RootState) => state.users?.byOrganization || {};

export const allUsersSelector = createSelector(
  userByIdSelector,
  allIdsSelector,
  (userById, allIds) => {
    if (!allIds) {
      return undefined;
    }
    return allIds!.map(x => userById[x]);
  },
);

export const selectedPortfolioOrganizationUsers = createSelector(
  userByIdSelector,
  userByOrganizationSelector,
  selectedPortfolioOrganizationSelector,
  (userById, userByOrganization, selectedOrganization) => {
    const { id: organizationId } = selectedOrganization;
    const portfolioUserIds = userByOrganization[organizationId];
    if (portfolioUserIds == null) {
      return undefined;
    }
    return portfolioUserIds.map(userId => userById[userId]);
  },
);
