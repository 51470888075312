import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';

import axios from 'axios';
import { API_V2_ROOT } from '../env';
import ApiKeyPanel from '../components/create/ApiKeyPanel';
import { useSelector } from 'react-redux';
import { getToken } from '../reducers/user';

const GET_USER_ENDPOINT = `${API_V2_ROOT}/users/me`;

type UserResponse = {
  id: number;
  name: string;
  role: string;
  username: string;
  organization_id: number;
  has_api_key: boolean;
  portfolios?: number[];
};

function ApiKey() {
  const token = useSelector(getToken);
  const [hasKey, setHasKey] = useState(false);
  const [userInfo, setUserInfo] = useState<UserResponse>();
  const [apiKey, setApiKey] = useState<string>();

  async function generateApiKey() {
    const CREATE_APIKEY_ENDPOINT = `${API_V2_ROOT}/users/${userInfo!.id}/apikey`;

    const result = await axios.put(CREATE_APIKEY_ENDPOINT, null, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    setHasKey(true);
    setApiKey(result.data.api_key);
  }

  useEffect(() => {
    async function fetchUser() {
      const result = await axios.get(GET_USER_ENDPOINT, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserInfo(result.data);
      if (result.data.has_api_key === true) {
        setHasKey(true);
      }
    }

    fetchUser();
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1>Manage API Keys</h1>
          <ApiKeyPanel hasApiKey={hasKey} apiKey={apiKey} onGenerateKey={generateApiKey} />
        </Grid>
      </Grid>
    </div>
  );
}

export default ApiKey;
