import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import { makeStyles, Theme } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';

import SettingsIcon from '@material-ui/icons/Settings';
import UsersIcon from '@material-ui/icons/People';
import KeyIcon from '@material-ui/icons/VpnKey';
import AdminIcon from '@material-ui/icons/AssignmentInd';
import PortfolioIcon from '@material-ui/icons/Web';

import Collapse from '@material-ui/core/Collapse';

import { DRAWER_WIDTH } from '../utils';
//import logo from './worldcover_logo_dark.svg';
import logo from './worldcover_logo_v2.svg';
import { ReactComponent as LocationIcon } from './icons/location.svg';
import { ReactComponent as SatelliteIcon } from './icons/satellite.svg';
import { ReactComponent as PayoutIcon } from './icons/payout.svg';
import { ReactComponent as AnalyzeIcon } from './icons/analyze.svg';
import { UserRole } from '../models';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#121427',
    height: '100%',
    color: 'white',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    paddingTop: theme.spacing(1),
    paddingRight: 15,
    paddingLeft: 15,
  },
  selectedItem: {
    backgroundColor: 'rgba(73, 76, 97, 0.65) !important',
  },
  svgIcon: {
    color: 'rgba(216, 216, 216)',
    //fill: 'white',
    width: 30,
    height: 30,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedDiv: {
    marginLeft: theme.spacing(4),
  },
}));

type DrawerItemProps = {
  urlPath: string;
  itemText: string;
  icon: React.ReactType<{}>;
  selectedItemClass: any;
  selected: boolean;
  onClick?: () => void;
  disabled?: boolean;
};

export const DrawerItem = (props: DrawerItemProps) => {
  const {
    urlPath,
    itemText,
    icon: IconComponent,
    onClick,
    selected,
    disabled = true,
    selectedItemClass,
  } = props;
  const svgStyle = {
    color: '#d6d6d6',
    fill: 'currentColor',
    width: 30,
    height: 30,
  };
  return (
    <>
      <Divider />
      <List>
        <ListItem
          button
          classes={{
            selected: selectedItemClass,
          }}
          onClick={onClick}
          component={Link}
          to={urlPath}
          selected={selected}
          disabled={disabled}
        >
          <ListItemIcon>
            <IconComponent style={svgStyle} />
          </ListItemIcon>
          <ListItemText primary={itemText} />
        </ListItem>
      </List>
    </>
  );
};

type HeaderDrawerProps = {
  selectorComponent: JSX.Element | any;
  role: UserRole;
};

export default function HeaderDrawer(props: HeaderDrawerProps) {
  const { selectorComponent: PortfolioSelector, role } = props;
  const classes = useStyles();
  const location = useLocation();

  const drawerItems = [
    // {
    //   urlPath: "/monitor",
    //   itemText: "Monitor Portfolio",
    //   icon: PortfolioIcon,
    //   isDisabled: false,
    // },
    {
      urlPath: '/build',
      itemText: 'Setup',
      icon: LocationIcon,
      isDisabled: false,
    },
    {
      urlPath: '/payouts',
      itemText: 'Payouts',
      icon: PayoutIcon,
      isDisabled: false,
    },
    // {
    //   urlPath: '/users',
    //   itemText: 'Settings',
    //   icon: UsersIcon,
    //   isDisabled: false,
    // },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <img width={DRAWER_WIDTH - 30} src={logo} alt="WorldCover" />
      </div>
      <Hidden mdUp>
        <PortfolioSelector variant="dark" />
      </Hidden>

      <Divider />
      <ListItem
        button
        component={Link}
        to={'/monitor'}
        classes={{
          selected: classes.selectedItem,
        }}
        selected={location.pathname === '/monitor'}
      >
        <ListItemIcon>
          <SatelliteIcon className={classes.svgIcon} />
        </ListItemIcon>
        <ListItemText primary="Monitor" />
      </ListItem>
      <Collapse in={location.pathname.startsWith('/monitor')} timeout="auto" unmountOnExit>
        <Divider className={classes.nestedDiv} />
        <List component="div" disablePadding>
          <ListItem
            className={classes.nested}
            button
            disabled={false}
            classes={{
              selected: classes.selectedItem,
            }}
            component={Link}
            to={'/monitor/analyze'}
            selected={location.pathname === '/monitor/analyze'}
          >
            <ListItemIcon>
              <AnalyzeIcon className={classes.svgIcon} />
            </ListItemIcon>
            <ListItemText primary="Analyze" />
          </ListItem>
        </List>
      </Collapse>

      {drawerItems.map(item => (
        <DrawerItem
          key={item.urlPath}
          urlPath={item.urlPath}
          itemText={item.itemText}
          icon={item.icon}
          selectedItemClass={classes.selectedItem}
          disabled={item.isDisabled}
          selected={location.pathname === item.urlPath}
        />
      ))}

      <ListItem
        button
        component={Link}
        to={'/settings'}
        classes={{
          selected: classes.selectedItem,
        }}
        selected={location.pathname === '/settings'}
      >
        <ListItemIcon>
          <SettingsIcon className={classes.svgIcon} />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItem>
      <Collapse in={location.pathname.startsWith('/settings')} timeout="auto" unmountOnExit>
        <Divider className={classes.nestedDiv} />
        <List component="div" disablePadding>
          <ListItem
            className={classes.nested}
            button
            disabled={false}
            classes={{
              selected: classes.selectedItem,
            }}
            component={Link}
            to={'/settings/users'}
            selected={location.pathname === '/settings/users'}
          >
            <ListItemIcon>
              <UsersIcon className={classes.svgIcon} />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
          <ListItem
            className={classes.nested}
            button
            disabled={false}
            classes={{
              selected: classes.selectedItem,
            }}
            component={Link}
            to={'/settings/keys'}
            selected={location.pathname === '/settings/keys'}
          >
            <ListItemIcon>
              <KeyIcon className={classes.svgIcon} />
            </ListItemIcon>
            <ListItemText primary="API Key" />
          </ListItem>
        </List>
      </Collapse>
      <Divider />

      {role === 'wc_admin' ? (
        <>
          <ListItem
            button
            component={Link}
            to={'/admin'}
            classes={{
              selected: classes.selectedItem,
            }}
            selected={location.pathname === '/admin'}
          >
            <ListItemIcon>
              <AdminIcon className={classes.svgIcon} />
            </ListItemIcon>
            <ListItemText primary="Admin" />
          </ListItem>
          <Collapse in={location.pathname.startsWith('/admin')} timeout="auto" unmountOnExit>
            <Divider className={classes.nestedDiv} />
            <List component="div" disablePadding>
              <ListItem
                className={classes.nested}
                button
                disabled={false}
                classes={{
                  selected: classes.selectedItem,
                }}
                component={Link}
                to={'/admin/organizations'}
                selected={location.pathname === '/admin/organizations'}
              >
                <ListItemIcon>
                  <UsersIcon className={classes.svgIcon} />
                </ListItemIcon>
                <ListItemText primary="Organizations" />
              </ListItem>
              <ListItem
                className={classes.nested}
                button
                disabled={false}
                classes={{
                  selected: classes.selectedItem,
                }}
                component={Link}
                to={'/admin/portfolios'}
                selected={location.pathname === '/admin/portfolios'}
              >
                <ListItemIcon>
                  <PortfolioIcon className={classes.svgIcon} />
                </ListItemIcon>
                <ListItemText primary="Portfolios" />
              </ListItem>
            </List>
          </Collapse>
          <Divider />
        </>
      ) : null}
    </div>
  );
}
