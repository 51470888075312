import React from 'react';

import MaterialTable from 'material-table';
import { Theme } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import mapValues from 'lodash/mapValues';

import { Policy, CurrencyCode, ObjectLiteral } from '../../models';

const useStyles = makeStyles((theme: Theme) => ({
  goodLoss: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  averageLoss: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
  },
  badLoss: {
    fontWeight: 'bold',
    color: theme.palette.error.main,
  },
}));

type BreakdownChartProps = {
  title: string;
  columnName: string;
  lookup: ObjectLiteral;
  cropsMap: ObjectLiteral;
  groupedPolicies: {
    [groupedId: string]: Policy[];
  };
  currencyCode: CurrencyCode | '';
};

const BreakdownTable = (props: BreakdownChartProps) => {
  const classes = useStyles();
  const { groupedPolicies, currencyCode, title, columnName, lookup, cropsMap } = props;
  const mappedPols = mapValues(groupedPolicies, (groupPols, groupId) => {
    let summary = {
      groupId,
      product: groupPols[0].product_id,
      policies: groupPols.length,
      premiums: 0,
      limit: 0,
      claims: 0,
      lossOnLine: 0,
      lossRatio: 0,
    };
    groupPols.forEach(pol => {
      summary.premiums += pol.total_premium.amount ?? 0;
      summary.limit += pol.sum_insured ?? 0;
      summary.claims += pol.payout ?? 0;
    });
    if (summary.claims !== 0) {
      summary.lossOnLine = summary.claims / summary.limit;
      // summary.lossRatio = summary.claims / summary.premiums;
    }
    return summary;
  });
  const percentRender = (data: number, color = false) => {
    const formatted = `${Math.floor(data * 100)}%`;
    if (color) {
      let styleClass: 'goodLoss' | 'averageLoss' | 'badLoss';
      if (data < 0.2) {
        styleClass = 'goodLoss';
      } else if (data < 0.5) {
        styleClass = 'averageLoss';
      } else {
        styleClass = 'badLoss';
      }
      return <span className={classes[styleClass]}>{formatted}</span>;
    }
    return formatted;
  };
  return (
    <MaterialTable
      title={title}
      data={Object.values(mappedPols)}
      columns={[
        { title: columnName, field: 'groupId', lookup },
        { title: 'Product', field: 'product', lookup: cropsMap },
        { title: 'Policies', field: 'policies' },
        {
          title: 'Premium',
          field: 'premiums',
          type: 'currency',
          currencySetting: { currencyCode, minimumFractionDigits: currencyCode === 'USD' ? 2 : 0 },
        },
        {
          title: 'Limit',
          field: 'limit',
          type: 'currency',
          currencySetting: { currencyCode, minimumFractionDigits: currencyCode === 'USD' ? 2 : 0 },
        },
        {
          title: 'Claims',
          field: 'claims',
          type: 'currency',
          currencySetting: { currencyCode, minimumFractionDigits: currencyCode === 'USD' ? 2 : 0 },
        },
        {
          title: 'Claim Rate',
          field: 'lossOnLine',
          render: rowdata => percentRender(rowdata.lossOnLine),
        },
      ]}
    />
  );
};

export default BreakdownTable;
