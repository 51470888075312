import { StatusSummary } from './models';

export const API_V2_ROOT = process.env.REACT_APP_API_V2_ROOT || 'https://api.worldcovr.com';
export const RISKASSIST_URL =
  process.env.REACT_APP_RISKASSIST_URL || 'https://riskassist.worldcovr.com';
export const MAPBOX_TOKEN =
  process.env.REACT_APP_MAPBOX_TOKEN ||
  'pk.eyJ1IjoiamFzb25zY2hhcGlybyIsImEiOiJjazNlcnJpbXUwMWRzM2VtbDhpcTdxemN3In0.PVLZkH91kDLHYqq2g45Y5g';
export const MIXPANEL_TOKEN =
  process.env.REACT_APP_MIXPANEL_TOKEN || '4797371f4c6bd1034416c0ec600e6666';
export const GA_TRACKER_ID = process.env.REACT_APP_GA_TRACKER_ID || 'UA-75232881-5';
export const FLUTTERWAVE_PUB_KEY =
  process.env.REACT_APP_FLUTTERWAVE_PUB_KEY || 'FLWPUBK_TEST-983ebc5fc801e666676da716d56e5f47-X';
export const MAGIC_API_KEY = process.env.REACT_APP_MAGIC_API_KEY || 'pk_test_F22052A660580DB8';
export const ENTER_KEYCODE = 13;

type ItemMap = {
  [key in string | number]: string;
};
export const countryMap: ItemMap = {
  1: 'Ghana',
  2: 'Kenya',
  3: 'Tanzania',
  56: 'Uganda',
};

export const currencyMap: ItemMap = {
  1: 'GHS',
  2: 'XOF',
  3: 'USD',
  4: 'KES',
  5: 'TZS',
  28: 'UGX',
};

export const currency = {
  1: 'GH₵',
  4: 'KSh',
  56: 'USh',
};
export const currencyCode = {
  GH: 'GH₵',
  KE: 'KSh',
  UG: 'USh',
};

export const currencyIsoCode = {
  'GH₵': 'GHS',
  KSh: 'KES',
  USh: 'UGX',
};

export const crops = {
  1: 'Maize',
  2: 'Rice',
  5: 'Sorghum',
  6: 'Groundnut',
};
export const seasons = {
  1: '2015',
  2: '2016',
  3: '2017',
  7: '2018',
  8: '2018 Minor',
  9: '2019',
  10: '2019 Minor',
};

// Crops available in each country
export const countryCrops = {
  GH: [1, 2, 5, 6],
  KE: [1, 5],
  UG: [1, 2, 5, 6],
};

export const defaultStatusSummary: StatusSummary = {
  total: 0,
  active: 0,
  triggered: 0,
  expired: 0,
  priced: 0,
  pending: 0,
  'paid out': 0,
};
