import React from 'react';

export default class CropApp extends React.Component {
  constructor(props: any) {
    super(props);
    this.instance = null;
  }

  instance: Node | HTMLDivElement | null;

  componentDidMount() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.innerHTML =
      'window.$crisp=[];window.CRISP_WEBSITE_ID="689afc8c-2d5a-45fd-9c0e-33b8943729dc";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();';
    this.instance!.appendChild(s);
  }

  render() {
    return <div ref={el => (this.instance = el)} />;
  }
}
