import keyBy from 'lodash/keyBy';

export type Locale = {
  name: string;
  locale: string;
  premium: number;
  currency: string;
  productId: number;
};

type LocaleMap = {
  [localeKey: string]: Locale;
};

const locales: Locale[] = [
  { name: 'USA', locale: 'en-US', premium: 125000, currency: 'USD', productId: 2 },
  { name: 'Brazil', locale: 'pt-BR', premium: 60000, currency: 'BRL', productId: 1 },
  { name: 'Kenya', locale: 'en-KE', premium: 10000, currency: 'KES', productId: 1 },
];

export const localeMap: LocaleMap = keyBy(locales, 'locale');
