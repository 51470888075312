import { FluxStandardAction, RootState } from '.';
import { ActivationStatus } from '../models';

export const SET_DISTRICTS = '@@filters/SET_DISTRICTS';
export const SET_TAGS = '@@filters/SET_TAGS';
export const SET_ELIGIBLE = '@@filters/SET_ELIGIBLE';
export const SET_ACTIVATION_STATUS = '@@filters/SET_ACTIVATION_STATUS';

export type FilterState = {
  districts: number[];
  tags: number[];
  onlyEligible: boolean;
  activationStatuses: ActivationStatus[];
};

const initialState: FilterState = {
  onlyEligible: false,
  districts: [],
  tags: [],
  activationStatuses: [],
};

export default function reducer(state = initialState, action: FluxStandardAction): FilterState {
  switch (action.type) {
    case SET_ELIGIBLE:
      return {
        ...state,
        onlyEligible: action.payload,
      };
    case SET_DISTRICTS:
      return {
        ...state,
        districts: action.payload,
      };
    case SET_TAGS:
      return {
        ...state,
        tags: action.payload,
      };
    case SET_ACTIVATION_STATUS:
      return {
        ...state,
        activationStatuses: action.payload,
      };
    default:
      return state;
  }
}

export function setDistrictsFilter(districts: number[]) {
  return {
    type: SET_DISTRICTS,
    payload: districts,
  };
}

export function setTagsFilter(tags: number[]) {
  return {
    type: SET_TAGS,
    payload: tags,
  };
}

export function setEligibleFilter(onlyEligible: boolean) {
  return {
    type: SET_ELIGIBLE,
    payload: onlyEligible,
  };
}

export function setActivationStatusFilter(statuses: boolean) {
  return {
    type: SET_ACTIVATION_STATUS,
    payload: statuses,
  };
}

export const filteredDistrictsSelector = (state: RootState) => state.filters.districts;
export const filteredTagsSelector = (state: RootState) => state.filters.tags;
export const filteredEligibleSelector = (state: RootState) => state.filters.onlyEligible;
export const filteredActivationStatusSelector = (state: RootState) =>
  state.filters.activationStatuses;
