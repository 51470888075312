import { createSelector } from 'reselect';
import { Product } from '../../models';
import { FluxStandardAction, RootState } from '.';
import mapValues from 'lodash/mapValues';

export const HYDRATE_STATE = '@@PRODUCTS/HYDRATE';

export type ProductsState = {
  isLoadingData: boolean;
  lastUpdate?: string;
  byId?: {
    [id: number]: Product;
  };
  allIds?: number[];
};

export const productInitialState: ProductsState = {
  isLoadingData: false,
  byId: undefined,
  allIds: undefined,
};

const defaultInitialState: ProductsState = {
  isLoadingData: false,
  byId: {
    1: {
      id: 1,
      code: 'WC-MAIZE-DR01',
      country_id: 1,
      rate_on_line: 5,
      crop: { id: 1, name: 'Maize' },
    },
    2: {
      id: 2,
      code: 'WC-ALM-FX01',
      country_id: 1,
      rate_on_line: 5,
      crop: { id: 2, name: 'Almond' },
    },
  },
  allIds: [1, 2],
};

const getInitialState = () => ({ ...defaultInitialState });

export default function reducer(
  state = getInitialState(),
  action: FluxStandardAction,
): ProductsState {
  switch (action.type) {
    default:
      return state;
  }
}

export const productByIdSelector = (state: RootState) => state.products?.byId || {};
export const cropByProductIdSelector = createSelector(productByIdSelector, productById =>
  mapValues(productById, x => x.crop.name),
);
