import { RSAA } from 'redux-api-middleware'; // RSAA = '@@redux-api-middleware/RSAA'
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';

import { createSelector } from 'reselect';

import { API_V2_ROOT } from '../env';
import { District, DistrictMap } from '../models';
import { FluxStandardAction, RootState } from '.';
import { selectedPortfolioCountrySelector } from './portfolios';
import { getSelectedPortfolioPolicies } from './policies';

export const LOAD_DISTRICTS_REQUEST = '@@district/DISTRICTS_REQUEST';
export const LOAD_DISTRICTS_SUCCESS = '@@district/DISTRICTS_SUCCESS';
export const LOAD_DISTRICTS_FAILURE = '@@district/DISTRICTS_FAILURE';

export const LOAD_DISTRICT_REQUEST = '@@district/DISTRICT_REQUEST';
export const LOAD_DISTRICT_SUCCESS = '@@district/DISTRICT_SUCCESS';
export const LOAD_DISTRICT_FAILURE = '@@district/DISTRICT_FAILURE';

export const fetchDistrict: any = (districtId: number, token: string) => ({
  [RSAA]: {
    endpoint: `${API_V2_ROOT}/districts/${districtId}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
    types: [LOAD_DISTRICT_REQUEST, LOAD_DISTRICT_SUCCESS, LOAD_DISTRICT_FAILURE],
  },
});

export const fetchDistricts: any = (countryId: number, token: string) => ({
  [RSAA]: {
    endpoint: `${API_V2_ROOT}/districts?country_id=${countryId}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
    types: [
      LOAD_DISTRICTS_REQUEST,
      {
        type: LOAD_DISTRICTS_SUCCESS,
        meta: { countryId },
      },
      LOAD_DISTRICTS_FAILURE,
    ],
  },
});

export type DistrictState = {
  isLoadingData: boolean;
  lastUpdate?: string;
  byId?: {
    [policyId: number]: District;
  };
  allIds?: number[];
  byCountry: {
    [countryId: number]: number[];
  };
};

export const districtInitialState: DistrictState = {
  isLoadingData: false,
  byId: undefined,
  allIds: undefined,
  byCountry: {},
};

export default function reducer(
  state = districtInitialState,
  action: FluxStandardAction,
): DistrictState {
  switch (action.type) {
    case LOAD_DISTRICT_REQUEST:
    case LOAD_DISTRICTS_REQUEST:
      return {
        ...state,
        isLoadingData: true,
      };
    case LOAD_DISTRICT_SUCCESS: {
      const district = action.payload;
      return {
        ...state,
        isLoadingData: false,
        byId: {
          ...state.byId,
          [district.id]: district,
        },
        allIds: state.allIds ? uniq([...state.allIds, district.id]) : [district.id],
        lastUpdate: new Date().toJSON(),
      };
    }
    case LOAD_DISTRICTS_SUCCESS: {
      const districtsById = keyBy(action.payload, 'id');
      const districtIds = Object.keys(districtsById).map(key => parseInt(key, 10));
      const { countryId } = action.meta;
      return {
        ...state,
        isLoadingData: false,
        byId: {
          ...state.byId,
          ...districtsById,
        },
        allIds: state.allIds ? [...state.allIds, ...districtIds] : [...districtIds],
        byCountry: {
          ...state.byCountry,
          [countryId]: [...districtIds],
        },
        lastUpdate: new Date().toJSON(),
      };
    }
    case LOAD_DISTRICT_FAILURE:
    case LOAD_DISTRICTS_FAILURE:
      // TODO handle
      return state;
    default:
      return state;
  }
}

const byCountrySelector = (state: RootState) => state.districts.byCountry;
export const districtByIdSelector = (state: RootState) => state.districts.byId;

const selectedCountryIdsSelector = createSelector(
  selectedPortfolioCountrySelector,
  byCountrySelector,
  (countryId, districts) => (countryId ? districts[countryId] : []),
);

export const districtsByCountrySelector = createSelector(
  selectedCountryIdsSelector,
  districtByIdSelector,
  (countryDistrictIds, districtsById) =>
    countryDistrictIds === undefined || districtsById === undefined
      ? undefined
      : countryDistrictIds.map(id => districtsById[id]),
);

export const selectedPortfolioDistricts = createSelector(
  getSelectedPortfolioPolicies,
  districtByIdSelector,
  (portfolioPolicies, districtById) => {
    if (portfolioPolicies === undefined || districtById === undefined) return undefined;
    const portfolioDistricts = uniq(
      portfolioPolicies
        ?.filter(pol => pol.trigger_location != null)
        .map(pol => pol.trigger_location!.district_id),
    );
    let districtMap: DistrictMap = {};
    portfolioDistricts.forEach(id => {
      districtMap[id] = districtById[id];
    });
    return districtMap;
  },
);
