import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';

import { getCurrencySymbol, abbreviateNumber } from '../../utils';
import { CurrencyCode } from '../../models';
import PortfolioDateRange from './PortfolioDateRange';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(3),
  },
  payment: {
    padding: theme.spacing(2),
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
  },
}));

export type PortfolioOverviewCardProps = {
  currencyCode: CurrencyCode | '';
  triggeredPayout?: number;
  sumInsured?: number;
  startDate: string | null;
  endDate: string | null;
  currentDate?: string;
};

const PortfolioOverviewCard = (props: PortfolioOverviewCardProps) => {
  const { currencyCode, triggeredPayout, sumInsured, startDate, endDate, currentDate } = props;
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.flex}>
        <div className={classes.payment}>
          <Typography variant="body2" color="textSecondary" component="p">
            Triggered Payout
          </Typography>
          <Typography gutterBottom variant="h5" component="h2">
            {triggeredPayout && currencyCode
              ? `${getCurrencySymbol(currencyCode)}${abbreviateNumber(triggeredPayout)}`
              : 'N/A'}
          </Typography>
        </div>
        <div className={classes.payment}>
          <Typography variant="body2" color="textSecondary" component="p">
            Total Sum Insured
          </Typography>
          <Typography gutterBottom variant="h5" component="h2">
            {sumInsured && currencyCode
              ? `${getCurrencySymbol(currencyCode)}${abbreviateNumber(sumInsured)}`
              : 'N/A'}
          </Typography>
        </div>
      </CardContent>
      {startDate && endDate ? (
        <CardActions>
          <PortfolioDateRange startDate={startDate} endDate={endDate} currentDate={currentDate} />
        </CardActions>
      ) : null}
    </Card>
  );
};

export default PortfolioOverviewCard;
