import React from 'react';
import clsx from 'clsx';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import { activeStatuses, triggeredStatuses, calculateStatus } from '../utils';
import { Policy } from '../models';

type ChipColor = 'inherit' | 'primary' | 'secondary' | 'default' | undefined;
type PolicyStatusProps = {
  policy: Policy;
};

const useStyles = makeStyles((theme: Theme) => ({
  statusChip: {
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    padding: `0 ${theme.spacing(1)}px`,
    minWidth: 100,
  },
  pendingChip: {
    backgroundColor: theme.palette.secondary.main,
  },
  pricedChip: {
    backgroundColor: theme.palette.secondary.main,
  },
  activeChip: {
    backgroundColor: theme.palette.primary.light,
  },
  expiredChip: {},
  triggeredChip: {
    backgroundColor: theme.palette.primary.main,
  },
  paidoutChip: {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const PolicyStatusChip = ({ policy }: PolicyStatusProps) => {
  const classes = useStyles();
  const colorClass: any = classes;
  const status = calculateStatus(policy);
  let statusColor: ChipColor = 'default';
  if (triggeredStatuses.includes(status)) {
    statusColor = 'secondary';
  } else if (activeStatuses.includes(status)) {
    statusColor = 'primary';
  }

  return (
    <Chip
      className={clsx(colorClass[`${status.replace(' ', '')}Chip`], classes.statusChip)}
      label={status.toUpperCase()}
      color={statusColor}
    />
  );
};

export default PolicyStatusChip;
