import React from 'react';
import moment from 'moment';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import CalendarIcon from '@material-ui/icons/DateRange';

const useStyles = makeStyles((theme: Theme) => ({
  progressBar: {
    height: 10,
    borderRadius: 10,
  },
  dateDescription: {
    minWidth: 105,
  },
  calIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 35,
  },
  monitoringProgress: {
    width: '100%',
  },
}));

export type PortfolioDateRangeProps = {
  startDate: string;
  endDate: string;
  currentDate?: string;
};

const PortfolioDateRange = (props: PortfolioDateRangeProps) => {
  const { startDate, endDate, currentDate = moment().format('YYYY-MM-DD') } = props;
  const classes = useStyles();

  function calculateDateRange(start?: string, end?: string, current?: string) {
    const startDate = moment(start, 'YYYY-MM-DD');
    const endDate = moment(end, 'YYYY-MM-DD');
    const currentDate = moment(current, 'YYYY-MM-DD');
    if (currentDate.isBefore(startDate)) {
      return 0;
    } else if (currentDate.isAfter(endDate)) {
      return 100;
    } else {
      const totalRange = endDate.diff(startDate, 'days');
      const currentRange = currentDate.diff(startDate, 'days');
      return Math.floor((currentRange / totalRange) * 100);
    }
  }

  const startText =
    startDate && moment(startDate, 'YYYY-MM-DD').isBefore(moment(currentDate, 'YYYY-MM-DD'))
      ? 'Started on'
      : 'Starts on';
  const endText =
    endDate && moment(endDate, 'YYYY-MM-DD').isBefore(moment(currentDate, 'YYYY-MM-DD'))
      ? 'Expired on'
      : 'Expires on';

  return (
    <>
      <CalendarIcon className={classes.calIcon} />
      <div className={classes.dateDescription}>
        <Typography variant="body2" color="textSecondary" component="p">
          {startText}
        </Typography>
        <Typography variant="body1" component="p">
          {moment(startDate, 'YYYY-MM-DD').format('MMM D, YYYY')}
        </Typography>
      </div>
      <div className={classes.monitoringProgress}>
        <LinearProgress
          className={classes.progressBar}
          variant="determinate"
          value={calculateDateRange(startDate, endDate, currentDate)}
        />
      </div>
      <div className={classes.dateDescription}>
        <Typography variant="body2" color="textSecondary" component="p">
          {endText}
        </Typography>
        <Typography variant="body1" component="p">
          {moment(endDate, 'YYYY-MM-DD').format('MMM D, YYYY')}
        </Typography>
      </div>
    </>
  );
};

export default PortfolioDateRange;
