// Handles high-level app routing
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Header from '../components/Header';
import PortfolioSelector from '../containers/PortfolioSelectorConnected';
import HeaderBar from './containers/HeaderBarConnected';

import '../App.css';
import HeaderDrawer from './containers/HeaderDrawerDemo';
import LoginPage from './containers/LoginPage';
import MonitorPortfolio from './containers/MonitorPortfolio';
import PolicyDetails from './containers/PolicyDetails';
import { useSelector } from 'react-redux';
import ScrollToTop from '../components/view/ScrollToTop';

const NotFound = () => <h1>404 - Page Not Found</h1>;

const App = () => {
  const isAuthenticated = useSelector((state: any) => state.user.isLoggedIn);

  return (
    <div className="App">
      <Header
        headerDrawerComponent={HeaderDrawer}
        headerBarComponent={HeaderBar}
        selectorComponent={PortfolioSelector}
        isAuthenticated={isAuthenticated}
        onlyRenderChilden={false}
      >
        <ScrollToTop />
        <Switch>
          <Route path="/login" component={LoginPage} />
          {isAuthenticated ? (
            <>
              <Route path="/monitor" component={MonitorPortfolio} />
              <Route path="/policies/:policyId" component={PolicyDetails} />
            </>
          ) : (
            <Redirect to="/login" />
          )}
          <Route component={NotFound} />
        </Switch>
      </Header>
    </div>
  );
};

export default App;
