import React from 'react';

import MaterialTable from 'material-table';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

import { User, Portfolio } from '../../models';

type UsersTableProps = {
  users?: User[];
  portfolioMap: {
    [id: number]: Portfolio;
  };
  title: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  portfolioChip: {
    margin: theme.spacing(0.5),
  },
}));

const USER_ROLE_MAP = {
  wc_admin: 'WC Admin',
  org_admin: 'Owner',
  user: 'User',
};

function sortRoles(x: User, y: User) {
  const roles = ['wc_admin', 'org_admin', 'user'];
  return roles.indexOf(x.role!) - roles.indexOf(y.role!);
}

export default function UserTable(props: UsersTableProps) {
  const { users = [], title, portfolioMap } = props;
  const classes = useStyles();

  const MIN_PAGE_SIZE = 10;
  const pageSize = users.length > MIN_PAGE_SIZE ? MIN_PAGE_SIZE : users.length;

  const renderPortfolioList = (
    portfolioMap: {
      [id: number]: Portfolio;
    },
    portfolioIds: number[],
  ) => {
    const portfolioCodes = portfolioIds
      .map(id => portfolioMap[id]?.portfolio_code ?? '')
      .filter(port => port !== '');
    return portfolioCodes.map(code => (
      <Chip key={code} className={classes.portfolioChip} label={code} />
    ));
  };

  return (
    <MaterialTable
      columns={[
        {
          title: 'Name',
          field: 'name',
        },
        {
          title: 'Email',
          field: 'username',
        },
        {
          title: 'Role',
          field: 'role',
          lookup: USER_ROLE_MAP,
        },
        {
          title: 'Portfolios',
          field: 'portfolios',
          render: user =>
            user.role === 'user' ? renderPortfolioList(portfolioMap, user.portfolios ?? []) : null,
        },
      ]}
      data={users.slice().sort(sortRoles)}
      title={title}
      options={{
        pageSizeOptions: pageSize < MIN_PAGE_SIZE ? [pageSize] : [MIN_PAGE_SIZE, 25, 50, 100, 500],
        pageSize,
      }}
    />
  );
}
