import React from 'react';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: `${theme.spacing(2)} 0`,
  },
}));

type ApiKeyPanelProps = {
  hasApiKey: boolean;
  apiKey?: string;
  onGenerateKey: () => Promise<void>;
};

function ApiKeyPanel(props: ApiKeyPanelProps) {
  const classes = useStyles();
  const { onGenerateKey, hasApiKey, apiKey } = props;

  const generateInitialKey = (
    <>
      <Typography variant="body2" gutterBottom>
        You currently have no API Key associated with this account.
      </Typography>
      <Typography variant="body2" gutterBottom>
        You can generate one now, it will have all the same permissions as your user account.
      </Typography>
      <Button variant="contained" color="primary" onClick={onGenerateKey}>
        Generate Key
      </Button>
    </>
  );

  const regenerateKey = (
    <>
      <Typography variant="body2" gutterBottom>
        You currently have an API Key associated with this account, for security purposes we do not
        store the original so if you have lost it you will need to create a new one.
      </Typography>
      <Typography variant="body2" gutterBottom>
        You can regenerate a new key now, however your previous key will no longer work.
      </Typography>
      <Typography variant="body2" gutterBottom>
        Your generated API key will have all the same permissions as your user account.
      </Typography>
      <Button variant="contained" color="secondary" onClick={onGenerateKey}>
        Regenerate Key
      </Button>
    </>
  );

  const generatedKeySuccess = (
    <>
      <Alert severity="warning">
        Make sure to copy your new personal API Key now. You won’t be able to see it again!
      </Alert>
      <Alert severity="success">{apiKey}</Alert>
    </>
  );

  let keyContent = generateInitialKey;
  if (apiKey != null) {
    keyContent = generatedKeySuccess;
  } else if (hasApiKey === true) {
    keyContent = regenerateKey;
  }

  return <Paper className={classes.paper}>{keyContent}</Paper>;
}

export default ApiKeyPanel;
