import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';
export const START_DAYS = 26;
export const MONITORING_DAYS = 76;
export const GERMINATION_DAYS = 14;
export const FLOWERING_DAYS = 32;

function getDate(days: number = 0) {
  return moment()
    .add(days, 'days')
    .format(dateFormat);
}

export function getPlantingDate() {
  return getDate();
}

export function getStartDate() {
  return getDate(START_DAYS);
}

export function getGerminationEndDate() {
  return getDate(START_DAYS + GERMINATION_DAYS - 1);
}

export function getFloweringDate() {
  return getDate(START_DAYS + GERMINATION_DAYS);
}

export function getFloweringEndDate() {
  return getDate(START_DAYS + GERMINATION_DAYS + FLOWERING_DAYS - 1);
}

export function getMaturationDate() {
  return getDate(START_DAYS + GERMINATION_DAYS + FLOWERING_DAYS);
}

export function getEndDate() {
  return getDate(START_DAYS + MONITORING_DAYS);
}

export function getAlmondUploadDate() {
  return '2020-12-31';
}

export function getAlmondStartDate() {
  return '2021-02-14';
}

export function getAlmondTriggerDate() {
  return '2021-03-05';
}

export function getAlmondEndDate() {
  return '2021-03-26';
}
