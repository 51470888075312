import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

import { District, DistrictMap, TagMap, ActivationStatus } from '../models';
import { selectedPortfolioDistricts } from '../reducers/districts';
import { RootState } from '../reducers';
import {
  setDistrictsFilter,
  filteredDistrictsSelector,
  filteredTagsSelector,
  filteredEligibleSelector,
  filteredActivationStatusSelector,
  setEligibleFilter,
  setTagsFilter,
  setActivationStatusFilter,
} from '../reducers/filters';
import { truncate } from '../utils';
import { selectedPortfolioTagsSelector } from '../reducers/portfolio-summaries';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  filterGrid: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function FilterPolicyTable(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const portfolioDistrictsMap =
    useSelector((state: RootState) => selectedPortfolioDistricts(state)) || ({} as DistrictMap);
  const portfolioDistricts: District[] = Object.values(portfolioDistrictsMap);
  const portfolioTags: TagMap = useSelector((state: RootState) =>
    selectedPortfolioTagsSelector(state),
  );
  const filteredDistricts = useSelector((state: RootState) => filteredDistrictsSelector(state));
  const filteredTagIds = useSelector((state: RootState) => filteredTagsSelector(state));
  const filteredEligbleOnly = useSelector((state: RootState) => filteredEligibleSelector(state));
  const filteredActivationStatus = useSelector((state: RootState) =>
    filteredActivationStatusSelector(state),
  );

  type ActivationStatusOption = {
    value: ActivationStatus;
    label: string;
  };
  const activationStatuses: ActivationStatusOption[] = [
    { value: 'pending_activation', label: 'Pending Activation' },
    { value: 'active', label: 'Active' },
    { value: 'eligibility_final', label: 'Eligibility Finalized' },
    { value: 'expired', label: 'Expired' },
  ];

  const handleChangeDistrict = (ev: any) => dispatch(setDistrictsFilter(ev.target.value));
  const handleChangeTag = (ev: any) => dispatch(setTagsFilter(ev.target.value));
  const handleChangeEligible = (ev: any) => dispatch(setEligibleFilter(ev.target.checked));
  const handleChangeActivationStatus = (ev: any) =>
    dispatch(setActivationStatusFilter(ev.target.value));
  return (
    <Grid className={classes.filterGrid} container spacing={3}>
      <Grid item xs={12} sm={4} md={2}>
        <Typography variant="body1">Filter By:</Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={filteredEligbleOnly}
              onChange={handleChangeEligible}
              name="triggeredOnly"
              color="secondary"
            />
          }
          label="Triggered"
        />
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <FormControl className={classes.formControl}>
          <InputLabel id="filter-district-label">Activation</InputLabel>
          <Select
            labelId="filter-activation-label"
            id="filter-activation"
            multiple
            value={filteredActivationStatus}
            onChange={handleChangeActivationStatus}
            input={<Input />}
            renderValue={(selected: any) => {
              const renderLabel = activationStatuses
                .filter(stat => selected.includes(stat.value))
                .map(stat => stat.label)
                .join(',');
              return truncate(renderLabel);
            }}
            MenuProps={MenuProps}
          >
            {activationStatuses.map(activeStatus => (
              <MenuItem key={activeStatus.value} value={activeStatus.value}>
                <Checkbox checked={filteredActivationStatus.includes(activeStatus.value)} />
                <ListItemText primary={activeStatus.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <FormControl className={classes.formControl}>
          <InputLabel id="filter-district-label">District</InputLabel>
          <Select
            labelId="filter-district-label"
            id="filter-district"
            multiple
            value={filteredDistricts}
            onChange={handleChangeDistrict}
            input={<Input />}
            renderValue={(selected: any) => {
              const renderLabel = selected
                .map((distId: number) => portfolioDistrictsMap[distId].name)
                .join(', ');
              return truncate(renderLabel);
            }}
            MenuProps={MenuProps}
          >
            {portfolioDistricts.map((district: District) => (
              <MenuItem key={district.id} value={district.id}>
                <Checkbox checked={filteredDistricts.includes(district.id)} />
                <ListItemText primary={district.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <FormControl className={classes.formControl}>
          <InputLabel id="filter-tags-label">Tag</InputLabel>
          <Select
            labelId="filter-tags-label"
            id="filter-tags"
            multiple
            value={filteredTagIds}
            onChange={handleChangeTag}
            input={<Input />}
            renderValue={(selected: any) =>
              selected.map((tagId: number) => portfolioTags[tagId]).join(', ')
            }
            MenuProps={MenuProps}
          >
            {Object.keys(portfolioTags)
              .map(tagId => parseInt(tagId, 10))
              .map((tagId: number) => (
                <MenuItem key={tagId} value={tagId}>
                  <Checkbox checked={filteredTagIds.includes(tagId)} />
                  <ListItemText primary={portfolioTags[tagId]} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
