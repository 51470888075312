import React, { useEffect, useState } from 'react';
import ReactMapGL, { Layer, Popup, Source } from 'react-map-gl';

import WebMercatorViewport from 'viewport-mercator-project';
import _min from 'lodash/min';
import _max from 'lodash/max';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/';

import { MAPBOX_TOKEN } from '../../env';
import { PolicyLocation } from '../../models';
import {
  clusterCountLayer,
  clusterLayer,
  heatmapLayer,
  payoutAmountLayer,
  unclusteredPointLayer,
} from './layers';
import { toCurrency } from '../../utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(0.5, 2, 2, 2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  mapContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export type PortfolioMapProps = {
  /** Set of individual policy GPS locations with metadata */
  locations?: PolicyLocation[];
  /** Width of parent node, used for sizing */
  parentWidth: number;
  /** Currency used in portfolio policies */
  currency: string;
};

const MAX_ZOOM = 12;
type ViewPort = {
  width?: number;
  height: number;
  zoom: number;
  latitude: number;
  longitude: number;
  maxZoom: number;
};
enum MapType {
  CLUSTER = 'cluster',
  HEATMAP = 'heatmap',
}
/**
 * Map of policies in a customer's portfolio along with
 * summary data of premiums and payouts in each location.
 */
const PortfolioMap = (props: PortfolioMapProps) => {
  const classes = useStyles();
  const { locations = [], currency, parentWidth } = props;

  const [mapType, setMapType] = useState<MapType>(MapType.CLUSTER);
  const [mapData, setMapData] = useState('FeatureCollection');

  const getBoundBox: any = (loc: PolicyLocation[]) => {
    const minLat = _min(loc.map(gps => gps.latitude));
    const minLon = _min(loc.map(gps => gps.longitude));

    const maxLat = _max(loc.map(gps => gps.latitude));
    const maxLon = _max(loc.map(gps => gps.longitude));
    return [
      [minLon, minLat],
      [maxLon, maxLat],
    ];
  };

  const [viewport, setViewport] = useState<ViewPort>({
    height: 350,
    width: 300,
    zoom: 8,
    latitude: 9.5,
    longitude: -0.5,
    maxZoom: 15,
  });

  const [popupInfo, setPopupInfo] = useState<PolicyLocation | null>(null);

  useEffect(() => {
    if (locations.length === 0) {
      return;
    }

    setViewport(vp => {
      const bbox = getBoundBox(locations);
      const newViewport = new WebMercatorViewport({
        ...vp,
        // override width to handle viewport construction issue
        width: parentWidth,
        //width: window.innerWidth,
      });
      const { longitude, latitude, zoom } = newViewport.fitBounds(bbox, {
        padding: 40,
      });

      return {
        ...vp,
        width: parentWidth,
        longitude,
        latitude,
        zoom,
      };
    });
  }, [parentWidth, locations]);

  useEffect(() => {
    const geoGson: any = {
      type: 'FeatureCollection',
      features: locations
        .filter(x => mapType === 'cluster' || x.payout > 0)
        .map(loc => ({
          type: 'Feature',
          id: loc.id,
          properties: {
            name: loc.label,
            premium: loc.premium,
            payout: loc.payout,
            status: loc.status,
          },
          geometry: {
            type: 'Point',
            coordinates: [loc.longitude, loc.latitude],
          },
        })),
    };
    setMapData(geoGson);
  }, [mapType, locations]);

  const _onClick = (event: any) => {
    const { features } = event;
    if (features[0] && features[0].layer.id === 'unclustered-point') {
      setPopupInfo({
        id: features[0].id,
        longitude: features[0].geometry.coordinates[0],
        latitude: features[0].geometry.coordinates[1],
        label: features[0].properties.name,
        premium: features[0].properties.premium,
        payout: features[0].properties.payout,
        status: features[0].properties.status,
      });
    }
  };

  return (
    <Paper className={classes.paper}>
      <div>
        <RadioGroup
          aria-label="map-type"
          name="map-type"
          value={mapType}
          row
          onChange={(_, value) => setMapType(value as MapType)}
        >
          <FormControlLabel value="cluster" control={<Radio />} label="Default Map" />
          <FormControlLabel value="heatmap" control={<Radio />} label="Payouts Heatmap" />
        </RadioGroup>
      </div>
      <ReactMapGL
        key="default-map"
        mapboxApiAccessToken={MAPBOX_TOKEN}
        {...viewport}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        zoom={viewport.zoom < MAX_ZOOM ? viewport.zoom : MAX_ZOOM}
        onViewportChange={(viewport: any) => setViewport(viewport)}
        onClick={_onClick}
        width={`${parentWidth}px`}
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
      >
        <Source
          type="geojson"
          data={mapData}
          cluster={true}
          clusterMaxZoom={14}
          clusterRadius={50}
          clusterProperties={{ payoutSum: ['+', ['get', 'payout']] }}
        >
          {mapType === 'heatmap' ? (
            <Layer key="heatmap" {...heatmapLayer} />
          ) : (
            <Layer key="cluster" {...clusterLayer} />
          )}

          {mapType === 'heatmap' ? (
            <Layer key="payoutSum" {...payoutAmountLayer} />
          ) : (
            <Layer key="clusterCount" {...clusterCountLayer} />
          )}

          <Layer key="unclustered" {...unclusteredPointLayer} />
        </Source>
        {popupInfo && (
          <Popup
            tipSize={5}
            anchor="top"
            latitude={popupInfo.latitude}
            longitude={popupInfo.longitude}
            closeOnClick={false}
            onClose={() => setPopupInfo(null)}
          >
            <div>
              <h4>{popupInfo.label}</h4>
              <p>Status: {popupInfo.status}</p>
              <p>Premium: {toCurrency(popupInfo.premium, currency)}</p>
              <p>Payout: {popupInfo.payout ? toCurrency(popupInfo.payout, currency) : 'N/A'}</p>
            </div>
          </Popup>
        )}
      </ReactMapGL>
    </Paper>
  );
};

export default PortfolioMap;
