export const LOGIN = '@@user/LOGIN';
export const LOGOUT = '@@user/LOGOUT';

const initialState: any = {
  isLoggedIn: !!window.sessionStorage.getItem('wclogin'),
  loginUser: window.sessionStorage.getItem('wclogin'),
  metadata: {
    organizationId: 1,
    role: 'user',
  },
  nickname: 'brad.pitt',
  name: 'Brad Pitt',
  picture:
    'https://s.gravatar.com/avatar/164aabbbee5aad0825921ac0ffdc9627?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fja.png',
  updated_at: '2020-01-29T12:04:21.710Z',
  email: 'brad.pitt@acmeagro.org',
  email_verified: true,
  sub: '',
};

export default function reducer(state = initialState, action: any): any {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loginUser: action.payload.email,
        locale: action.payload.locale,
        isLoggedIn: true,
      };
    case LOGOUT:
      return {
        ...state,
        loginUser: undefined,
        locale: undefined,
        isLoggedIn: false,
      };
    default:
      return state;
  }
}
