import React from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';

import { RootState } from '../reducers';
import {
  selectedPortfolioCurrencySelector,
  selectedPortfolioTotalBalanceSelector,
} from '../reducers/portfolios';
import HeaderBar from '../../components/HeaderBar';
import PortfolioSelector from '../../containers/PortfolioSelectorConnected';
import { LOGOUT } from '../reducers/user';

const mapState = (state: RootState) => ({
  organization: state.organization?.metadata,
  currency: selectedPortfolioCurrencySelector(state),
  balance: selectedPortfolioTotalBalanceSelector(state),
  isAuthenticated: state.user.isLoggedIn,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
interface HeaderBarAuthProps extends PropsFromRedux {
  handleDrawerToggle: () => void;
}

function HeaderBarAuth(props: HeaderBarAuthProps) {
  const dispatch = useDispatch();
  const user = {
    name: 'Brad Pitt',
    picture: 'https://upload.wikimedia.org/wikipedia/commons/d/db/BradPittBAR08.jpg',
  };
  const logoutUser = () => {
    window.sessionStorage.removeItem('wclogin');
    dispatch({ type: LOGOUT });
    // Clear the reducer - hacky
    window.location.reload();
  };
  return (
    <HeaderBar
      loginWithRedirect={() => {}}
      selectorComponent={PortfolioSelector}
      logout={logoutUser}
      user={user}
      {...props}
    />
  );
}

export default connector(HeaderBarAuth);
