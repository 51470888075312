import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { Theme } from '@material-ui/core';

import LoginBenefits from '../../components/LoginBenefits';
import { LOGIN } from '../reducers/user';
import { Redirect } from 'react-router-dom';
import { ENTER_KEYCODE } from '../../env';
import { HYDRATE_STATE } from '../reducers/policies';
import { getAlmondUploadDate, getPlantingDate } from '../reducers/timeUtils';
import { SET_DATE } from '../reducers/time';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    maxWidth: 500,
    margin: 'auto',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    textAlign: 'left',
    margin: theme.spacing(1),
  },
  label: {
    background: 'white',
  },
}));

export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [country, setCountry] = useState('en-GH');
  const [error, setError] = useState('');

  const isAuthenticated = useSelector((state: any) => state.user.isLoggedIn);
  const dispatch = useDispatch();

  const classes = useStyles();

  const localeMap = [
    { name: 'USA (Almonds)', locale: 'en-US' },
    { name: 'Brazil (Maize)', locale: 'pt-BR' },
    { name: 'Kenya (Maize)', locale: 'en-KE' },
  ];

  const confirmLogin = () => {
    if (email.endsWith('@worldcovr.com') && password.toLowerCase() === 'test') {
      setError('');
      window.sessionStorage.setItem('wclogin', email);
      window.sessionStorage.setItem('country', country);
      dispatch({ type: LOGIN, payload: { email, locale: country } });
      dispatch({ type: HYDRATE_STATE, payload: { data: country } });
      dispatch({
        type: SET_DATE,
        payload: country === 'en-US' ? getAlmondUploadDate() : getPlantingDate(),
      });
    } else {
      setError('Invalid Username or Password');
    }
  };

  const catchReturn = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.keyCode === ENTER_KEYCODE) {
      // Enter key
      confirmLogin();
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/monitor" />;
  }

  return (
    <Grid className={classes.root} container spacing={2}>
      <Grid item xs={12}>
        <h2>Sign in to RiskAssist (DEMO)</h2>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <TextField
            label="Email"
            className={classes.margin}
            id="outlined-adornment-email"
            value={email}
            error={!!error}
            placeholder="user@example.org"
            onChange={(event: any) => setEmail(event.target.value)}
            onKeyDown={(event: any) => catchReturn(event)}
            variant="outlined"
          />
          <TextField
            label="Password"
            className={classes.margin}
            error={!!error}
            helperText={error}
            type="password"
            id="outlined-adornment-phone"
            value={password}
            onKeyDown={event => catchReturn(event)}
            onChange={(event: any) => setPassword(event.target.value)}
            variant="outlined"
          />
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.label} id="country-label">
              Demo Country
            </InputLabel>
            <Select
              value={country}
              labelId="country-label"
              onChange={event => setCountry((event.target as HTMLInputElement).value)}
            >
              {localeMap.map(loc => (
                <MenuItem key={loc.locale} value={loc.locale}>
                  {loc.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" color="secondary" onClick={confirmLogin}>
            Log In
          </Button>
        </Paper>
      </Grid>
      <LoginBenefits />
    </Grid>
  );
}
