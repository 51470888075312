import { combineReducers } from 'redux';

import user from './user';
import users from './users';
import districts from './districts';
import portfolios from './portfolios';
import portfolioSummaries from './portfolio-summaries';
import policies from './policies';
import triggers from './triggers';
import products from './products';
import filters from './filters';

const rootReducer = combineReducers({
  portfolios,
  portfolioSummaries,
  districts,
  policies,
  triggers,
  user,
  products,
  filters,
  users,
});

export type FluxStandardAction = {
  type: string;
  payload?: any;
  error?: boolean;
  meta?: any;
};

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
