import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import axios from 'axios';
import { API_V2_ROOT } from '../../env';

const BULK_IMPORT_ENDPOINT = `${API_V2_ROOT}/jobs/bulk-import`;

type Props = {
  onUploadSuccess?: () => void;
  portfolioId?: number;
  token?: string;
};

const BulkPolicyFileUpload = (props: Props) => {
  const { token, portfolioId, onUploadSuccess } = props;
  const [selectedFile, setSelectedFile] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);

  function handleChangeFile(e: any) {
    setFileUploaded(false);
    setSelectedFile(e.target.files[0]);
  }

  async function uploadFile(portfolioId: number | undefined, file: File | undefined) {
    if (portfolioId == null || file == null) return;
    const data = new FormData();
    data.append('file', file, file.name);
    data.append('portfolio_id', portfolioId.toString());

    try {
      setIsUploading(true);

      await axios.post(BULK_IMPORT_ENDPOINT, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setIsUploading(false);
      setFileUploaded(true);
      if (onUploadSuccess) {
        onUploadSuccess();
      }
    } catch (e) {
      setIsUploading(false);
      setFileUploaded(false);
      console.error(e);
    }
  }

  return (
    <>
      <form>
        <input
          id="csv-upload"
          type="file"
          accept="text/csv"
          onChange={e => handleChangeFile(e)}
          style={{ margin: 8 }}
        />
        <div>
          <Button
            variant="contained"
            color="primary"
            component="span"
            style={{ margin: 16 }}
            onClick={() => uploadFile(portfolioId, selectedFile)}
          >
            Upload File
          </Button>
        </div>
        {fileUploaded ? <div>File uploaded!</div> : null}
      </form>
    </>
  );
};

export default BulkPolicyFileUpload;
