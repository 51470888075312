import { EnterpriseOrganization } from '../../models';

export type OrganizationState = {
  isLoadingData: boolean;
  metadata?: EnterpriseOrganization;
};

const initialState: OrganizationState = {
  isLoadingData: false,
  metadata: {
    id: 1,
    name: 'ACME Agro Inc.',
    balances: [
      {
        currency: {
          id: 1,
          iso_code: 'GHS',
        },
        balance: 0,
      },
    ],
  },
};

type OrganizationAction = {
  type: string;
  payload: {
    data: any;
  };
};

export default function reducer(
  state = initialState,
  action: OrganizationAction,
): OrganizationState {
  switch (action.type) {
    default:
      return state;
  }
}
