import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import grey from '@material-ui/core/colors/grey';

import MaterialTable from 'material-table';

import { API_V2_ROOT, countryMap, currencyMap } from '../env';
import { getPortfoliosList, fetchPortfolios } from '../reducers/portfolios';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../reducers';
import { toCurrency } from '../utils';
import { EnterpriseOrganization, PortfolioType } from '../models';
import { getToken } from '../reducers/user';

const ORGANIZATIONS_ENDPOINT = `${API_V2_ROOT}/organizations`;
const PORTFOLIOS_ENDPOINT = `${API_V2_ROOT}/portfolios`;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  formRoot: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const NA = () => <span style={{ color: grey[500] }}>N/A</span>;
type FormData = {
  country_id: number;
  currency_id: number;
  organization_id: number;
  portfolio_code: string;
  portfolio_type: PortfolioType;
};

export default function PortfolioApp() {
  const classes = useStyles();
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = useState(false);
  const [formValues, setFormValues] = useState({} as FormData);
  const [shouldRefreshTable, setShouldRefreshTable] = useState<number>(0);
  const [orgs, setOrgs] = useState<EnterpriseOrganization[]>([]);
  const [portfolioCode, setPortfolioCode] = useState<string>();
  const portfolios = useSelector((state: RootState) => getPortfoliosList(state));

  async function createNewPortfolio() {
    const headers = { Authorization: `Bearer ${token}` };
    const payload = {
      ...formValues,
      portfolio_code: portfolioCode,
    };
    const portResult = await axios.post(PORTFOLIOS_ENDPOINT, payload, { headers });
    setOpenAlert(true);
    setShouldRefreshTable(ref => ref + 1);
  }

  const handleSelectChange = (field: string, isInt?: boolean) => (
    event: React.ChangeEvent<{ value: any }>,
  ) => {
    setFormValues(form => ({
      ...form,
      [field]: isInt === true ? parseInt(event.target.value, 10) : event.target.value,
    }));
  };

  useEffect(() => {
    async function fetchOrgs() {
      setOrgs([] as EnterpriseOrganization[]);

      dispatch(fetchPortfolios(token));
      const result = await axios.get(ORGANIZATIONS_ENDPOINT, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOrgs(result.data);
    }

    fetchOrgs();
  }, [shouldRefreshTable]);

  const sortedPort = !!portfolios
    ? portfolios.slice().sort((a, b) => {
        if (a.organization.name < b.organization.name) {
          return -1;
        }
        if (a.organization.name > b.organization.name) {
          return 1;
        }
        return 0;
      })
    : [];
  return (
    <div>
      <Paper className={classes.paper}>
        <Typography variant="h5" gutterBottom>
          New Portfolio
        </Typography>
        <form className={classes.formRoot} noValidate autoComplete="off">
          <FormControl className={classes.formControl}>
            <InputLabel id="org-select-label">Organization</InputLabel>
            <Select
              labelId="org-select-label"
              id="org-select"
              onChange={handleSelectChange('organization_id', true)}
              value={formValues.organization_id || ''}
            >
              {orgs.map((org: EnterpriseOrganization) => (
                <MenuItem key={`org-${org.id}`} value={org.id}>
                  {org.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="type-select-label">Type</InputLabel>
            <Select
              label="Type"
              onChange={handleSelectChange('portfolio_type')}
              value={formValues.portfolio_type || ''}
            >
              <MenuItem value="PI">PI</MenuItem>
              <MenuItem value="ME">ME</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="country-select-label">Country</InputLabel>
            <Select
              label="Country"
              labelId="country-select-label"
              id="country-select"
              onChange={handleSelectChange('country_id', true)}
              value={formValues.country_id || ''}
            >
              {Object.keys(countryMap).map(id => (
                <MenuItem key={`country-${id}`} value={id}>
                  {countryMap[id]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="currency-select-label">Currency</InputLabel>
            <Select
              label="Currency"
              labelId="currency-select-label"
              id="currency-select"
              onChange={handleSelectChange('currency_id', true)}
              value={formValues.currency_id || ''}
            >
              {Object.keys(currencyMap).map(id => (
                <MenuItem key={`currency-${id}`} value={id}>
                  {currencyMap[id]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Portfolio Code"
            onChange={event => setPortfolioCode(event.target.value)}
            value={portfolioCode || ''}
          />
          <Button
            disabled={
              !formValues.organization_id ||
              !formValues.country_id ||
              !formValues.currency_id ||
              !formValues.portfolio_type ||
              !portfolioCode
            }
            variant="contained"
            color="secondary"
            onClick={createNewPortfolio}
          >
            Create
          </Button>
        </form>
      </Paper>
      {portfolios && portfolios.length ? (
        <MaterialTable
          columns={[
            { title: 'ID', field: 'id', editable: 'never' },
            { title: 'Organization', field: 'organization.name', defaultSort: 'asc' },
            { title: 'Portfolio Code', field: 'portfolio_code' },
            { title: 'Type', field: 'portfolio_type' },
            { title: 'Country', field: 'country.iso_code' },
            { title: 'Currency', field: 'currency.iso_code' },
            {
              title: 'Balance',
              field: 'balance.total',
              render: rowData =>
                rowData.balance?.total ? (
                  toCurrency(rowData.balance.total, rowData.currency.iso_code)
                ) : (
                  <NA />
                ),
            },
          ]}
          data={portfolios}
          title="Portfolios"
          options={{
            filtering: false,
            pageSizeOptions: [10, 25, 50, 100, 500],
            pageSize: 25,
          }}
        />
      ) : null}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openAlert}
        autoHideDuration={12000}
        onClose={() => setOpenAlert(false)}
        message="Portfolio Successfully Created"
      />
    </div>
  );
}
