import { RSAA } from 'redux-api-middleware'; // RSAA = '@@redux-api-middleware/RSAA'
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import uniq from 'lodash/uniq';

import { API_V2_ROOT } from '../env';
import { Product } from '../models';
import { RootState, FluxStandardAction } from '.';
import { createSelector } from 'reselect';

export const LOAD_PRODUCTS_REQUEST = '@@policies/LOAD_PRODUCTS_REQUEST';
export const LOAD_PRODUCTS_SUCCESS = '@@policies/LOAD_PRODUCTS_SUCCESS';
export const LOAD_PRODUCTS_FAILURE = '@@policies/LOAD_PRODUCTS_FAILURE';

export const fetchProducts: any = (token: string) => ({
  [RSAA]: {
    endpoint: `${API_V2_ROOT}/products`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
    types: [
      LOAD_PRODUCTS_REQUEST,
      {
        type: LOAD_PRODUCTS_SUCCESS,
      },
      LOAD_PRODUCTS_FAILURE,
    ],
  },
});

export type ProductsState = {
  isLoadingData: boolean;
  lastUpdate?: string;
  byId?: {
    [id: number]: Product;
  };
  allIds?: number[];
};

export const productInitialState: ProductsState = {
  isLoadingData: false,
  byId: undefined,
  allIds: undefined,
};

export default function reducer(
  state = productInitialState,
  action: FluxStandardAction,
): ProductsState {
  switch (action.type) {
    case LOAD_PRODUCTS_REQUEST:
      return {
        ...state,
        isLoadingData: true,
      };
    case LOAD_PRODUCTS_SUCCESS:
      const productsById = keyBy(action.payload, 'id');
      const productIds = Object.keys(productsById).map(key => parseInt(key, 10));
      return {
        ...state,
        isLoadingData: false,
        byId: {
          ...state.byId,
          ...productsById,
        },
        allIds: state.allIds ? uniq([...state.allIds, ...productIds]) : [...productIds],
        lastUpdate: new Date().toJSON(),
      };
    case LOAD_PRODUCTS_FAILURE:
      // TODO implement
      return state;
    default:
      return state;
  }
}

export const productByIdSelector = (state: RootState) => state.products?.byId || {};
export const cropByProductIdSelector = createSelector(productByIdSelector, productById =>
  mapValues(productById, x => x.crop.name),
);
