import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import PortfolioIcon from '@material-ui/icons/ListAlt';
import MapIcon from '@material-ui/icons/Map';
import CropIcon from '@material-ui/icons/Spa';

import PortfolioDateRange from './PortfolioDateRange';

import { getCurrencySymbol, abbreviateNumber } from '../../utils';
import { Policy } from '../../models';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(3),
  },
  flex: {
    display: 'flex',
    flexFlow: 'column',
    padding: 0,
  },
  iconItem: {
    display: 'flex',
  },
  iconItemRow: {
    display: 'flex',
    flexGrow: 2,
    justifyContent: 'space-around',
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 35,
    margin: '5px 10px',
  },
  iconText: {
    textAlign: 'left',
  },
  payment: {
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

type PolicyDetailsSummaryCardProps = {
  policy: Policy;
  crop: string;
  productCode?: string;
  currentDate?: string;
};

export default function PolicyDetailsSummaryCard(props: PolicyDetailsSummaryCardProps) {
  const { policy, productCode, crop, currentDate } = props;
  const classes = useStyles();
  const currencyCode = policy.total_premium.currency;

  let productType = 'N/A';
  console.log('product code', productCode);
  if (productCode != null && productCode.length) {
    const productCodeType = productCode.slice(-4, -2);
    type ProductMap = {
      [key: string]: string;
    };
    const productMap: ProductMap = {
      CB: 'Rainfall Combo',
      XS: 'Excess Rain',
      ST: 'Drought',
      DR: 'Drought',
      FR: 'Frost',
      FX: 'Frost & Excess Rain',
    };
    if (productCodeType in productMap) {
      productType = productMap[productCodeType];
    }
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.flex}>
        <Typography variant="h5" component="h1">
          Policy ID {policy.id}
        </Typography>
        <div className={classes.row}>
          <div className={classes.payment}>
            <Typography variant="body2" color="textSecondary" component="p">
              Triggered Payout
            </Typography>
            <Typography gutterBottom variant="h5" component="h2">
              {policy.payout && currencyCode
                ? `${getCurrencySymbol(currencyCode)}${abbreviateNumber(policy.payout)}`
                : 'N/A'}
            </Typography>
          </div>
          <PortfolioDateRange
            currentDate={currentDate}
            startDate={policy.date_start!}
            endDate={policy.date_end!}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.payment}>
            <Typography variant="body2" color="textSecondary" component="p">
              Anchor Value
            </Typography>
            <Typography gutterBottom variant="h5" component="h2">
              {policy.sum_insured && currencyCode
                ? `${getCurrencySymbol(currencyCode)}${abbreviateNumber(policy.sum_insured)}`
                : 'N/A'}
            </Typography>
          </div>
          <div className={classes.iconItemRow}>
            <div className={classes.iconItem}>
              <PortfolioIcon className={classes.icon} />
              <div className={classes.iconText}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Type of Policy
                </Typography>
                <Typography variant="body1" component="p">
                  {productType}
                </Typography>
              </div>
            </div>
            <div className={classes.iconItem}>
              <MapIcon className={classes.icon} />
              <div className={classes.iconText}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Location
                </Typography>
                <Typography variant="body1" component="p">
                  {policy.trigger_location?.name}
                </Typography>
              </div>
            </div>
            <div className={classes.iconItem}>
              <CropIcon className={classes.icon} />
              <div className={classes.iconText}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Crop
                </Typography>
                <Typography variant="body1" component="p">
                  {crop}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
