import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import portfolioPlanning from './portfolio.png';

export default function ErrorPage() {
  const errorPageImgStyle = {
    maxWidth: 265,
    borderRadius: '50%',
    opacity: '70%',
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <img style={errorPageImgStyle} src={portfolioPlanning} alt="Error Image" />
      </Grid>
      <Grid item xs={12}>
        <Typography component="h1" variant="h4">
          Something Went Wrong!
        </Typography>
        <Typography variant="h6" gutterBottom>
          Sorry about that, we had trouble loading this page.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Please go to a different page or try again later.
        </Typography>
      </Grid>
    </Grid>
  );
}
