import { RSAA } from 'redux-api-middleware'; // RSAA = '@@redux-api-middleware/RSAA'
import { RootState } from '.';

import { API_V2_ROOT } from '../env';
import { UserRole } from '../models';
import { PORTFOLIOS_FAILURE } from './portfolios';
import { LOAD_PRODUCTS_FAILURE } from './products';

export const LOGIN = '@@user/LOGIN';
export const LOGOUT = '@@user/LOGOUT';
export const LOAD_USER_REQUEST = '@@user/LOAD_USER_REQUEST';
export const LOAD_USER_SUCCESS = '@@user/LOAD_USER_SUCCESS';
export const LOAD_USER_FAILURE = '@@user/LOAD_USER_FAILURE';
export const SET_AUTH_REDIRECT = '@@user/SET_AUTH_REDIRECT';

type User = {
  id: number;
  organization_id: number | null;
  username: string;
  role: UserRole;
  name: string;
  has_api_key: boolean;
  portfolios: number[] | null;
};

export const fetchUser: any = (token: string) => ({
  [RSAA]: {
    endpoint: `${API_V2_ROOT}/users/me`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
    types: [
      LOAD_USER_REQUEST,
      {
        type: LOAD_USER_SUCCESS,
      },
      LOAD_USER_FAILURE,
    ],
  },
});

export type TokenData = {
  token: string;
  expires_at: number;
};

export type UserState = User & {
  lastUpdate?: Date;
  isAuthenticated: boolean;
  token?: string;
  authRedirect?: string;
  isLoadingData: boolean;
};

const loadToken = () => {
  const tokenData = localStorage.getItem('apitoken');
  if (tokenData === null) {
    return { isAuthenticated: false };
  } else {
    const { expires_at, token }: TokenData = JSON.parse(tokenData);
    const currentTimestamp = Math.floor(new Date().getTime() / 1000);
    if (expires_at <= currentTimestamp) {
      // Token expired, clear old token
      localStorage.removeItem('apitoken');
      return { isAuthenticated: false };
    } else {
      return { token, isAuthenticated: true };
    }
  }
};

const initialState = { ...loadToken(), isLoadingData: false } as UserState;

export default function reducer(state: UserState = initialState, action: any): any {
  switch (action.type) {
    case LOAD_USER_REQUEST:
      return {
        ...state,
        isLoadingData: true,
      };
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoadingData: false,
      };
    case LOAD_USER_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoadingData: false,
      };
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
      };
    case LOAD_PRODUCTS_FAILURE:
    case PORTFOLIOS_FAILURE:
      // Clear User - Auth failure
      if (action.payload.status === 401) {
        localStorage.clear();
        return {
          ...initialState,
        };
      }
    case LOGOUT:
      return {
        isAuthenticated: false,
        isLoadingData: false,
      };
    case SET_AUTH_REDIRECT:
      return {
        ...state,
        authRedirect: action.payload,
      };
    default:
      return state;
  }
}

export const login = (token: string) => ({
  type: LOGIN,
  payload: { token },
});

export const logout = () => ({
  type: LOGOUT,
});

export const getToken = (state: RootState) => state.user.token;
export const isAuthenticated = (state: RootState) => state.user.isAuthenticated;

export const getAuthRedirect = (state: RootState) => state.user.authRedirect;
export const setAuthRedirect = (redirectPath: string) => ({
  type: SET_AUTH_REDIRECT,
  payload: redirectPath,
});
