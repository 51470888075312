import { currencyCode } from '../env';
import { Policy, PolicyStatus } from '../models';

export const capitalize = (str: string) =>
  str
    .split(' ')
    .map(s => s[0].toUpperCase() + s.slice(1))
    .join(' ');

type optionsObj = { id: number; name: string };
export const mapOptions = (responseObj: optionsObj) => ({
  value: responseObj.id,
  label: responseObj.name,
});

export const truncate = (str: string, maxLength = 10) =>
  str.length > maxLength ? str.slice(0, maxLength - 3) + '...' : str;

export const inDepositStatuses = ['pending', 'priced'];
export const activeStatuses = ['active'];
export const triggeredStatuses = ['triggered', 'paid out'];

export const DRAWER_WIDTH = 225;

export const toCurrency = (amount: number, currencyCode: string) => {
  const minimumFractionDigits = currencyCode === 'USD' ? 2 : 0;
  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'symbol',
    minimumFractionDigits,
    maximumFractionDigits: 2,
  });
};

// Iterated from: https://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn
export function abbreviateNumber(value: number): string {
  let newValue = value;
  const suffixes = ['', 'K', 'M', 'B', 'T'];
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }

  const formattedValue = `${newValue.toPrecision(3)}${suffixes[suffixNum]}`;
  return formattedValue;
}

export function getCurrencySymbol(currencyCode: string) {
  const t = 0;
  return t
    .toLocaleString('en-US', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace('0.00', '');
}

export function calculateStatus(policy: Policy): PolicyStatus {
  // Policy Activated
  if (['eligiblity_final', 'expired'].includes(policy.activation_status)) {
    return policy.is_eligible === true ? 'paid out' : 'expired';
  } else if (policy.activation_status === 'active') {
    return policy.is_eligible === true ? 'triggered' : 'active';
  }

  // Policy Not Activated But Priced
  if (policy.priced_at != null) {
    return 'priced';
  }

  // TODO Add State for Missing Data for Pricing

  // Default - not yet priced
  return 'pending';
}
