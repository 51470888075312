import { getPlantingDate } from './timeUtils';
import { FluxStandardAction, RootState } from '.';

export const SET_DATE = '@@TIME/SET_DATE';
export const INCREASE_STEP = '@@TIME/INCREASE_STEP';
export const DECREASE_STEP = '@@TIME/DECREASE_STEP';

type TimeState = {
  currentTime: string;
  step: number;
};

const initialState: TimeState = {
  currentTime: getPlantingDate(),
  step: 0,
};

export default function reducer(state = initialState, action: FluxStandardAction): TimeState {
  switch (action.type) {
    case SET_DATE: {
      return {
        ...state,
        currentTime: action.payload,
      };
    }
    case INCREASE_STEP: {
      return {
        ...state,
        step: state.step + 1,
      };
    }
    case DECREASE_STEP: {
      return {
        ...state,
        step: state.step - 1,
      };
    }
    default:
      return state;
  }
}
