import React from 'react';
import PropTypes from 'prop-types';

import Hidden from '@material-ui/core/Hidden';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { DRAWER_WIDTH } from '../utils';
import { UserRole } from '../models';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

type HeaderProps = {
  container: any;
  children: any;
  onlyRenderChilden: boolean;
  isAuthenticated: boolean;
  role?: UserRole;
  headerDrawerComponent: JSX.Element | any;
  selectorComponent: JSX.Element | any;
  headerBarComponent: JSX.Element | any;
};

function Header(props: HeaderProps) {
  const {
    isAuthenticated,
    role,
    headerDrawerComponent: HeaderDrawer,
    headerBarComponent: HeaderBar,
    selectorComponent: PortfolioSelector,
    container,
    onlyRenderChilden = false,
    children,
  } = props;
  const classes = useStyles({});
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (onlyRenderChilden === true) {
    return children;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <HeaderBar handleDrawerToggle={handleDrawerToggle} />

      {isAuthenticated && (
        <React.Fragment>
          <nav className={classes.drawer}>
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <HeaderDrawer role={role} selectorComponent={PortfolioSelector} />
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                <HeaderDrawer role={role} selectorComponent={PortfolioSelector} />
              </Drawer>
            </Hidden>
          </nav>
        </React.Fragment>
      )}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

export default Header;
