import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';

import { DRAWER_WIDTH } from '../../utils';
//import logo from './worldcover_logo_dark.svg';
import logo from '../../components/worldcover_logo_v2.svg';
import UsersIcon from '@material-ui/icons/People';
import { ReactComponent as LocationIcon } from '../../components/icons/location.svg';
import { ReactComponent as SatelliteIcon } from '../../components/icons/satellite.svg';
import { ReactComponent as PayoutIcon } from '../../components/icons/payout.svg';
import { ReactComponent as AnalyzeIcon } from '../../components/icons/analyze.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#121427',
    height: '100%',
    color: 'white',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    paddingTop: theme.spacing(1),
    paddingRight: 15,
    paddingLeft: 15,
  },
  selectedItem: {
    backgroundColor: 'rgba(73, 76, 97, 0.65) !important',
  },
  svgIcon: {
    fill: 'currentColor',
    width: 30,
    height: 30,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedDiv: {
    marginLeft: theme.spacing(4),
  },
}));

type DrawerItemProps = {
  urlPath: string;
  itemText: string;
  icon: React.ReactType<{}>;
  selectedItemClass: any;
  selected: boolean;
  onClick?: () => void;
  disabled?: boolean;
};

export const DrawerItem = (props: DrawerItemProps) => {
  const {
    urlPath,
    itemText,
    icon: IconComponent,
    onClick,
    selected,
    disabled = true,
    selectedItemClass,
  } = props;
  const svgStyle = {
    color: '#d6d6d6',
    fill: 'currentColor',
    width: 30,
    height: 30,
  };
  return (
    <>
      <Divider />
      <List>
        <ListItem
          button
          classes={{
            selected: selectedItemClass,
          }}
          onClick={onClick}
          component={Link}
          to={urlPath}
          selected={selected}
          disabled={disabled}
        >
          <ListItemIcon>
            <IconComponent style={svgStyle} />
          </ListItemIcon>
          <ListItemText primary={itemText} />
        </ListItem>
      </List>
    </>
  );
};

type HeaderDrawerProps = {
  selectorComponent: JSX.Element | any;
};

export default function HeaderDrawer(props: HeaderDrawerProps) {
  const { selectorComponent: PortfolioSelector } = props;
  const classes = useStyles();
  const location = useLocation();

  const drawerItems = [
    {
      urlPath: '/monitor',
      itemText: 'Monitor Portfolio',
      icon: SatelliteIcon,
      isDisabled: false,
    },
    {
      urlPath: '/build',
      itemText: 'Setup',
      icon: LocationIcon,
      isDisabled: true,
    },
    {
      urlPath: '/payouts',
      itemText: 'Payouts',
      icon: PayoutIcon,
      isDisabled: true,
    },
    {
      urlPath: '/users',
      itemText: 'Settings',
      icon: UsersIcon,
      isDisabled: true,
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <img width={DRAWER_WIDTH - 30} src={logo} alt="WorldCover" />
      </div>
      <Hidden mdUp>
        <PortfolioSelector variant="dark" />
      </Hidden>

      {drawerItems.map(item => (
        <DrawerItem
          key={item.urlPath}
          urlPath={item.urlPath}
          itemText={item.itemText}
          icon={item.icon}
          selectedItemClass={classes.selectedItem}
          disabled={item.isDisabled}
          selected={location.pathname === item.urlPath}
        />
      ))}
      <Divider />
    </div>
  );
}
