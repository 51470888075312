import { RSAA } from 'redux-api-middleware'; // RSAA = '@@redux-api-middleware/RSAA'
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';

import { createSelector } from 'reselect';

import { API_V2_ROOT } from '../env';
import { TagMap, Policy, ApiErrorMessage } from '../models';
import { RootState, FluxStandardAction } from '.';
import { selectedPortfolioIdSelector } from './portfolios';
import { selectedPortfolioTagsSelector } from './portfolio-summaries';
import { LOGOUT } from './user';

export const LOAD_POLICIES_REQUEST = '@@policies/LOAD_POLICIES_REQUEST';
export const LOAD_POLICIES_SUCCESS = '@@policies/LOAD_POLICIES_SUCCESS';
export const LOAD_POLICIES_FAILURE = '@@policies/LOAD_POLICIES_FAILURE';

export const LOAD_POLICY_REQUEST = '@@policies/LOAD_POLICY_REQUEST';
export const LOAD_POLICY_SUCCESS = '@@policies/LOAD_POLICY_SUCCESS';
export const LOAD_POLICY_FAILURE = '@@policies/LOAD_POLICY_FAILURE';

const POLICIES_LIMIT = 25000;
export const fetchPortfolioPolicies: any = (portfolioId: number, token: string) => ({
  [RSAA]: {
    endpoint: `${API_V2_ROOT}/policies?portfolio_id=${portfolioId}&limit=${POLICIES_LIMIT}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
    types: [
      LOAD_POLICIES_REQUEST,
      {
        type: LOAD_POLICIES_SUCCESS,
        meta: { portfolioId },
      },
      LOAD_POLICIES_FAILURE,
    ],
  },
});

export const fetchPolicy: any = (policyId: number, token: string) => ({
  [RSAA]: {
    endpoint: `${API_V2_ROOT}/policies/${policyId}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
    types: [
      LOAD_POLICY_REQUEST,
      {
        type: LOAD_POLICY_SUCCESS,
      },
      LOAD_POLICY_FAILURE,
    ],
  },
});

export type PortfolioPoliciesState = {
  isLoadingData: boolean;
  error?: ApiErrorMessage;
  byId?: {
    [policyId: number]: Policy;
  };
  allIds?: number[];
  byPortfolio: {
    [portfolioId: number]: number[];
  };
};

const initialState: PortfolioPoliciesState = {
  isLoadingData: false,
  error: undefined,
  byId: undefined,
  allIds: undefined,
  byPortfolio: {},
};

export default function reducer(
  state = initialState,
  action: FluxStandardAction,
): PortfolioPoliciesState {
  switch (action.type) {
    case LOAD_POLICY_REQUEST:
    case LOAD_POLICIES_REQUEST:
      return {
        ...state,
        error: undefined,
        isLoadingData: true,
      };
    case LOAD_POLICY_SUCCESS: {
      const { id } = action.payload;
      const { portfolio_id: portfolioId } = action.payload;
      // const currentPortfolioIds = state.byPortfolio[portfolioId] || [];
      return {
        ...state,
        isLoadingData: false,
        byId: {
          ...state.byId,
          [id]: action.payload,
        },
        allIds: state.allIds ? uniq([...state.allIds, id]) : [id],
        byPortfolio: {
          ...state.byPortfolio,
          // Hack to prevent partially loading policy until we determine better way to handle
          // [portfolioId]: uniq([...currentPortfolioIds, id]),
        },
      };
    }
    case LOAD_POLICIES_SUCCESS:
      const policiesById = keyBy(action.payload, 'id');
      const policyIds = Object.keys(policiesById).map(key => parseInt(key, 10));
      const { portfolioId } = action.meta;
      const currentPortfolioIds = state.byPortfolio[portfolioId] || [];
      return {
        ...state,
        isLoadingData: false,
        byId: {
          ...state.byId,
          ...policiesById,
        },
        allIds: state.allIds ? uniq([...state.allIds, ...policyIds]) : [...policyIds],
        byPortfolio: {
          ...state.byPortfolio,
          [portfolioId]: uniq([...currentPortfolioIds, ...policyIds]),
        },
      };
    case LOAD_POLICY_FAILURE:
    case LOAD_POLICIES_FAILURE:
      return {
        ...state,
        error: action.payload?.response as ApiErrorMessage,
        isLoadingData: false,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export const policiesByPortfolioSelector = (state: RootState) => state.policies.byPortfolio;
export const policiesByIdSelector = (state: RootState) => state.policies?.byId || {};
export const policiesErrorSelector = (state: RootState) => state.policies.error;

export const getSelectedPortfolioPolicies = createSelector(
  selectedPortfolioIdSelector,
  policiesByPortfolioSelector,
  policiesByIdSelector,
  (portfolioId, byPortfolio, byId) => {
    if (!portfolioId || !byPortfolio) {
      return undefined;
    }
    const policyIds = byPortfolio[portfolioId];
    return policyIds === undefined ? undefined : policyIds.map(id => byId[id]);
  },
);

const policyIdSelector = (state: RootState, props: any) => props?.policy?.id;
export const policySelector = createSelector(
  policiesByIdSelector,
  policyIdSelector,
  (byId, policyId) => byId[policyId],
);

export const getSelectedPortfolioTags = createSelector(
  getSelectedPortfolioPolicies,
  selectedPortfolioTagsSelector,
  (portfolioPolicies, tagMap) => {
    if (isEmpty(tagMap)) {
      return {};
    } else {
      let filteredTagMap = {} as TagMap;
      portfolioPolicies?.forEach(pol => {
        if (pol.tag_ids.length) {
          pol.tag_ids.forEach(tagId => {
            filteredTagMap[tagId] = tagMap![tagId];
          });
        }
      });
      return filteredTagMap;
    }
  },
);
